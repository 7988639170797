


















































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'NextEpisodeMismatch',

  computed: {
    ...mapGetters({
      selectedSpokenLanguage: 'selectedSpokenLanguage',
      selectedVersion: 'selectedVersion',
      currentEpisodeType: 'videoPlayerCatalog/currentEpisodeType',
      currentShowName: 'videoPlayerCatalog/currentShowName',
      currentShowSlug: 'videoPlayerCatalog/currentShowSlug',
      nextEpisodeSlug: 'videoPlayerCatalog/nextEpisodeSlug',
      nextEpisodeNumber: 'videoPlayerCatalog/nextEpisodeNumber',
      altNextEpisodeExperienceSpokenLanguage: 'videoPlayerCatalog/altNextEpisodeExperienceSpokenLanguage',
      altNextEpisodeExperienceType: 'videoPlayerCatalog/altNextEpisodeExperienceType',
    }),
    orientationPortrait(): boolean {
      return this.$vuetify.breakpoint.height > this.$vuetify.breakpoint.width;
    },
    nextEpisodeHref(): string {
      const origin = window.location.origin; // "https://www.funimation.com"
      const routerBase = this.$router.options.base; // "/v/"" or "/"
      const showSlug = this.currentShowSlug;
      const episodeSlug = this.nextEpisodeSlug;

      return `${origin}${routerBase}${showSlug}/${episodeSlug}`;
    },
  },
});
