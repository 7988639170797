






































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

type Resolution = {
  isHd: boolean;
  name: string;
  height: number | 'auto';
};
type Resolutions = Array<Resolution>;

type VideoQuality = {
  bandwidth: number;
  enabled: (enable?: boolean) => boolean;
  height: number;
};

const HD_QUALITY_MIN = 720;

export default Vue.extend({
  name: 'video-player-settings-panel',
  props: {
    panelOpen: Boolean,
  },
  data() {
    return {
      showQualitySelection: false,
    };
  },
  computed: {
    ...mapGetters({
      autoMarathon: 'autoMarathon',
      isLoggedIn: 'isLoggedIn'
    }),
    ...mapState('videoPlayerCore', [
      'quality',
      'videoRepresentations',
    ]),

    autoMarathonModel: {
      get() {
        return this.autoMarathon;
      },
      set() {
        this.toggleAutoMarathon();
      },
    } as any, // Fix CombinedVueInstance type error

    resolutionOptions(): Resolutions {
      // Filter duplicate heights from display
      const heights: Array<number> = [];
      const videoRepresentationsFiltered = this.videoRepresentations.filter((quality: VideoQuality) => {
        if (heights.includes(quality.height)) {
        // Skip height
          return false;
        }

        // Keep height
        heights.push(quality.height);
        return true;
      });


      // FIXME: 270p & 234p are causing media errors, so we're turning them off for now.
      const safeFilter = videoRepresentationsFiltered.filter((quality: VideoQuality) => {
        if ([ 270, 234 ].includes(quality.height)) {
          return false;
        }
        return true;
      });

      // Map to display type
      const resolutions: Resolutions = safeFilter.map((quality: VideoQuality): Resolution => {
        return {
          isHd: quality.height >= HD_QUALITY_MIN,
          name: this.qualityDisplay(quality.height),
          height: quality.height,
        };
      });

      // Add Auto option to top
      resolutions.unshift({
        isHd: false,
        name: (this.$t('quality.auto') as string),
        height: 'auto',
      });

      return resolutions;
    },

    hasResolutionOptions(): boolean {
      return this.resolutionOptions.length > 1;
    },

    qualityModel: {
      get() {
        return this.quality;
      },
      set(newValue: any) {
        this.setQuality(newValue);
      },
    } as any, // Fix CombinedVueInstance type error
  },

  watch: {
    panelOpen(newValue: boolean) {
      if (!newValue) {
        // Close Quality Selection menu when parent menu/panel closes.
        this.showQualitySelection = false;
      }
    },
  },

  methods: {
    ...mapActions({
      toggleAutoMarathon: 'toggleAutoMarathon',
      setQuality: 'videoPlayerCore/setQuality',
    }),

    qualityDisplay(quality: string | number): string {
      if (quality === 'auto') {
        return 'auto';
      }

      return `${quality}p`;
    },

    toggleVideoQualityMenu(): void {
      this.showQualitySelection = !this.showQualitySelection;
    },
  }
});
