/**
 * @fileoverview
 * Store module and initializer for Google Sign-in (Auth)
 *
 * Google Sign-in API: https://developers.google.com/identity/sign-in/web/reference?hl=en
 */

import { i18n } from './i18n';

declare global {
  let gapi: any; // From Google Platform API

  interface Window {
    funiGoogleAuthPlatformLoaded: any;
  }
}

interface PluginOptions {
  clientId: string;
  clientConfig?: any;
}

interface ProfileData {
  avatar: string | null;
  displayName: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

interface GoogleAuthData {
  idToken: string | null;
  profileData: ProfileData | null;
}

/**
 * Sets-up, initializes, and registers store module.
 *
 * @param {VuexStore} context
 * @param {PluginOptions} options
 */
const setupStore = (context: any, options: PluginOptions): void => {
  /**
   * Transforms a GoogleUser, for use in store.
   *
   * @param {GoogleUser} googleUser the current user.
   *
   * @returns {GoogleAuthData}
   */
  function getGoogleAuthData(googleUser: any): GoogleAuthData {
    const googleAuth: any = gapi.auth2.getAuthInstance();

    const signedIntoGoogle: boolean = googleAuth.isSignedIn.get();
    if (!signedIntoGoogle) {
      return {
        idToken: null,
        profileData: null,
      };
    }

    const idToken: string = googleUser.getAuthResponse().id_token;

    const profileData: any = googleUser.getBasicProfile();

    const avatar: string = profileData.getImageUrl();
    const displayName: string = profileData.getName();
    const email: string = profileData.getEmail();
    const firstName: string = profileData.getGivenName();
    const lastName: string = profileData.getFamilyName();

    return {
      idToken,
      profileData: {
        avatar,
        displayName,
        email,
        firstName,
        lastName,
      },
    };
  }

  /**
   * Listener method for when the user changes.
   *
   * @param {GoogleUser} googleUser the updated user.
   */
  function onCurrentUser(googleUser: any): void {
    const googleAuthData: GoogleAuthData = getGoogleAuthData(googleUser);
    context.commit('googleAuth/SET_GOOGLE_AUTH_DATA', googleAuthData);
  }

  /**
   * Signs-in the user via Google Sign-in
   */
  async function signIn(): Promise<void> {
    context.commit('googleAuth/SET_SIGNING_IN_OUT', true);

    try {
      const googleAuth: any = gapi.auth2.getAuthInstance();
      await googleAuth.signIn();
    } catch (auth2Error) {
      const messagePreamble = i18n.t('sign-in.errors.message-preamble');
      const code: string = auth2Error.error ?? '';

      // UI Message
      if (code === 'popup_closed_by_user') {
        const error = new Error(
          `${messagePreamble} ${i18n.t('sign-in.errors.popup-closed-by-user-x', { code })}`
        );
        context.dispatch('error', error, { root: true });
        return;
      }

      // UI Message
      if (code === 'popup_blocked_by_browser') {
        const error = new Error(
          `${messagePreamble} ${i18n.t('sign-in.errors.unblock-pop-ups-x', { code })}`
        );
        context.dispatch('error', error, { root: true });
        return;
      }

      // UI Message
      if (code === 'access_denied') {
        const error = new Error(
          `${messagePreamble} ${i18n.t('sign-in.errors.permission-denied-x', { code })}`
        );
        context.dispatch('error', error, { root: true });
        return;
      }

      // Throw
      if (code) {
        throw new Error(`${messagePreamble} ${code}`);
      }

      // Throw yo hands up!
      // Note, auth2Error may be an object, not a proper Error.
      throw new Error(`${messagePreamble} ${auth2Error}`);
    }

    context.commit('googleAuth/SET_SIGNING_IN_OUT', false);
  }

  /**
   * Signs-out the user via Google Sign-in
   */
  async function signOut(): Promise<void> {
    context.commit('googleAuth/SET_SIGNING_IN_OUT', true);

    try {
      const googleAuth: any = gapi.auth2.getAuthInstance();
      await googleAuth.signOut();
    } catch (auth2Error) {
      // Note, auth2Error may be an object, not a proper Error.
      const message = `${i18n.t('sign-out.error')} ${auth2Error}`;
      throw new Error(message);
    }

    context.commit('googleAuth/SET_SIGNING_IN_OUT', false);
  }

  /**
   * Init Lifecycle Step 3
   *
   * Initializes Google Sign-in and sets up listeners.
   */
  async function onAuth2Loaded(): Promise<void> {
    try {
      // NOTES:
      // - gapi.auth2.init restores the user's sign-in state from the previous session.
      // - gapi.auth2.init does not return a proper Promise.
      const googleAuth: any = gapi.auth2.init({
        ...options.clientConfig,
        'client_id': options.clientId,
      });

      // Attach state listeners before googleAuth resolves.
      googleAuth.currentUser.listen(onCurrentUser);

      // googleAuth resolves after googleAuth.currentUser is initialized.
      await googleAuth;
    } catch (auth2Error) {
      const code: string = auth2Error.error ?? '';
      const messagePreamble = i18n.t('on-auth2-loaded.errors.message-preamble');

      // UI Message
      if (code === 'idpiframe_initialization_failed') {
        const error = new Error(`${messagePreamble} ${code} ${auth2Error.details}`);
        context.dispatch('error', error, { root: true });
        return;
      }

      // Throw
      if (code) {
        const error = new Error(`${messagePreamble} ${code}`);
        console.error(error);
        return;
      }

      // Throw yo hands up!
      const error = new Error(`${messagePreamble}`);
      console.error(error);
      console.error(auth2Error); // Note, auth2Error may be an object, not a proper Error.
      return;
    }

    context.commit('googleAuth/SET_GOOGLE_AUTH_READY');
  }

  /**
   * Init Lifecycle Step 2
   *
   * Calls onAuth2Loaded after Google Sign-in finishes setting up.
   */
  window.funiGoogleAuthPlatformLoaded = function (): void {
    gapi.load('auth2', {
      callback: onAuth2Loaded,
      onerror: (error: any) => {
        const message = i18n.t('gapi-load.error');
        console.error(message);
        console.error(error);
      },
      timeout: 5000, // 5 Sec
      ontimeout: () => {
        const message = i18n.t('gapi-load.timeout-x', { seconds: 5});
        console.error(message);
      },
    });
  };

  /**
   * Init Lifecycle Step 1
   *
   * Dynamically injects platform.js script and calls
   * `window.funiGoogleAuthPlatformLoaded` when loaded.
   */
  function loadPlatformScript(): void {
    const doc: Document = document;
    const script: HTMLScriptElement = doc.createElement('script');

    script.async = true;
    script.defer = true;
    script.src = 'https://apis.google.com/js/platform.js?onload=funiGoogleAuthPlatformLoaded';

    doc.body.appendChild(script);
  }

  const store = {
    namespaced: true,

    state: {
      idToken: null,
      profileData: null,
      ready: false,
      signingInOut: false,
    },

    getters: {
      /**
       * The user is signed-in if they have an `id_token`.
       */
      isSignedIn(state: any): boolean {
        return !!state.idToken;
      },
    },

    actions: {
      signIn,
      signOut,
    },

    mutations: {
      SET_GOOGLE_AUTH_DATA(state: any, payload: GoogleAuthData) {
        state.idToken = payload.idToken;
        state.profileData = payload.profileData;
      },

      SET_GOOGLE_AUTH_READY(state: any) {
        state.ready = true;
      },

      SET_SIGNING_IN_OUT(state: any, payload: boolean) {
        state.signingInOut = payload;
      },
    },
  };

  loadPlatformScript();

  context.registerModule('googleAuth', store);
};

/**
 * Sets-up and returns a Vue Plugin for a Vuex store module for
 * Google Sign-in (Auth).
 *
 * @param {VuexStore} context Vuex store instance
 * @param {PluginOptions} options
 *
 * @returns {object} Vue Plugin
 */
export default (context: any, options: PluginOptions): any => {
  const minimalConfig: boolean = !context || !options || !(options && options.clientId);
  if (minimalConfig) {
    const message = i18n.t('minimal-config.error');
    throw new Error(`${message}`);
  }

  setupStore(context, options);

  return {
    install(): void {
      // Nothing yet
    },
  };
};
