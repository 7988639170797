<template>
  <v-row no-gutters>
    <v-col class="pa-0">
      <content-hero />
      <content-details-tabs />
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    showName() {
      if (!this.$store.state.showDetails.item) {
        return '';
      }
      return this.$store.state.showDetails.item.name;
    },
    shortSynopsis() {
      if (!this.$store.state.showDetails.item) {
        return '';
      }
      return this.$store.state.showDetails.item.shortSynopsis;
    },
    showImage() {
      if (!this.$store.state.showDetails.item) {
        return '';
      }
      const image = this.$store.state.showDetails.item.images.find(
        (x) => x.key === 'showBackgroundSite'
      );
      if (image) {
        return image.path;
      }
      return '';
    },
    subDubInfo() {
      const showDetails = this.$store.state.showDetails.item;
      if (!showDetails) {
        return '';
      }
      if (showDetails.isDubbed && !showDetails.isSubtitled) {
        return '- Dub';
      } else if (showDetails.isDubbed && showDetails.isSubtitled) {
        return '- Sub & Dub';
      } else if (!showDetails.isDubbed && showDetails.isSubtitled) {
        return '- Sub';
      }
      return '';
    },
  },
  metaInfo() {
    return {
      title: `${this.showName} | Watch on Funimation`,
      meta: [
        {
          vmid: 'og:title',
          name: 'og:title',
          content: `Stream & Watch ${this.showName} Online ${this.subDubInfo}`,
        },
        {
          vmid: 'description',
          name: 'description',
          content: this.shortSynopsis,
        },
        {
          vmid: 'og:description',
          name: 'og:description',
          content: this.shortSynopsis,
        },
        {
          vmid: 'og:type',
          name: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: this.showImage,
        },
      ],
    };
  },
  components: {},
};
</script>
<style scoped lang="scss"></style>
