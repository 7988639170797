import GlobalHeader from './GlobalHeader.vue';
import AuxiliaryPanel from './AuxiliaryPanel/AuxiliaryPanel.vue';
import MainNavPanel from './MainNavPanel/MainNavPanel.vue';
import RibbonComponent from './Ribbon/Ribbon.vue';
import Search from './Search/Search.vue';
import { API_URLS } from './config/apiUrls';
import { Parser } from 'htmlparser2';
import { DomHandler } from 'domhandler';
import { getText } from 'domutils';
import axios from 'axios';

export interface RibbonState {
  item: Ribbon;
  loading: false;
}
export class Ribbon {
  config: any;
  constructor(config: any = {service: 'https://uat-api-funimationnow.dadcdigital.com'}) {
    const baseApiConfig = config.service;
    this.config = {
      base: baseApiConfig,
      headerModules: `${API_URLS.funimationNow.headerModules}${window.region}`,
    };
  }
  parseContent(data: string) {
    let content: string | undefined = '';
    const handler = new DomHandler(function (error: any, dom: any) {
      if (error) {
        throw new Error('Error parsing content ' + error);
      }
      dom.forEach((el: any) => {
        if (el.tagName === 'p') {
          content = getText(el);
        }
      });
      return content;
    });
    const parser = new Parser(handler, {
      decodeEntities: true,
      recognizeSelfClosing: true,
    });
    parser.write(data);
    parser.end();
    return content;
  }
  async fetchRibbon(token: string, subscriptionStatus: string): Promise<any> {
    let response;
    if (token) {
      response = await axios.get(this.config.base + this.config.headerModules, {
        headers: {
          'Authorization': `Token ${token}`,
        },
      });
    } else {
      response = await axios.get(this.config.base + this.config.headerModules);
    }
    const data = await response.data;
    const rawContent = data.items.filter((item: { slug: string }) => {
      if (subscriptionStatus === 'past due') {
        return item.slug.includes('header-grace-banner-ribbon');
      } else if (subscriptionStatus === 'suspended') {
        return item.slug.includes('header-suspended-banner-ribbon');
      }
      return item.slug.includes('header-promo-ribbon');
    });
    if (!rawContent[0]) {
      return {};
    }
    try {
      const parsedContent = this.parseContent(rawContent[0].content);
      const ribbonContent = { url: rawContent[0].clickThru, text: parsedContent };
      return ribbonContent;
    }
    catch (err) {
      return {};
    }
  }
  getSubscriptionStatus(context: any) {
    return context.rootState.userInfo.userData && context.rootState.userInfo.userData.subscription
      ?  context.rootState.userInfo.userData.subscription.status.toLowerCase()
      : 'never';
  }
}

export const setupStore = (context: any, config?: any) => {
  const ribbon = new Ribbon(config);
  const ribbonStore = {
    state: {
      item: null,
    },
    actions: {
      async fetchRibbon(context: any) {
        let result;

        const token = context.rootState.userInfo.token;
        const subscriptionStatus = ribbon.getSubscriptionStatus(context);
        try {
          result = await ribbon.fetchRibbon(token, subscriptionStatus);
        } catch (err) {
          const er = new Error('Error fetching promo content: ' + err);
          context.dispatch('error', er, { root: true });
        }
        context.commit('SET_RIBBON', result);
      },
    },
    mutations: {
      SET_RIBBON(state: RibbonState, payload: any) {
        state.item = payload;
      },
    },
  };
  const headerStore = {
    state: {
      drawer: false,
      searchDrawer: false,
    },
    actions: {
      toggleDrawerState(context: any) {
        if (context.state.searchDrawer) {
          context.dispatch('toggleSearchDrawerState');
        }
        context.commit('SET_DRAWER_STATE', !context.state.drawer);
      },
      toggleSearchDrawerState(context: any) {
        context.commit('SET_SEARCH_DRAWER_STATE', !context.state.searchDrawer);
      },
    },
    mutations: {
      SET_DRAWER_STATE(state: any, e: boolean) {
        state.drawer = e;
      },
      SET_SEARCH_DRAWER_STATE(state: any, e: boolean) {
        state.searchDrawer = e;
      },
    },
  };
  context.registerModule('header', headerStore);
  context.registerModule('ribbon', ribbonStore);
};

export default (context: any, config?: any) => {
  setupStore(context, config);
  return {
    install(Vue: any) {
      Vue.component('main-nav-panel', MainNavPanel);
      Vue.component('global-header-ribbon', RibbonComponent);
      Vue.component('global-header-search', Search);
      Vue.component('auxiliary-panel', AuxiliaryPanel);
      Vue.component('global-header', GlobalHeader);
    },
  };
};
