





























































































import Vue from 'vue';
import { compareStrings } from '@funimation/comp-utils';
import { enFooterData, esFooterData } from './footerData';


export default Vue.extend({
  name: 'global-footer',
  data() {
    return {
      fixed: false,
      globalFooter: this.$t('globalFooter'),
    };
  },
  props: {
    theme: String,
  },
  computed: {
    region(): string {
      return window.region;
    },
    isCanada(): boolean {
      return compareStrings(window.region, 'ca');
    },
    isUs(): boolean {
      return compareStrings(window.region, 'us');
    },
    footerMainNavData(): any {
      const footerData: any = this.globalFooter;
      const englishFooterData: any = enFooterData;
      const spanishFooterData: any = esFooterData;
      const enLocales = [ 'CA', 'GB', 'IE', 'AU', 'NZ' ];
      const esLocales = [ 'MX', 'CL', 'CO', 'PE' ];
      if ( this.region && enLocales.includes(this.region)) {
        footerData.mainNav = englishFooterData[this.region].globalFooter.mainNav;
      } else if ( this.region && esLocales.includes(this.region)) {
        footerData.mainNav = spanishFooterData[this.region].globalFooter.mainNav;
      }
      return footerData.mainNav;
    },
    footerSocialNavData(): any {
      const footerData: any = this.globalFooter;
      const englishFooterData: any = enFooterData;
      const enLocales = [ 'US', 'CA', 'GB', 'IE', 'AU', 'NZ' ];
      if ( this.region && enLocales.includes(this.region)) {
        footerData.social = englishFooterData[this.region].globalFooter.social;
      }
      return footerData.social;
    },
  },
});
