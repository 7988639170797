export default {
  'regions': {
    'NZ': [ 'OFLC' ],
    'AU': [ 'CBSC' ],
    'GB': [ 'BBFC' ],
    'US': [ 'TV-US', 'MPAA' ],
    'IE': [ 'RTE', 'IFCOF' ],
    'CA': [ 'CBSC', 'CHVRS' ],
    'BR': [ 'DJCTQ', 'CRRBR' ],
    'MX': [ 'RTC' ],
    'PE': [ 'RTC' ],
    'CO': [ 'RTC' ],
    'CL': [ 'RTC' ],
  },
  'systems': {
    'OFLC': {
      'G': [ 'G', false ],
      'PG': [ 'PG', false ],
      'M': [ 'M', false ],
      '13': [ 'RP13', false ],
      '15': [ 'R15', false ],
      '16': [ 'RP16', false ],
      '18': [ 'RP18', true ],
      'R': [ 'R', true ],
    },
    'CB': {
      'G': [ 'G', false ],
      'PG': [ 'PG', false ],
      'M': [ 'M', false ],
      'MA': [ 'MA', true ],
      'R': [ 'R', true ],
      'X': [ 'R', true ],
    },
    'TV-US': {
      'TV-G': [ 'TV-G', false ],
      'TV-PG': [ 'TV-PG', false ],
      'TV-14': [ 'TV-14', false ],
      'TV-MA': [ 'TV-MA', true ],
    },
    'MPAA': {
      'G': [ 'G', false ],
      'PG': [ 'PG', false ],
      'PG-13': [ 'PG-13', false ],
      'R': [ 'R', true ],
    },
    'BBFC': {
      'U': [ 'U', false ],
      'PG': [ 'PG', false ],
      '15': [ '15', false ],
      '12': [ '12A', false ],
      '18': [ '18', true ],
    },
    'CBSC': {
      'G': [ 'G', false ],
      'PG': [ 'PG', false ],
      '14': [ '14A', false ],
      '18': [ '18A', true ],
    },
    'CHVRS': {
      'G': [ 'G', false ],
      'PG': [ 'PG', false ],
      '14': [ '14+', false ],
      '18': [ '18+', true ],
    },
    'RTE': {
      'G': [ 'G', false ],
      'PG': [ 'PG', false ],
      '15': [ '15A', false ],
      '18': [ '18', true ],
    },
    'IFCOF': {
      'GA': [ 'GA', false ],
      'Ch': [ 'Ch', false ],
      'YA': [ 'YA', false ],
      'PS': [ 'PS', true ],
    },
    'DJCTQ': {
      'L': [ 'L', false ],
      '10': [ '10', false ],
      '12': [ '12', false ],
      '14': [ '14', false ],
      '16': [ '16', true ],
      '18': [ '18', true ],
    },
    'CRRBR': {
      'AL': [ 'AL', false ],
      'A10': [ 'A10', false ],
      'A12': [ 'A12', false ],
      'A14': [ 'A14', false ],
      'A16': [ 'A16', true ],
      'A18': [ 'A18', true ],
    },
    'RTC': {
      'AA': [ 'AA', false ],
      'A': [ 'A', false ],
      'B': [ 'B', false ],
      'B15': [ 'B15', false ],
      'C': [ 'C', true ],
      'D': [ 'D', true ],
    },
  },
};
