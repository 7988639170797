









import Vue from 'vue';

const VideoPlayerCore = Vue.extend({
  name: 'video-player-core',
  data() {
    return {
      resizeTimer: 0 as any,
      // this swtiches CC modes
      // 0 video content clamp
      // 1 viewport clamp
      ccMode: 0,
    };
  },
  mounted() {
    this.$store.dispatch('videoPlayerCore/_init', this.$refs.videoRef);
    if (this.ccMode === 1) {
      return;
    }
    window.removeEventListener('resize', this.resize);
    window.addEventListener('resize', this.resize);
    // start resize loop
    this.resize();
  },

  beforeDestroy() {
    this.$store.dispatch('videoPlayerCore/_destroy');
  },

  methods: {
    resize() {
      // debounce and loop every 2 seconds
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.resize();
      }, 2000);
      const el = document.querySelector('.vjs-text-track-display') as Record<string, any>;
      const vel =  document.querySelector('.vjs-tech') as Record<string, any>;
      // bail if elements are not ready yet
      if (!el || !vel) {
        return;
      }
      const style = this.textTrackInlineCss(vel);
      el.style.left = style.left;
      el.style.top = style.top;
      el.style.height = style.height;
      el.style.width = style.width;
    },
    textTrackInlineCss(vel: Record<string, any>) {
      // get h/w ratios based on reported video size
      const wRatio = vel.videoWidth / vel.videoHeight;
      const hRatio = vel.videoHeight / vel.videoWidth;
      const vw = window.innerWidth;
      const vh = window.innerHeight;
      // calculate ratio and clamp to viewport maxs
      const vph = Math.min(vw * hRatio, vh);
      const vpw = Math.min(vh * wRatio, vw);
      return {
        // clamp to viewport mins
        top: Math.max(((vh - vph) / 2), 0) + 'px',
        left: Math.max(((vw - vpw) / 2), 0) + 'px',
        height: vph + 'px',
        width: vpw + 'px',
      };
    },
  },
});

export default VideoPlayerCore;
