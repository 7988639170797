import { ActionTree, MutationTree, Module } from 'vuex';
import videojs, { VideoJsPlayer } from 'video.js';

import EventBus from './EventBus';

type RootState = Record<string, any>;
type PlayerInstance = VideoJsPlayer | null;

export interface ModuleLifecyclePayload {
  eventBus: EventBus;
  player: PlayerInstance;
  videojs: (typeof videojs);
}

/** A VideoPlayerModule extends the functionality of VideoPlayer. */
export interface VideoPlayerModule {
  destroy?: (payload: ModuleLifecyclePayload) => Promise<void> | void;
  install: (payload: ModuleLifecyclePayload) => Promise<void> | void;
  name: string;
}

interface State {
  eventBus: EventBus;
  modulesRegistered: Array<VideoPlayerModule>;
  readyToInit: boolean;
}

/** Used to get initial state of store. */
function getDefaultState(): State {
  return {
    eventBus: new EventBus(),
    modulesRegistered: [],
    readyToInit: false,
  };
}

const actions: ActionTree<State, RootState> = {
  /** Clean-up. Called on VideoPlayer.beforeDestroy(). */
  _destroy(context): void {
    context.dispatch('_modulesDestroy');

    context.commit('STATE_RESET');
  },

  /** Flag module as Ready to Initialize */
  _init(context): void {
    context.commit('READY_TO_INIT');
  },

  /**
   * Calls all registered Video Player Modules' OPTIONAL destroy() method; each
   * time the player is destroyed.
   */
  async _modulesDestroy(context): Promise<void> {
    context.state.eventBus.emit('modules-destroy-start');

    for (const module of context.state.modulesRegistered) {
      if (module.destroy) {
        try {
          await module.destroy({
            eventBus: context.state.eventBus,
            player: context.rootState.videoPlayerCore.player,
            videojs: context.rootState.videoPlayerCore.videojs,
          });

          context.state.eventBus.emit('modules-destroy-module', module.name);
        } catch (error) {
          context.state.eventBus.emit('modules-destroy-error', module.name);

          error.message = `Error while destroying Video Player Module "${module.name}". ${error.message}`;
          context.dispatch('error', error, { root: true });
        }
      }
    }

    context.state.eventBus.emit('modules-destroy-end');
  },

  /**
   * Calls all registered Video Player Modules' REQUIRED install() method; each
   * time the player is initialized.
   */
  async _modulesInstall(context): Promise<void> {
    context.state.eventBus.emit('modules-install-start');

    for (const module of context.state.modulesRegistered) {
      try {
        await module.install({
          eventBus: context.state.eventBus,
          player: context.rootState.videoPlayerCore.player,
          videojs: context.rootState.videoPlayerCore.videojs,
        });

        context.state.eventBus.emit('modules-install-module', module.name);
      } catch (error) {
        context.state.eventBus.emit('modules-install-error', module.name);

        error.message = `Failed to register Video Player Module "${module.name}". ${error.message}`;
        context.dispatch('error', error, { root: true });
      }
    }
    context.state.eventBus.emit('modules-install-end');
  },

  /**
   * Call this action to register your Video Player Module.
   * VideoPlayerModule.install() will be called each time the player is
   * initialized.
   */
  modulesRegister(context, module: VideoPlayerModule): void {
    context.commit('MODULES_REGISTERED_APPEND', module);

    context.state.eventBus.emit('modules-registered', module.name);
  },
};

const mutations: MutationTree<State> = {
  MODULES_REGISTERED_APPEND(state, payload) {
    state.modulesRegistered.push(payload);
  },

  READY_TO_INIT(state) {
    state.readyToInit = true;
  },

  STATE_RESET(state) {
    Object.assign(state, getDefaultState());
  },
};

const store: Module<State, RootState> = {
  actions,
  mutations,
  namespaced: true,
  state: getDefaultState(),
};

export default store;
