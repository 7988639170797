/* istanbul ignore file */

import NotFound from './NotFound.vue';
import AdditionalLinks from './AdditionalLinks/AdditionalLinks.vue';

export default {
  install(Vue: any): void {
    Vue.component('not-found', NotFound);
    Vue.component('additional-links', AdditionalLinks);
  },
};