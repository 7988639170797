import { API_URLS } from './config/apiUrls';
import axios from 'axios';
import {
  SpokenLanguage,
  SubtitleLanguage,
  Image,
  Episode,
  EpisodeMeta,
  ContinueWatching,
  UnSignedVideoResponse,
  ShowData,
  EpisodesRequest,
  EpisodeSlugRequest,
  ShowDetailsSlugRequest,
  EpisodesListRequest,
  ShowDetailsState
} from './types';


let Vue: any;
let fetchShowDetailsBySlugTimer: any;

export class ShowDetails {
  constructor(config: any = {
    service: 'https://title-api.uat.funimationsvc.com',
    projectorService: 'https://d33et77evd9bgg.cloudfront.net/data' }) {
    this.config = {
      base: config.service + '/v1',
      baseV2: config.service + '/v2',
      baseV3: config.service + '/v3',
      projectorBase: config.projectorService + '/v2',
    };
  }
  config: any;
  continueWatching: any;
  isAvailable(start: string, end: string) {
    const today = new Date();
    const startDate = Date.parse(start);
    const endDate = Date.parse(end);
    const checkDate = Date.parse(today.toString());

    return (checkDate <= endDate && checkDate >= startDate );
  }
  getAvailableAVODLanguages(version: any, languageArray: any) {
    for (const key in version) {
      if (typeof version[key] === 'object' && this.isAvailable(version[key].start, version[key].end)) {
        languageArray.push(key);
      }
    }
  }
  aggregateAvailableSeasonIds(season: any, region: string, availableSeasonIds: string[]) {
    const avails = season.availability[region]?.all;
    let isAvailable = false;
    if (avails) {
      for (const key in avails) {
        if (key === 'AVOD' || key === 'SVOD') {
          isAvailable = this.isAvailable(avails[key].start, avails[key].end);
          if (isAvailable && !availableSeasonIds.includes(season.contentId)) {
            availableSeasonIds.push(season.contentId);
          }
        }
      }
    }
  }
  isEpisodeAvailable(format: any) {
    let isSimulcastAvailable = false;
    let isUncutAvailable = false;
    let isExtrasAvailable = false;
    if (format.simulcast) {
      isSimulcastAvailable = this.isAvailable(format.simulcast.start, format.simulcast.end);
    }
    if (format.uncut) {
      isUncutAvailable = this.isAvailable(format.uncut.start, format.uncut.end);
    }
    if (format.extras) {
      isExtrasAvailable = this.isAvailable(format.extras.start, format.extras.end);
    }
    return isSimulcastAvailable  || isUncutAvailable || isExtrasAvailable;
  }
  isOutOfTerritory(show: any, region: string): boolean {
    const availsData = show.index.publishTerritoryDates[region]?.all;
    if (!availsData) {
      return true;
    }
    const isAvailable = this.isAvailable(availsData.start, availsData.end);
    return !isAvailable;
  }
  isDubbed(audioLanguages: SpokenLanguage[]) {
    // if a show is in japanese, koraen, cantonese or mandarin it is not consiered dub
    const nonDubLanguages = [
      'ja',
      'ko',
      'zh-ct',
      'zh_CT',
      'zh-mn',
      'zh_MN',
    ];
    const dubLanguagesArray = audioLanguages.find(x => !nonDubLanguages.includes(x.languageCode));
    return audioLanguages.length > 0 && dubLanguagesArray !== undefined;
  }
  isSubtitled(subtitleLanguages: SubtitleLanguage[]) {
    return subtitleLanguages.length > 0;
  }
  getMoviePoster(images: Image[]) {
    return images.find(x => x.key === 'Show Keyart');
  }
  getDefaultHeroImage(images: Image[]) {
    return {
      desktop: images.find(x => x.key === 'Show Background Site'),
      mobile: images.find(x => x.key === 'Show Detail Hero Phone'),
    };
  }
  getEpisodeHeroImage(images: Image[], episodeImages: Image[]): any {
    return {
      desktop: episodeImages.find(x => x.key === 'Episode Thumbnail'),
      mobile: images.find(x => x.key === 'Show Detail Hero Phone'),
    };
  }
  mergeEpisodesData(episodes: Episode[], episodesMeta: EpisodeMeta[]) {
    return episodes.map((episode) => {
      const metaItem = episodesMeta.find(x => x.episodeId === episode.venueId);
      if (metaItem) {
        return { ...episode, ...metaItem };
      }
      return episode;
    });
  }
  async fetchShowDetailsBySlug(slug: string, region: string): Promise<any> {
    const response = await axios.get(this.config.projectorBase + API_URLS.catalogProjection.show(slug));
    const data = response.data;
    data.isDubbed = this.isDubbed(data.audioLanguages);
    data.isSubtitled = this.isSubtitled(data.subtitleLanguages);
    data.posterImage = this.getMoviePoster(data.images);
    data.heroImage = this.getDefaultHeroImage(data.images);
    data.outOfTerritory = this.isOutOfTerritory(data, region);
    const availableSeasonIds: any = [];
    data.index.seasons.forEach((season: any) => {
      this.aggregateAvailableSeasonIds(season, region, availableSeasonIds);
    });
    data.availableSeasonIds = availableSeasonIds;
    data.seasons = data.seasons.filter((season: any) => availableSeasonIds.includes(season.id));
    data.seasonCount = data.seasons.filter((season: any) => { return /[S,s]eason/i.test(season.type); }).length;
    return data;
  }
  async fetchUserShowData(showData: ShowData, region: string, deviceType: string, token: string, locale: string): Promise<any> {
    const response = await axios.get(this.config.baseV3 + API_URLS.titleService.showMeta(showData.venueId, region, deviceType, locale), {
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
    const data = response.data;
    if ( !data.continueWatching?.episodeId ) {
      return showData;
    }
    showData.continueWatching = data.continueWatching;
    showData.inMyAnime = data.inMyAnime;
    const episodeData = await this.fetchEpisodeById(showData.continueWatching.episodeId);
    showData.continueWatching.episode = episodeData;
    showData.heroImage = await this.getEpisodeHeroImage(showData.images, episodeData.images);
    return showData;
  }
  async fetchEpisodeById(episodeId: number): Promise<any> {
    const response = await axios.get(this.config.projectorBase + API_URLS.catalogProjection.episode(episodeId));
    return response.data;
  }
  async fetchUnSignedVideoBySlug(showSlug: string, episodeSlug: string, region: string, deviceType: string, locale: string): Promise<UnSignedVideoResponse> {
    const response = await axios.get(
      `${this.config.base}${API_URLS.titleService.unSignedVideoBySlug(showSlug, episodeSlug, region, deviceType, locale)}`
    );
    return response.data;
  }
  async fetchEpisodesBySeason(seasonId: string): Promise<any> {
    const response = await axios.get(this.config.projectorBase + API_URLS.catalogProjection.episodesBySeason(seasonId));
    const data = response.data;

    const availableEpisodes: any = data.episodes.filter((episode: any) => {
      const avodData = episode.videoAvailability.all[window.region]?.AVOD;
      const svodData = episode.videoAvailability.all[window.region]?.SVOD;
      let isAvodAvailable = false;
      let isSvodAvailable = false;
      if (avodData) {
        isAvodAvailable = this.isEpisodeAvailable(avodData);
      }
      if (svodData) {
        isSvodAvailable = this.isEpisodeAvailable(svodData);
      }
      if (!isAvodAvailable) {
        episode.isSubRequired = true;
      }
      if (isSvodAvailable || isAvodAvailable) {
        return episode;
      }
    });
    data.episodes = availableEpisodes;
    return data;
  }
  async fetchUserEpisodesData(seasonId: number, token: string, episodeList: Episode[]): Promise<any> {
    const response = await axios.get(this.config.base + API_URLS.titleService.episodesMeta(seasonId), {
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
    const data = response.data;
    const mergedList = this.mergeEpisodesData(episodeList, data);
    return mergedList;
  }
  async addShowToQueue(showId: number, token: string) {
    await axios.post(this.config.base + API_URLS.titleService.addShowToQueue(), `showId=${showId}`, {
      headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
  async deleteShowFromQueue(showId: number, token: string) {
    await axios.delete(this.config.base + API_URLS.titleService.deleteShowFromQueue(showId), {
      headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
}

const setupStore = (context: any, config?: any) => {
  const showDetails = new ShowDetails(config);
  const store = {
    state: {
      show: null as ShowData | null,
      episodes: {},
      seasons: {},
      showsViewed: null as any[] | null,
      episodesViewed: null as any[] | null,
      continueWatching: null as ContinueWatching | null,
      unSignedVideo: null
    } as ShowDetailsState,
    actions: {
      async fetchEpisodeById(context: any, payload: EpisodesRequest) {
        const episode = context.rootState.showDetails.unSignedVideo;
        if (episode && episode.id === payload.id) {
          return;
        }
        let result;
        try {
          result = await showDetails
            .fetchEpisodeById(payload.id);
        } catch (err) {
          const er = new Error('Error fetching episode by id: ' + err);
          context.dispatch('error', er, { root: true });
          return;
        }
        context.commit('SET_UNSIGNED_VIDEO', result);
      },
      async fetchUnSignedVideoBySlug(context: any, payload: EpisodeSlugRequest) {
        let result;
        try {
          result = await showDetails
            .fetchUnSignedVideoBySlug(payload.showSlug, payload.episodeSlug, payload.region, payload.deviceType, payload.locale);
        } catch (err) {
          context.dispatch('videoPlayerModal/dispatchTechnicalError', undefined, { root: true });
          const er = new Error(`Error fetching episode by showSlug - ${payload.showSlug} and episodeSlug - ${payload.episodeSlug}`);
          context.dispatch('error', er, { root: true });
          return;
        }

        context.commit('SET_UNSIGNED_VIDEO', result);
      },
      async _fetchShowDetailsBySlug(context: any, payload: ShowDetailsSlugRequest) {
        const token = context.rootState.userInfo.token;
        let result;
        let userInfoResult;
        context.commit('SET_SHOW_DETAILS', null);
        try {
          result = await showDetails
            .fetchShowDetailsBySlug(payload.slug, payload.region);
        } catch (err) {
          const er = new Error('Error fetching show details by slug: ' + err.toString().replace('403', '404'));
          context.dispatch('error', er, { root: true });
        }
        context.commit('SET_SHOW_DETAILS', result);
        if (token) {
          try {
            userInfoResult = await showDetails.fetchUserShowData(result, payload.region, payload.deviceType, token, payload.locale);
          } catch (err) {
            if (err.toString().indexOf('401') > -1) {
              context.dispatch('logOutUser');
            } else {
              const er = new Error('Error fetching user specific show details by id: ' + err);
              context.dispatch('error', er, { root: true });
            }
          }
          context.commit('SET_USER_SHOW_DETAILS', userInfoResult);
        }
      },
      fetchShowDetailsBySlug(context: any, payload: ShowDetailsSlugRequest) {
        clearTimeout(fetchShowDetailsBySlugTimer);
        fetchShowDetailsBySlugTimer = setTimeout(async() => {
          await context.dispatch('_fetchShowDetailsBySlug', payload);
        }, 10);
      },
      async fetchEpisodesBySeason(context: any, payload: EpisodesListRequest) {
        let result;
        let userInfoResult;
        const token = context.rootState.userInfo.token;
        const seasons = context.rootState.showDetails.seasons;
        if (seasons[payload.id]) {
          return;
        }
        try {
          result = await showDetails
            .fetchEpisodesBySeason(payload.id);
        } catch (err) {
          const er = new Error('Error fetching episodes by season: ' + err);
          context.dispatch('error', er, { root: true });
        }
        context.commit('SET_SEASON_LIST', {
          id: payload.id,
          result: result?.episodes,
        });
        if (token) {
          try {
            userInfoResult = await showDetails
              .fetchUserEpisodesData(result.venueId, token, result.episodes);
          } catch (err) {
            if (err.toString().indexOf('401') > -1) {
              context.dispatch('logOutUser');
            } else {
              const er = new Error('Error fetching user specific info for episodes by season: ' + err);
              context.dispatch('error', er, { root: true });
            }
            userInfoResult = result;
          }
          context.commit('SET_USER_SEASON_LIST', {
            id: payload.id,
            episodes: userInfoResult,
          });
        }
      },
      async addShowToQueue(context: any, showId: number) {
        try {
          await showDetails
            .addShowToQueue(showId, context.rootState.userInfo.token);
        } catch (err) {
          const er = new Error('Error adding show to queue: ' + err);
          context.dispatch('error', er, { root: true });
        }
      },
      async deleteShowFromQueue(context: any, showId: number) {
        try {
          await showDetails
            .deleteShowFromQueue(showId, context.rootState.userInfo.token);
        } catch (err) {
          const er = new Error('Error deleting show from queue: ' + err);
          context.dispatch('error', er, { root: true });
        }
      },
    },
    getters: {
      playerUrl(state: Record<string, any>, getters: Record<string, any>,
        rootState: Record<string, any>, rootGetters: Record<string, any>) {
        return (showSlug: string, episodeSlug: string) => {
          if (config && typeof config.playerUrlFn === 'function') {
            return config.playerUrlFn(showSlug, episodeSlug);
          }
          return `/${rootGetters.regionLocale}/shows/${showSlug}/${episodeSlug}/`;
        };
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      episodeLanguages(state: Record<string, any>): any {
        return (episode: Record<string, any>) => {
          if (Object.keys(episode.videoOptions.audioLanguages).length === 0) {
            return [];
          }
          let languageList = [] as any;
          const region = window.region;
          const showDetails = new ShowDetails();
          languageList = episode.videoOptions.audioLanguages[region]?.all.map((lang: any) => {
            const hasCode = languageList.find((l: any) => {
              return lang.languageCode === l.languageCode && showDetails.isAvailable(lang.start, lang.end);
            });

            if (!hasCode && showDetails.isAvailable(lang.start, lang.end)) {
              return lang;
            }
          });
          const avodLanguages: any = [];
          const avodVersions = episode.videoAvailability.all && episode.videoAvailability.all[region]?.AVOD;

          if (avodVersions) {
            for (const [ key ] of Object.entries(avodVersions)) {
              if (showDetails.isAvailable(avodVersions[key].start, avodVersions[key].end)) {
                showDetails.getAvailableAVODLanguages(avodVersions[key], avodLanguages);
              }
            }
          }
          languageList = languageList.filter((lang: any) => {
            return Boolean(lang);
          }).map((lang: any) => {
            return {...lang, hasAvod: avodLanguages.includes(lang.languageCode)};
          });
          return languageList;
        };
      },
    },
    mutations: {
      SET_SHOW_DETAILS(state: ShowDetailsState, payload: any) {
        if (payload === null) {
          state.seasons = {};
        }
        state.show = payload;
      },
      SET_USER_SHOW_DETAILS(state: ShowDetailsState, payload: any) {
        if (!payload) {
          // no payload means 40x or 50x error  Display an error block.
          state.continueWatching = {
            noContinueWatchingDirective: true,
          };
          return;
        }
        state.continueWatching = payload.continueWatching;
      },
      SET_SEASON_LIST(state: ShowDetailsState, payload: any) {
        Vue.set(state.seasons, payload.id, payload.result);
      },
      SET_USER_SEASON_LIST(state: ShowDetailsState, payload: any) {
        Vue.set(state.seasons, payload.id, payload.episodes);
      },
      SET_UNSIGNED_VIDEO(state: ShowDetailsState, payload: UnSignedVideoResponse) {
        Vue.set(state.episodes, payload.id, payload);
        state.unSignedVideo = payload;
      },
    },
  };
  context.registerModule('showDetails', store);
};

export default (context: any, config?: any) => {
  setupStore(context, config);
  return {
    install(vue: any) {
      Vue = vue;
    },
  };
};
