





























































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'EpisodeCard',

  computed: {
    ...mapGetters({
      nextEpisode: 'videoPlayerCatalog/nextEpisode',
      currentEpisode: 'videoPlayerCatalog/currentEpisode',
      nextEpisodeKeyPosterArt: 'videoPlayerCatalog/nextEpisodeKeyPosterArt',
      episodeKeyPosterArt: 'videoPlayerCatalog/episodeKeyPosterArt',
      currentEpisodeRatingCode: 'videoPlayerCatalog/currentEpisodeRatingCode',
      nextEpisodeRatingCode: 'videoPlayerCatalog/nextEpisodeRatingCode',
      currentEpisodeName: 'videoPlayerCatalog/currentEpisodeName',
      nextEpisodeName: 'videoPlayerCatalog/nextEpisodeName',
      currentEpisodeNumber: 'videoPlayerCatalog/currentEpisodeNumber',
      nextEpisodeNumber: 'videoPlayerCatalog/nextEpisodeNumber',
      currentEpisodeDurationInMinutes: 'videoPlayerCatalog/currentEpisodeDurationInMinutes',
      nextEpisodeDurationInMinutes: 'videoPlayerCatalog/nextEpisodeDurationInMinutes',
    }),
    displayEpisode(): Record<string, any> | null | undefined {
      return this.nextEpisode || this.currentEpisode;
    },
    displayEpisodeRatingCode(): Record<string, any> | null | undefined {
      return this.nextEpisodeRatingCode || this.currentEpisodeRatingCode;
    },
    displayEpisodeName(): Record<string, any> | null | undefined {
      return this.nextEpisodeName || this.currentEpisodeName;
    },
    displayEpisodeNumber(): string {
      return this.nextEpisodeNumber || this.currentEpisodeNumber || '';
    },
    displayEpisodeDurationInMinutes(): number | null {
      return this.nextEpisodeDurationInMinutes || this.currentEpisodeDurationInMinutes ;
    },
    displayEpisodeThumbnail(): string {
      return this.nextEpisodeKeyPosterArt || this.episodeKeyPosterArt || '';
    },
  }
});
