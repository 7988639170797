<template>
  <content-carousel />
</template>

<script>
export default {
  components: {},
  data() {
    return {
      hover: false,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
