export interface EventSignature {
  event: string;
  callback: (action: string) => void;
}

export function trackEvent(store: any, eventAction: string, eventLabel?: string) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'Videos',
    eventAction: eventAction,
    eventLabel: eventLabel || store.getters['videoPlayerAnalytics/showAndEpisodeName'],
    audioPreference: store.getters['videoPlayerAnalytics/audioPreference'],
    autoPlay: store.getters['videoPlayerAnalytics/autoPlay'],
    AvodSvod: store.getters['videoPlayerAnalytics/avodSvod'],
    restrictmatureContent: store.getters['videoPlayerAnalytics/isMatureRestricted'],
    season: store.getters['videoPlayerAnalytics/episodeSeason'],
    showID: store.getters['videoPlayerAnalytics/showID'],
    showLanguage: store.getters['videoPlayerAnalytics/episodeLanguage'],
    showName: store.getters['videoPlayerAnalytics/showName'],
    showRating: store.getters['videoPlayerAnalytics/showRating'],
    videoID: store.getters['videoPlayerAnalytics/videoID'],
    videoPlayerVersion: '2.0.0',
    videoNumber: store.getters['videoPlayerAnalytics/episodeNumber'],
    videoTitle: store.getters['videoPlayerAnalytics/episodeTitle'],
    videoType: store.getters['videoPlayerAnalytics/videoType'],
    contentType: 'Episodes and Movies',
    customerAge: store.getters['videoPlayerAnalytics/customerAge'],
    customerGender: store.getters['videoPlayerAnalytics/customerGender'],
    customerType: store.getters['videoPlayerAnalytics/customerType'],
    dateAdded: store.getters['videoPlayerAnalytics/dateAdded'],
    enableClosedCaption: store.getters['videoPlayerAnalytics/enableClosedCaption'],
    enableSubtitles: store.getters['videoPlayerAnalytics/enableSubtitles'],
    freeTrial: store.getters['videoPlayerAnalytics/freeTrial'],
    gender: store.getters['videoPlayerAnalytics/customerGender'],
    planName: store.getters['videoPlayerAnalytics/planName'],
    qualityPreference: store.getters['videoPlayerAnalytics/qualityPreference'],
    requesttime: Date(),
    territory: store.getters['videoPlayerAnalytics/territory'],
    userAccessLevel: store.getters['videoPlayerAnalytics/userAccessLevel'],
    userCountry: store.getters['videoPlayerAnalytics/userCountry'],
    userID: store.getters['videoPlayerAnalytics/userID'],
  });
}

// handles the playback milestones
export function playbackProgress(context: any, playbackPercentage: number): string {
  if (!context.state.videoPlayerAnalytics.hitPlaybackStart) {
    // Don't track quartiles until Start has fired.
    return '';
  }

  let quartileHit = false;
  let quartile;

  if (playbackPercentage >= 0 && playbackPercentage < 25 && !context.state.videoPlayerAnalytics.hitQuartile0) {
    // Quartile 0
    quartileHit = true;
    context.commit('videoPlayerAnalytics/HIT_QUARTILE_0', true);
    quartile = 0;
  } else if (playbackPercentage >= 25 && playbackPercentage < 50 && !context.state.videoPlayerAnalytics.hitQuartile1) {
    // Quartile 1
    quartileHit = true;
    context.commit('videoPlayerAnalytics/HIT_QUARTILE_1', true);
    quartile = 1;
  } else if (playbackPercentage >= 50 && playbackPercentage < 75 && !context.state.videoPlayerAnalytics.hitQuartile2) {
    // Quartile 2
    quartileHit = true;
    context.commit('videoPlayerAnalytics/HIT_QUARTILE_2', true);
    quartile = 2;
  } else if (playbackPercentage >= 75 && playbackPercentage < 95 && !context.state.videoPlayerAnalytics.hitQuartile3) {
    // Quartile 3
    quartileHit = true;
    context.commit('videoPlayerAnalytics/HIT_QUARTILE_3', true);
    quartile = 3;
  } else if (playbackPercentage >= 95 && !context.state.videoPlayerAnalytics.hitQuartile4) {
    // Quartile 4
    quartileHit = true;
    context.commit('videoPlayerAnalytics/HIT_QUARTILE_4', true);
    quartile = 4;
  }

  if (quartileHit) {
    return 'Quartile ' + quartile + ' Complete';
  }

  if (playbackPercentage >= 95 && !context.state.videoPlayerAnalytics.hitPlaybackComplete) {
    context.commit('videoPlayerAnalytics/HIT_PLAYBACK_COMPLETE', true);

    return 'Completed';
  }
  return '';
}

/**
 * Returns true if the ad plugin is in ad mode. Ad Mode means the ad plugin
 * is currently preventing content playback.
 *
 * TODO: Move to store in @funimation/comp-video-player-ads-gam
 */
export function isInAdMode(player: Record<string, any>): boolean {
  const playerAds = player.ads;
  if (!playerAds) {
    return false;
  }
  return playerAds.isInAdMode();
}

export function setAnalyticsListeners(context: any) {
  // Validate options
  if (!context) {
    throw new Error('VideoPlayerAnalytics requires "store" option.');
  }
  let eventBus: any;
  const eventSignatures: Array<EventSignature> = [];
  context.dispatch('videoPlayer/modulesRegister', {
    name: 'video-player-analytics',
    install(installContext: any) {
      eventBus = installContext.eventBus;
      const player = installContext.player;

      const listeners = [
        {
          event: 'ad-start',
          fn: () => {
            trackEvent(context, 'Ad Start');
          },
        },
        {
          event: 'ad-interrupt',
          fn: () => {
            trackEvent(context, 'video ad interrupted');
          },
        },
        {
          event: 'ad-completed',
          fn: () => {
            trackEvent(context, 'Ad Completed');
          },
        },
        {
          event: 'ad-error',
          fn: () => {
            trackEvent(context, 'video ad error');
          },
        },
        {
          event: 'playback-firstplay',
          fn: () => {
            context.commit('videoPlayerAnalytics/HIT_PLAYBACK_START', true);

            // Rest Quartiles
            context.commit('videoPlayerAnalytics/HIT_QUARTILE_0', false);
            context.commit('videoPlayerAnalytics/HIT_QUARTILE_1', false);
            context.commit('videoPlayerAnalytics/HIT_QUARTILE_2', false);
            context.commit('videoPlayerAnalytics/HIT_QUARTILE_3', false);
            context.commit('videoPlayerAnalytics/HIT_QUARTILE_4', false);
            context.commit('videoPlayerAnalytics/HIT_PLAYBACK_COMPLETE', false);

            trackEvent(context, 'Start');
          },
        },
        {
          event: 'playback-pause',
          fn: () => {
            trackEvent(context, 'Interrupted');
          },
        },
        {
          event: 'media-error',
          fn: () => {
            trackEvent(context, 'Error');
          },
        },
        {
          event: 'readystate-waiting',
          fn: () => {
            trackEvent(context, 'Buffering');
          },
        },
        {
          event: 'network-suspend',
          fn: () => {
            trackEvent(context, 'Interrupted');
          },
        },
        {
          event: 'network-abort',
          fn: () => {
            trackEvent(context, 'Interrupted');
          },
        },
        {
          event: 'playback-time-current',
          fn: () => {
            if (isInAdMode(player)) {
              return; // Abort if in Ad Break.
            }

            const playbackPercentage = context.getters['videoPlayerAnalytics/playbackPercentage'];
            const playbackAction = playbackProgress(context, playbackPercentage);
            if (!playbackAction) {
              return;
            }

            trackEvent(context, playbackAction);
          },
        },
      ];
      listeners.forEach((listener) => {
        const eventSignature: EventSignature = {
          event: listener.event,
          callback: listener.fn,
        };
        eventBus.on(eventSignature.event, eventSignature.callback); // eventBus event listener capture
        eventSignatures.push(eventSignature);
      });
    },
    destroy() {
      eventSignatures.forEach((eventSignature) => {
        eventBus.off(eventSignature.event, eventSignature.callback);
      });
    },
  });
}
