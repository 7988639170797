export const enOutOfTerritory: any =  {
  US: {
    outOfTerritory: {
      message: 'Sorry, but this content isn’t available in your country.',
    },
  },
  CA: {
    outOfTerritory: {
      message: 'Sorry, but this content isn’t available in your country.',
    },
  },
  AU: {
    outOfTerritory: {
      message: 'Sorry, but this content isn’t available in your country.',
    },
  },
  NZ: {
    outOfTerritory: {
      message: 'Sorry, but this content isn’t available in your country.',
    },
  },
  GB: {
    outOfTerritory: {
      message: 'Sorry, but this content isn’t available in your country.',
    },
  },
  IE: {
    outOfTerritory: {
      message: 'Sorry, but this content isn’t available in your country.',
    },
  },
};
