export const enFooterData: any =  {
  US: {
    globalFooter: {
      social: [
        {
          title: 'facebook',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/facebook.svg',
          href: 'https://www.facebook.com/funimation',
          target: '_blank',
        },
        {
          title: 'twitter',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/twitter.svg',
          href: 'https://twitter.com/funimation',
          target: '_blank',
        },
        {
          title: 'youtube',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/youtube.svg',
          href: 'https://www.youtube.com/user/FUNimation',
          target: '_blank',
        },
        {
          title: 'instagram',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/instagram.svg',
          href: 'https://www.instagram.com/funimation',
          target: '_blank',
        },
        {
          title: 'pinterest',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/pinterest.svg',
          href: 'https://www.pinterest.com/funimation',
          target: '_blank',
        },
        {
          title: 'tiktok',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/tiktok.svg',
          href: 'https://www.tiktok.com/@funimation',
          target: '_blank',
        },
      ],
      mainNav: [
        {
          title: 'about funimation',
          href: 'https://www.funimation.com/about-us/',
        },
        {
          title: 'customer support',
          href: 'https://help.funimation.com/',
        },
        {
          title: 'terms of use',
          href: 'https://www.funimation.com/terms-of-use/',
        },
        {
          title: 'terms of sale',
          href: 'https://www.funimation.com/terms-of-sale/',
        },
        {
          title: 'privacy policy',
          href: 'https://www.sonypictures.com/corp/privacy.html',
        },
        {
          title: 'do NOT sell my personal information',
          href: 'https://privacyportal-cdn.onetrust.com/dsarwebform/d19e506f-1a64-463d-94e4-914dd635817d/b9eb997c-9ede-451b-8fd4-29891782a928.html',
        },
        {
          title: 'careers',
          href: 'https://www.funimation.com/careers/',
        },
      ],
      copyright: 'Crunchyroll, LLC. All Rights Reserved.',
    },
  },
  CA: {
    globalFooter: {
      social: [
        {
          title: 'facebook',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/facebook.svg',
          href: 'https://www.facebook.com/funimation',
          target: '_blank',
        },
        {
          title: 'twitter',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/twitter.svg',
          href: 'https://twitter.com/funimation',
          target: '_blank',
        },
        {
          title: 'youtube',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/youtube.svg',
          href: 'https://www.youtube.com/user/FUNimation',
          target: '_blank',
        },
        {
          title: 'instagram',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/instagram.svg',
          href: 'https://www.instagram.com/funimation',
          target: '_blank',
        },
        {
          title: 'pinterest',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/pinterest.svg',
          href: 'https://www.pinterest.com/funimation',
          target: '_blank',
        },
        {
          title: 'tiktok',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/tiktok.svg',
          href: 'https://www.tiktok.com/@funimation',
          target: '_blank',
        },
      ],
      mainNav: [
        {
          title: 'about funimation',
          href: 'https://www.funimation.com/about-us/',
        },
        {
          title: 'customer support',
          href: 'https://help.funimation.com/',
        },
        {
          title: 'terms of use',
          href: 'https://www.funimation.com/terms-of-use/',
        },
        {
          title: 'terms of sale',
          href: 'https://www.funimation.com/terms-of-sale/',
        },
        {
          title: 'privacy policy',
          href: 'https://www.sonypictures.com/corp/privacy.html',
        },
      ],
      copyright: 'Crunchyroll, LLC. All Rights Reserved.',
    },
  },
  AU: {
    globalFooter: {
      social: [
        {
          title: 'facebook',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/facebook.svg',
          href: 'https://www.facebook.com/funimation',
          target: '_blank',
        },
        {
          title: 'twitter',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/twitter.svg',
          href: 'https://twitter.com/funimation',
          target: '_blank',
        },
        {
          title: 'youtube',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/youtube.svg',
          href: 'https://www.youtube.com/playlist?list=PLwazKLaN7rLid3rdNgjgEfUq4zda3ZBUK',
          target: '_blank',
        },
        {
          title: 'instagram',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/instagram.svg',
          href: 'https://www.instagram.com/funimation',
          target: '_blank',
        },
        {
          title: 'pinterest',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/pinterest.svg',
          href: 'https://www.pinterest.com/funimation',
          target: '_blank',
        },
        {
          title: 'tiktok',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/tiktok.svg',
          href: 'https://www.tiktok.com/@funimation',
          target: '_blank',
        },
      ],
      mainNav: [
        {
          title: 'about funimation',
          href: 'https://www.funimation.com/about-us/',
        },
        {
          title: 'customer support',
          href: 'https://help.funimation.com/',
        },
        {
          title: 'terms of use',
          href: 'https://www.funimation.com/terms-of-use/',
        },
        {
          title: 'privacy policy',
          href: 'https://www.sonypictures.com/corp/privacy.html',
        },
      ],
      copyright: 'Crunchyroll, LLC. All Rights Reserved.',
    },
  },
  NZ: {
    globalFooter: {
      social: [
        {
          title: 'facebook',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/facebook.svg',
          href: 'https://www.facebook.com/funimation',
          target: '_blank',
        },
        {
          title: 'twitter',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/twitter.svg',
          href: 'https://twitter.com/funimation',
          target: '_blank',
        },
        {
          title: 'youtube',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/youtube.svg',
          href: 'https://www.youtube.com/playlist?list=PLwazKLaN7rLid3rdNgjgEfUq4zda3ZBUK',
          target: '_blank',
        },
        {
          title: 'instagram',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/instagram.svg',
          href: 'https://www.instagram.com/funimation',
          target: '_blank',
        },
        {
          title: 'pinterest',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/pinterest.svg',
          href: 'https://www.pinterest.com/funimation',
          target: '_blank',
        },
        {
          title: 'tiktok',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/tiktok.svg',
          href: 'https://www.tiktok.com/@funimation',
          target: '_blank',
        },
      ],
      mainNav: [
        {
          title: 'about funimation',
          href: 'https://www.funimation.com/about-us/',
        },
        {
          title: 'customer support',
          href: 'https://help.funimation.com/',
        },
        {
          title: 'terms of use',
          href: 'https://www.funimation.com/terms-of-use/',
        },
        {
          title: 'privacy policy',
          href: 'https://www.sonypictures.com/corp/privacy.html',
        },
      ],
      copyright: 'Crunchyroll, LLC. All Rights Reserved.',
    },
  },
  GB: {
    globalFooter: {
      social: [
        {
          title: 'facebook',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/facebook.svg',
          href: 'https://www.facebook.com/funimationUK',
          target: '_blank',
        },
        {
          title: 'twitter',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/twitter.svg',
          href: 'https://twitter.com/funimationUK',
          target: '_blank',
        },
        {
          title: 'youtube',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/youtube.svg',
          href: 'https://www.youtube.com/playlist?list=PLwazKLaN7rLid3rdNgjgEfUq4zda3ZBUK',
          target: '_blank',
        },
        {
          title: 'instagram',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/instagram.svg',
          href: 'https://www.instagram.com/funimation',
          target: '_blank',
        },
        {
          title: 'tiktok',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/tiktok.svg',
          href: 'https://www.tiktok.com/@funimation',
          target: '_blank',
        },
      ],
      mainNav: [
        {
          title: 'about funimation',
          href: 'https://www.funimation.com/about-us/',
        },
        {
          title: 'customer support',
          href: 'https://help.funimation.com/',
        },
        {
          title: 'terms of use',
          href: 'https://www.funimation.com/terms-of-use/',
        },
        {
          title: 'terms of sale',
          href: 'https://www.funimation.com/terms-of-sale/',
        },
        {
          title: 'privacy policy',
          href: 'https://www.sonypictures.co.uk/privacy-policy',
        },
        {
          title: 'modern slavery transparency statement',
          href: 'https://www.sonypictures.com/corp/pdfs/modern_slavery_transparency_statement.pdf',
        },
      ],
      copyright: 'Crunchyroll, LLC. All Rights Reserved.',
    },
  },
  IE: {
    globalFooter: {
      social: [
        {
          title: 'facebook',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/facebook.svg',
          href: 'https://www.facebook.com/funimationUK',
          target: '_blank',
        },
        {
          title: 'twitter',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/twitter.svg',
          href: 'https://twitter.com/funimationUK',
          target: '_blank',
        },
        {
          title: 'youtube',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/youtube.svg',
          href: 'https://www.youtube.com/playlist?list=PLwazKLaN7rLid3rdNgjgEfUq4zda3ZBUK',
          target: '_blank',
        },
        {
          title: 'instagram',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/instagram.svg',
          href: 'https://www.instagram.com/funimation',
          target: '_blank',
        },
        {
          title: 'tiktok',
          icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/tiktok.svg',
          href: 'https://www.tiktok.com/@funimation',
          target: '_blank',
        },
      ],
      mainNav: [
        {
          title: 'about funimation',
          href: 'https://www.funimation.com/about-us/',
        },
        {
          title: 'customer support',
          href: 'https://help.funimation.com/',
        },
        {
          title: 'terms of use',
          href: 'https://www.funimation.com/terms-of-use/',
        },
        {
          title: 'terms of sale',
          href: 'https://www.funimation.com/terms-of-sale/',
        },
        {
          title: 'privacy policy',
          href: 'https://www.sonypictures.co.uk/privacy-policy',
        },
      ],
      copyright: 'Crunchyroll, LLC. All Rights Reserved.',
    },
  },
};

export const esFooterData: any = {
  MX: {
    globalFooter: {
      mainNav: [
        {
          title: 'ACERCA DE FUNIMATION',
          href: 'https://www.funimation.com/es-mx/about-us/',
        },
        {
          title: 'ATENCIÓN AL CLIENTE',
          href: 'https://help.funimation.com/hc/es-mx',
        },
        {
          title: 'TÉRMINOS DE USO',
          href: 'https://www.funimation.com/es-mx/terms-of-use/',
        },
        {
          title: 'TÉRMINOS DE VENTA',
          href: 'https://www.funimation.com/es-mx/terms-of-sale/',
        },
        {
          title: 'POLÍTICA DE PRIVACIDAD',
          href: 'https://www.funimation.com/es-mx/privacy-policy/',
        },
      ],
      copyright: 'Crunchyroll, LLC. Todos los derechos reservados.',
    },
  },
  CL: {
    globalFooter: {
      mainNav: [
        {
          title: 'ACERCA DE FUNIMATION',
          href: 'https://www.funimation.com/es-cl/about-us/',
        },
        {
          title: 'ATENCIÓN AL CLIENTE',
          href: 'https://help.funimation.com/hc/es-cl',
        },
        {
          title: 'TÉRMINOS DE USO',
          href: 'https://www.funimation.com/es-cl/terms-of-use/',
        },
        {
          title: 'TÉRMINOS DE VENTA',
          href: 'https://www.funimation.com/es-mx/terms-of-sale/',
        },
        {
          title: 'POLÍTICA DE PRIVACIDAD',
          href: 'https://www.funimation.com/es-cl/privacy-policy/',
        },
      ],
      copyright: 'Crunchyroll, LLC. Todos los derechos reservados.',
    },
  },
  CO: {
    globalFooter: {
      mainNav: [
        {
          title: 'ACERCA DE FUNIMATION',
          href: 'https://www.funimation.com/es-co/about-us/',
        },
        {
          title: 'ATENCIÓN AL CLIENTE',
          href: 'https://help.funimation.com/hc/es-co',
        },
        {
          title: 'TÉRMINOS DE USO',
          href: 'https://www.funimation.com/es-co/terms-of-use/',
        },
        {
          title: 'TÉRMINOS DE VENTA',
          href: 'https://www.funimation.com/es-mx/terms-of-sale/',
        },
        {
          title: 'POLÍTICA DE PRIVACIDAD',
          href: 'https://www.funimation.com/es-co/privacy-policy/',
        },
      ],
      copyright: 'Crunchyroll, LLC. Todos los derechos reservados.',
    },
  },
  PE: {
    globalFooter: {
      mainNav: [
        {
          title: 'ACERCA DE FUNIMATION',
          href: 'https://www.funimation.com/es-pe/about-us/',
        },
        {
          title: 'ATENCIÓN AL CLIENTE',
          href: 'https://help.funimation.com/hc/es-pe',
        },
        {
          title: 'TÉRMINOS DE USO',
          href: 'https://www.funimation.com/es-pe/terms-of-use/',
        },
        {
          title: 'TERMOS E CONDIÇÕES DE VENDA',
          href: 'https://www.funimation.com/es-pe/terms-of-sale/',
        },
        {
          title: 'POLÍTICA DE PRIVACIDAD',
          href: 'https://www.funimation.com/es-pe/privacy-policy/',
        },
      ],
      copyright: 'Crunchyroll, LLC. Todos los derechos reservados.',
    },
  },
};
