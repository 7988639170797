




































































































































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import { compareStrings } from '@funimation/comp-utils';

export default Vue.extend({
  name: 'content-details-grid',
  data() {
    return {
      detailsCardGridContent: this.$t('detailsCardGridContent'),
    };
  },
  async mounted() {
    const slug = this.route.params.slug || window.defaultShow;
    await this.fetchShowDetailsBySlug({
      slug: slug,
      region: this.region,
      deviceType: 'web',
      locale: this.userLanguage,
    });
  },
  computed: {
    ...mapState({
      route: (state: any) => state.route,
      show: (state: any) => state.showDetails.show,
      userLanguage: (state: any) => state.userInfo.userLanguage,
      region: (state: any) => state.userInfo.region,
    }),
    rating(): string {
      if (!Array.isArray(this.show.ratings) || this.show.ratings.length < 1) {
        return '';
      }

      const matchingRating = this.show.ratings.find((rating: Record<string, any>) => {
        return compareStrings(rating.region, this.region);
      });

      return matchingRating?.ratingCode || '';
    },
    displayAudioLanguages(): string {
      let audioLanguages = '';
      this.show.audioLanguages.map((language: any, i: number) => {
        audioLanguages += language.name[window.locale];
        audioLanguages += (i < this.show.audioLanguages.length - 1) ? ', ' : '';
      });
      return audioLanguages;
    },
    displaySubtitleLanguages(): string {
      let subtitleLanguages = '';
      this.show.subtitleLanguages.map((language: any, i: number) => {
        subtitleLanguages += language.name[window.locale];
        subtitleLanguages += (i < this.show.subtitleLanguages.length - 1) ? ', ' : '';
      });
      return subtitleLanguages;
    },
  },
  methods: {
    ...mapActions({
      fetchShowDetailsBySlug: 'fetchShowDetailsBySlug',
    }),
  },
});
