






























































import Vue from 'vue';
import { mapActions, mapState,mapGetters } from 'vuex';
import { compareStrings } from '@funimation/comp-utils';


export default Vue.extend({
  name: 'global-header-ribbon',
  computed: {
    ...mapState({
      ribbon: (state: any) =>
        state.ribbon ? state.ribbon.item : {},
    }),
    ...mapGetters([ 'isLoggedIn' ]),
    subscriptionState() {
      const state = this.$store.state;
      return state.userInfo.userData && state.userInfo.userData.subscription
        ? state.userInfo.userData.subscription.status.toLowerCase()
        : 'never';
    },
    isAnimeLabRegion() {
      return (compareStrings(window.region, 'au') || compareStrings(window.region, 'nz'));
    },
    bannerColor() {
      return this.subscriptionState === 'past due' || this.subscriptionState === 'suspended'? 'error' : 'primary';
    },
    bannerTextTransform() {
      return this.subscriptionState === 'past due' || this.subscriptionState === 'suspended'? 'ribbon font-weight-bold text-center text-uppercase' : 'ribbon font-weight-bold text-center';
    },
    showIcon() {
      return this.subscriptionState === 'past due' || this.subscriptionState === 'suspended';
    },
  },
  methods: {
    ...mapActions({
      fetchRibbon: 'fetchRibbon',
    }),
  },
  watch: {
    '$store.state.userInfo.userDataReady'(newValue: boolean) {
      if (!newValue) {
        return;
      }

      this.fetchRibbon();
    },
  },
});
