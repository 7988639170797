import { PluginObject } from 'vue';
import { Store } from 'vuex';

import VueComponent from './index.vue';
import vuexModule from './store';

type RootState = Record<string, any>;
type RootStore = Store<RootState>;
type ModuleOptions = {};

/** Vue Plugin Install function, which sets-up the module on Vue.use(). */
const pluginFactory = (store: RootStore): PluginObject<ModuleOptions> => {
  return {
    install(Vue) {
      // Validate options
      if (!store) {
        throw new Error('VideoPlayer requires "store" option.');
      }

      store.registerModule('videoPlayer', vuexModule);

      /*
       * Install Video Player Modules when videoPlayerCore.player is available
       * and videoPlayer.readyToInit.
       */
      function watchValidation(store: RootStore) {
        return store.state.videoPlayer.readyToInit && store.state.videoPlayerCore?.player;
      }
      store.watch(
        () => watchValidation(store),
        async () => {
          if (!watchValidation(store)) {
            return;
          }
          await store.dispatch('videoPlayer/_modulesInstall');
        }
      );

      /* Load POSTER into player when data ready. */
      function watchEpisodeKeyPosterArt(store: RootStore) {
        return store.getters['videoPlayerCatalog/episodeKeyPosterArt'];
      }
      store.watch(
        () => watchEpisodeKeyPosterArt(store),
        () => {
          const episodeKeyPosterArt = watchEpisodeKeyPosterArt(store) || '';
          store.commit('videoPlayerCore/POSTER', episodeKeyPosterArt);
        }
      );

      /* Load videoSources & videoSubtitleSources into player when data ready. */
      function getSelectedVideo(store: RootStore) {
        return store.getters['videoPlayerCatalog/selectedVideo'];
      }
      store.watch(
        () => getSelectedVideo(store),
        () => {
          const selectedVideo = getSelectedVideo(store);
          store.dispatch('videoPlayerCore/setVideoSource', selectedVideo);

          // Always reload Subtitles after Video Sources update + set
          const subtitleSources = store.getters['videoPlayerCatalog/subtitleSources'];
          store.dispatch('videoPlayerCore/_initSubtitleTracks', subtitleSources);
        }
      );

      /* Update userInfo.selectedVolume from Player volume */
      function initPlayerVolumeWatch(store: RootStore) {
        function watchPlayerVolume(store: RootStore) {
          return store.state.videoPlayerCore?.volumeLevel;
        }
        store.watch(
          () => watchPlayerVolume(store),
          () => {
            const volume = watchPlayerVolume(store);
            store.dispatch('setSelectedVolume', volume);
          }
        );
      }

      /* Update Player volume from userInfo.selectedVolume */
      function watchUserInfoVolume(store: RootStore) {
        return store.state.userInfo?.selectedVolume;
      }
      const unwatchUserInfoVolume = store.watch(
        () => watchUserInfoVolume(store),
        () => {
          const newVolume = watchUserInfoVolume(store);
          if (Number.isNaN(newVolume)) {
            return;
          }

          // Only runs once
          unwatchUserInfoVolume();

          // Set initial volume from userInfo
          store.dispatch('videoPlayerCore/setVolume', newVolume);

          // Start watching playerVolume
          initPlayerVolumeWatch(store);
        }
      );

      // Start from languageChangePlayPosition time if it exists (user switched languages)
      store.watch(
        () => {
          return store.state.userInfo?.languageChangePlayPosition;
        },
        () => {
          const timeCurrent = store.state.userInfo?.languageChangePlayPosition;
          if (!timeCurrent) {
            return;
          }

          store.commit('SET_LANGUAGE_CHANGE_PLAY_POSITION', undefined);

          const currentTimeInt = Number.parseInt(timeCurrent);
          if (!Number.isFinite(currentTimeInt)) {
            return;
          }

          const player = store.state.videoPlayerCore?.player;
          player.one('loadedmetadata', () => {
            player.currentTime(currentTimeInt);
          });
        }
      );

      Vue.component('video-player', VueComponent);
    },
  };
};

export default pluginFactory;