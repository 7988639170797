export interface SearchParams {
  searchTerm: string;
  offset: number;
  limit: number;
  facet?: {
    versions?: string[];
    genres?: string[];
    languages?: string[];
    ratings?: string[];
  };
  lang: string;
  sortBy: string;
  sortDirection: string;
  track?: boolean;
  origin: string;
}
function facets(genres: string[],
  versions: string[],
  languages: string[],
  ratings: string[]): string {
  // %7C === | character
  const gfac = genres.map((g: string) => {
    return `f=genre%7C${encodeURIComponent(g)}`;
  }).join('&');
  const vfac = versions.map((g: string) => {
    return `f=version%7C${encodeURIComponent(g)}`;
  }).join('&');
  const lfac = languages.map((g: string) => {
    return `f=language%7C${encodeURIComponent(g)}`;
  }).join('&');
  const rfac = ratings.map((g: string) => {
    return `f=ratingPairs%7C${encodeURIComponent(g)}`;
  }).join('&');
  return (gfac ? `&${gfac}` : '')
    + (vfac ? `&${vfac}` : '')
    + (lfac ? `&${lfac}` : '')
    + (rfac ? `&${rfac}` : '');
}
export const API_URLS = {
  searchService: {
    search: (index: string, region: string, searchParams: SearchParams) => {
      let queryString;
      queryString = `index=${index}&region=${region}`;
      if (searchParams.searchTerm) {
        queryString += `&q=${searchParams.searchTerm}`;
      }
      if (searchParams.offset) {
        queryString += `&offset=${searchParams.offset}`;
      }
      if (searchParams.limit) {
        queryString += `&limit=${searchParams.limit}`;
      }
      if (searchParams.sortBy && searchParams.sortDirection) {
        queryString += `&sort=${searchParams.sortBy}%7C${searchParams.sortDirection}`;
      }
      if (searchParams.facet) {
        const f = facets(searchParams.facet.genres || [],
          searchParams.facet.versions || [],
          searchParams.facet.languages || [],
          searchParams.facet.ratings || []);
        if (f) {
          queryString += `${f}`;
        }
      }
      if (searchParams.lang) {
        queryString += `&lang=${searchParams.lang}`;
      }
      if (searchParams.track) {
        queryString += `&track=${searchParams.track}`;
      }
      return `/search?${queryString}`;
    },
  },
};
