













































































import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import Vue from 'vue';
import { enHeader } from './enHeader';
import { aunzHeader } from './aunzHeader';

export default Vue.extend({
  name: 'global-header',
  data() {
    return {
      hover: false,
      globalHeader: this.$t('globalHeader'),
      windowSize: {
        x: 0,
      },
    };
  },
  mounted () {
    this.closeDrawerOnResize();
  },
  computed: {
    ...mapState({
      drawer: (state: any) => state.header.drawer,
      searchDrawer: (state: any) => state.header.searchDrawer,
      userData: (state: any) => state.userInfo.userData,
    }),
    ...mapGetters([ 'isLoggedIn', 'isSubscriber' ]),
    region(): string {
      return window.region;
    },
    isOpen(): boolean {
      return this.drawer === true || this.hover === true;
    },
    headerData(): any {
      const headerData: any = this.globalHeader;
      const noShopEnLocales = [ 'GB', 'IE', 'AU', 'NZ' ];
      const aunzEnLocales = [ 'AU', 'NZ' ];
      if ( this.region && noShopEnLocales.includes(this.region)) {
        if ( aunzEnLocales.includes(this.region)) {
          headerData.mainNav = aunzHeader;
        } else {
          headerData.mainNav = enHeader;
        }
      }
      return headerData.mainNav;
    },
  },
  methods: {
    ...mapActions([ 'toggleDrawerState', 'toggleSearchDrawerState' ]),
    ...mapMutations([ 'SET_DRAWER_STATE', 'SET_SEARCH_DRAWER_STATE' ]),
    hoverOpen() {
      if (this.searchDrawer) {
        this.toggleSearchDrawerState();
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.hover = !this.hover;
      }
    },
    closeDrawerOnResize() {
      this.windowSize = { x: window.innerWidth };
      if ((this.windowSize.x >= 960) && this.drawer) {
        this.closeDrawer();
      }
    },
    closeDrawer() {
      this.SET_DRAWER_STATE(false);
      this.SET_SEARCH_DRAWER_STATE(false);
    },
    toggleSearch() {
      if (this.isOpen) {
        this.toggleDrawerState();
      }
      this.toggleSearchDrawerState();
    },
  },
});
