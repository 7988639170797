






















import Vue from 'vue';

const VueComponent = Vue.extend({
  name: 'template-rename',

  props: { // OPTIONAL: Removed if unused
    exampleProp: {
      default: 'DEFAULT VALUE',
      type: String,
    },

    examplePropList: {
      default() {
        return [];
      },
      type: Array,
    }
  },

  data() { // OPTIONAL: Removed if unused
    return {
      exampleData: 'DEFAULT VALUE',
    };
  },

  computed: { // OPTIONAL: Removed if unused
    exampleComputed(): string {
      return `exampleData: ${this.exampleData}, exampleProp: ${this.exampleProp}`;
    },
  },

  created() {
    // OPTIONAL: Removed if unused
  },

  mounted() {
    // OPTIONAL: Removed if unused
  },

  destroyed() {
    // OPTIONAL: Removed if unused
  },

  methods: { // OPTIONAL: Removed if unused
    exampleMethod(): string {
      return 'SOME VALUE';
    },
  },
});

export default VueComponent;
