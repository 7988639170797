







import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
export default Vue.extend({
  name: 'filter-sort-view',
  computed: {
    ...mapState({
      panel: (state: any) => state.filterSort.panel,
    }),
  },
  methods: {
    ...mapActions([ 'setFilterSortPanelState' ]),
  },
});
