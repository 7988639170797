import { PluginObject } from 'vue';
import { Store } from 'vuex';

import VueComponent from './index.vue';
import vuexModule from './store';

type RootState = Record<string, any>;
type RootStore = Store<RootState>;
type ModuleOptions = {};

/** Vue Plugin Install function, which sets-up the module on Vue.use(). */
const pluginFactory = (store: RootStore): PluginObject<ModuleOptions> => {
  return {
    install(Vue) {
      // Validate options
      if (!store) {
        throw new Error('VideoPlayerCore requires "store" option.');
      }

      store.registerModule('videoPlayerCore', vuexModule);

      Vue.component('video-player-core', VueComponent);
    },
  };
};

export default pluginFactory;
