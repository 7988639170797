

























































import Vue from 'vue';
export default Vue.extend({
  name: 'content-carousel',
  components: {},
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    isMobileAgent(): boolean {
      // FIXME: Rework navigator.userAgent usage
      // https://blog.chromium.org/2021/05/update-on-user-agent-string-reduction.html
      const mobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      return mobileBrowser;
    },
  },
  methods: {

  },
});
