import {createEpisodeGetters} from './episode';
import {
  AIP,
  ContinueWatching,
  Image,
  PlayerSpokenLanguage,
  PlayerSubtitleLanguage,
  PlayerVersion,
  RootState,
  SubtitleSource,
  VideoPlayerCatalogState,
  VideoSource
} from '../types';
import {
  compareStrings
} from '@funimation/comp-utils';
import {
  addLockedSpokenLanguages,
  addLockedSubtitleLanguages,
  addLockedVersions,
  convertAccessType,
  getLanguageTranslation,
  getUnlockedSpokenLanguages,
  getUnlockedSubtitleLanguages,
  getUnlockedVersions
} from './helpers';


export default {
  ...createEpisodeGetters(),
  currentShowId(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
    let showId = '';
    if (!getters.currentEpisode) {
      return showId;
    }

    showId = getters.currentEpisode.showId ?? '';

    return showId;
  },
  currentShowName(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): string {
    if (!getters.currentEpisode?.show?.name) {
      return '';
    }

    const userLanguage = (rootGetters.userLanguage || '').toLowerCase();
    let matchingName = getters.currentEpisode.show.name[userLanguage];
    if (!matchingName) {
      matchingName = Object.values(getters.currentEpisode.show.name)[0];
    }

    return matchingName;
  },
  currentShowSlug(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
    let showSlug = '';
    if (!getters.currentEpisode?.show) {
      return showSlug;
    }

    showSlug = getters.currentEpisode.show.slug ?? '';

    return showSlug;
  },
  currentSeasonName(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): string {
    if (!getters.currentEpisode?.season?.name) {
      return '';
    }

    const userLanguage = (rootGetters.userLanguage || '').toLowerCase();
    let matchingName = getters.currentEpisode.season.name[userLanguage];
    if (!matchingName) {
      matchingName = Object.values(getters.currentEpisode.season.name)[0];
    }

    return matchingName;
  },
  currentSeasonNumber(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
    if (!getters.currentEpisode?.season?.number) {
      return '';
    }

    return getters.currentEpisode.season.number;
  },
  episodeSpokenLanguages(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): PlayerSpokenLanguage[] {
    // Get Unlocked Values
    const videos: VideoSource[] = getters.currentEpisodeVideos ?? [];
    const spokenLanguages: PlayerSpokenLanguage[] = getUnlockedSpokenLanguages(
      videos,
      getters.currentEpisode?.videoOptions?.audioLanguages,
      rootGetters.userLanguage
    );

    // Add Not Present Locked SVOD Values
    if (!rootGetters.isUserPremium) {
      addLockedSpokenLanguages(
        spokenLanguages,
        getters.currentEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedVersion,
        getters.currentEpisode?.videoOptions?.audioLanguages,
        rootGetters.userLanguage,
        'SVOD'
      );
    }

    // Add Not Present Locked EST Values
    if (!getters.hasESTVideos) {
      addLockedSpokenLanguages(
        spokenLanguages,
        getters.currentEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedVersion,
        getters.currentEpisode?.videoOptions?.audioLanguages,
        rootGetters.userLanguage,
        'EST'
      );
    }

    return spokenLanguages;
  },
  experienceSubtitleLanguages(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): PlayerSubtitleLanguage[] {
    // Get Unlocked Values
    const subtitleLanguages: PlayerSubtitleLanguage[] = getUnlockedSubtitleLanguages(
      getters.subtitleSources,
      getters.currentEpisode?.videoOptions?.subtitleLanguages,
      rootGetters.userLanguage
    );

    // Add Not Present Locked SVOD Values
    if (!rootGetters.isUserPremium) {
      addLockedSubtitleLanguages(
        subtitleLanguages,
        getters.currentEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedVersion,
        rootGetters.selectedSpokenLanguage,
        getters.currentEpisode?.videoOptions?.subtitleLanguages,
        rootGetters.userLanguage,
        'SVOD'
      );
    }

    // Add Not Present Locked EST Values
    if (!getters.hasESTVideos) {
      addLockedSubtitleLanguages(
        subtitleLanguages,
        getters.currentEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedVersion,
        rootGetters.selectedSpokenLanguage,
        getters.currentEpisode?.videoOptions?.subtitleLanguages,
        rootGetters.userLanguage,
        'EST'
      );
    }

    return subtitleLanguages;
  },
  episodeVersions(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): PlayerVersion[] {
    // Add Unlocked Values
    const versions: PlayerVersion[] = getUnlockedVersions(
      getters.currentEpisodeVideos ?? [],
      rootGetters.selectedSpokenLanguage
    );

    // Add Not Present Locked SVOD Values
    if (!rootGetters.isUserPremium) {
      addLockedVersions(
        versions,
        getters.currentEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedSpokenLanguage,
        'SVOD'
      );
    }

    // Add Not Present Locked EST Values
    if (!getters.hasESTVideos) {
      addLockedVersions(
        versions,
        getters.currentEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedSpokenLanguage,
        'EST'
      );
    }

    return versions;
  },
  nextEpisodeSpokenLanguages(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): PlayerSpokenLanguage[] {
    // Get Unlocked Values
    const videos: VideoSource[] = getters.nextEpisodeVideos ?? [];
    const spokenLanguages: PlayerSpokenLanguage[] = getUnlockedSpokenLanguages(
      videos,
      getters.nextEpisode?.videoOptions?.audioLanguages,
      rootGetters.userLanguage
    );

    // Add Not Present Locked SVOD Values
    if (!rootGetters.isUserPremium) {
      addLockedSpokenLanguages(
        spokenLanguages,
        getters.nextEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedVersion,
        getters.nextEpisode?.videoOptions?.audioLanguages,
        rootGetters.userLanguage,
        'SVOD'
      );
    }

    // Add Not Present Locked EST Values
    if (!getters.hasESTVideos) {
      addLockedSpokenLanguages(
        spokenLanguages,
        getters.nextEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedVersion,
        getters.nextEpisode?.videoOptions?.audioLanguages,
        rootGetters.userLanguage,
        'EST'
      );
    }

    return spokenLanguages;
  },
  nextEpisodeVersions(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): PlayerVersion[] {
    // Add Unlocked Values
    const versions: PlayerVersion[] = getUnlockedVersions(
      getters.nextEpisodeVideos ?? [],
      rootGetters.selectedSpokenLanguage
    );

    // Add Not Present Locked SVOD Values
    if (!rootGetters.isUserPremium) {
      addLockedVersions(
        versions,
        getters.nextEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedSpokenLanguage,
        'SVOD'
      );
    }

    // Add Not Present Locked EST Values
    if (!getters.hasESTVideos) {
      addLockedVersions(
        versions,
        getters.nextEpisode?.videoAvailability,
        rootState.userInfo.region,
        rootGetters.selectedSpokenLanguage,
        'EST'
      );
    }

    return versions;
  },
  episodeKeyPosterArt(state: VideoPlayerCatalogState, getters: Record<string, any>): string | undefined {
    const images = getters.currentEpisode?.images ?? [];
    if (images.length < 1) {
      return;
    }

    let matchingImage = images.find((image: Image) => compareStrings(image.key, 'episode thumbnail'));
    if (!matchingImage) {
      matchingImage = images[0];
    }

    return matchingImage?.path;
  },
  currentEpisodeVideos(state: VideoPlayerCatalogState): VideoSource[] | null {
    return state.currentEpisodeVideos;
  },
  selectedVideo(state: VideoPlayerCatalogState): VideoSource | null | undefined {
    return state.selectedVideo;
  },
  selectedVideoAccessType(state: VideoPlayerCatalogState, getters: Record<string, any>): string | null | undefined {
    if (!getters.selectedVideo) {
      return;
    }

    const rawAccessType = getters.selectedVideo.accessType;

    return convertAccessType(rawAccessType);
  },
  subtitleSources(state: VideoPlayerCatalogState, getters: Record<string, any>): SubtitleSource[] {
    const videoSubtitles: SubtitleSource[] = getters.selectedVideo?.subtitles ?? [];
    const subtitleSources: SubtitleSource[] = [];

    videoSubtitles.forEach((subtitleFile: SubtitleSource) => {
      const subtitleAlreadyPresent = subtitleSources.some(
        presentSubtitle => compareStrings(presentSubtitle.languageCode, subtitleFile.languageCode))
      ;

      if (!subtitleAlreadyPresent) {
        subtitleSources.push(subtitleFile);
      }
    });

    return subtitleSources;
  },
  selectedVideoId(state: VideoPlayerCatalogState, getters: Record<string, any>): string | null {
    const selectedVideo = getters.selectedVideo;
    if (!selectedVideo?.venueVideoId) {
      return null;
    }

    return selectedVideo.venueVideoId;
  },
  hasESTVideos(state: VideoPlayerCatalogState, getters: Record<string, any>): boolean {
    if (!Array.isArray(getters.currentEpisodeVideos) || getters.currentEpisodeVideos.length < 1) {
      return false;
    }

    const hasESTVideos = getters.currentEpisodeVideos.some(video => {
      const accessType = convertAccessType(video.accessType);
      return compareStrings(accessType, 'EST');
    });

    return hasESTVideos;
  },
  selectedVideoAdMarkers(state: VideoPlayerCatalogState, getters: Record<string, any>): AIP[] {
    const selectedVideo = getters.selectedVideo;
    if (!Array.isArray(selectedVideo?.aips)) {
      return [];
    }

    return selectedVideo.aips;
  },
  selectedVideoManifest(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
    const selectedVideo = getters.selectedVideo;
    if (!selectedVideo?.manifestPath) {
      return '';
    }

    return selectedVideo.manifestPath;
  },
  selectedVideoManifestFileExt(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
    const selectedVideo = getters.selectedVideo;
    if (!selectedVideo?.fileExt) {
      return '';
    }

    return selectedVideo.fileExt;
  },
  continueWatching(state: VideoPlayerCatalogState): ContinueWatching | null {
    return state.continueWatching;
  },
  ...createEpisodeGetters('nextEpisode'),
  nextEpisodeKeyPosterArt(state: VideoPlayerCatalogState, getters: Record<string, any>): string | undefined {
    const images = getters.nextEpisode?.images;
    if (!Array.isArray(images) || images.length < 1) {
      return;
    }

    let matchingImage = images.find((image: Image) => compareStrings(image.key, 'episode thumbnail'));
    if (!matchingImage) {
      matchingImage = images[0];
    }

    return matchingImage?.path;
  },
  nextEpisodeExperienceMatches(state: VideoPlayerCatalogState): boolean | null {
    return state.nextEpisodeExperienceMatches;
  },
  matchingNextEpisodeExperienceIsSVODLocked(state: VideoPlayerCatalogState): boolean | null {
    return state.matchingNextEpisodeExperienceIsSVODLocked;
  },
  matchingNextEpisodeExperienceIsESTOnlyLocked(state: VideoPlayerCatalogState): boolean | null {
    return state.matchingNextEpisodeExperienceIsESTOnlyLocked;
  },
  altNextEpisodeExperience(state: VideoPlayerCatalogState): VideoSource | null {
    return state.altNextEpisodeExperience;
  },
  altNextEpisodeExperienceSpokenLanguage(state: VideoPlayerCatalogState): string | null {
    if (
      state.nextEpisodeExperienceMatches ||
      !state.altNextEpisodeExperience?.audioLanguage
    ) {
      return null;
    }

    return state.altNextEpisodeExperience.audioLanguage;
  },
  altNextEpisodeExperienceType(state: VideoPlayerCatalogState): string | null {
    if (
      state.nextEpisodeExperienceMatches ||
      !state.altNextEpisodeExperience?.version
    ) {
      return null;
    }

    return state.altNextEpisodeExperience.version;
  },
  nextEpisodeSVODPaywallError(state: VideoPlayerCatalogState): boolean | null {
    return state.nextEpisodeSVODPaywallError;
  },
  nextEpisodeESTOnlyPaywallError(state: VideoPlayerCatalogState): boolean | null {
    return state.nextEpisodeESTOnlyPaywallError;
  },
  selectedSpokenLanguageTranslation(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): string {
    if (
      !rootGetters.selectedSpokenLanguage
    ) {
      return '';
    }

    const languageTranslation = getLanguageTranslation(
      rootGetters.selectedSpokenLanguage,
      getters.currentEpisode?.videoOptions?.audioLanguages,
      rootGetters.userLanguage
    );

    return languageTranslation ?? rootGetters.selectedSpokenLanguage;
  },
};
