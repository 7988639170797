



































































































































































































import Vue from 'vue';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';

import { compareStrings } from '@funimation/comp-utils';

export default Vue.extend({
  name: 'content-hero',
  data() {
    return {
      showId: null as number | null,
      contentHero: this.$t('contentHero'),
      contentLoading: true,
      loading: false,
    };
  },
  async mounted() {
    const slug = this.route.params.slug || window.defaultShow;
    await this.fetchShowDetailsBySlug({
      slug: slug,
      region: this.region,
      deviceType: 'web',
      locale: this.userLanguage,
    });
    Vue.nextTick(() => {
      const readyHandler = () => {
        if (document.readyState == 'complete') {
          this.contentLoading = false;
          document.removeEventListener('readystatechange', readyHandler);
        }
      };
      document.addEventListener('readystatechange', readyHandler);
      readyHandler();
    });
  },
  watch: {
    show() {
      if (this.show && this.show.outOfTerritory) {
        this.SET_REGION_RESTRICTION(true);
        return;
      }
      if (this.show && this.show.seasons.length > 0) {
        const seasonData = window.sessionStorage.getItem(`selectedSeason-${this.show.id}`);
        const selectedSeasonId = seasonData ? seasonData : this.show.seasons[0].id;
        this.fetchEpisodesBySeason({
          id: selectedSeasonId,
          region: this.region,
          deviceType: 'web',
          locale: this.userLanguage,
        });
      }
    },
  },
  computed: {
    ...mapState({
      continueWatching: (state: any) => state.showDetails.continueWatching,
      route: (state: any) => state.route,
      show: (state: any) => state.showDetails.show,
      userData: (state: any) => state.userInfo.userData,
      episodesBySeason: (state: any) => state.userInfo.userData,
      seasons: (state: any) => state.showDetails.seasons,
      userLanguage: (state: any) => state.userInfo.userLanguage,
      region: (state: any) => state.userInfo.region,
    }),
    ...mapGetters([ 'isLoggedIn', 'playerUrl' ]),
    firstEpisode(): Record<string, any> | null {
      if (!this.seasons || Object.keys(this.seasons).length === 0) {
        return null;
      }
      return this.seasons[Object.keys(this.seasons)[0]][0];
    },
    firstEpisodeImage(): string {
      if (!this.firstEpisode) {
        return '';
      }
      if (!this.firstEpisode.images) {
        return '';
      }
      const img = this.firstEpisode.images.find((img: Record<string, any>) => {
        return img.key === 'Episode Thumbnail';
      });
      if (!img) {
        return '';
      }
      return img.path;
    },
    isImageLoadingReady(): boolean {
      return Boolean(this.show
        && this.continueWatching
        && !this.continueWatching.noContinueWatchingDirective
        && this.continueWatching.episode);
    },
    backgroundImage(): string {
      if (!this.show) {
        return '';
      }
      if (this.isImageLoadingReady && this.show.id !== this.continueWatching.episode.show.id) {
        return '';
      }
      if (this.isImageLoadingReady
        && this.continueWatching.episode.images
        && Array.isArray(this.continueWatching.episode.images)) {
        const i = this.continueWatching.episode.images.find((img: Record<string, any>) => {
          return img.key === 'Episode Thumbnail';
        });
        if (!i) {
          return this.firstEpisodeImage;
        }
        return i.path;
      }
      return this.firstEpisodeImage;
    },
    numberOfSeasons(): string {
      const seasonCount = this.show.seasonCount;
      const seasonText = seasonCount > 1 ? this.$t('contentHero.seasons').toString() : this.$t('contentHero.season').toString();
      return seasonCount + ' ' + seasonText;
    },
    rating(): string {
      if (!Array.isArray(this.show.ratings) || this.show.ratings.length < 1) {
        return '';
      }

      const matchingRating = this.show.ratings.find((rating: Record<string, any>) => {
        return compareStrings(rating.region, this.region);
      });

      return matchingRating?.ratingCode || '';
    },
    playerBtnLoading(): boolean {
      return this.isLoggedIn && !this.continueWatching;
    },
    hideContinueWatchingButton(): boolean {
      return this.continueWatching ? this.continueWatching.noContinueWatchingDirective : false;
    },
    nextShowLink(): string {
      if (this.continueWatching && this.continueWatching.noContinueWatchingDirective) {
        return '';
      }
      if (this.isLoggedIn && this.continueWatching) {
        const cw = this.continueWatching.episode;
        return this.playerUrl(cw.show.slug, cw.slug);
      }
      if (!this.seasons) {
        return '';
      }
      const seasonId = Object.keys(this.seasons)[0];
      const episodes = this.seasons[seasonId];
      if (!episodes || episodes.length === 0) {
        return '';
      }
      return this.playerUrl(this.show.slug, episodes[0].slug);
    },
    genres(): any[] {
      if (!Array.isArray(this.show.genres)) {
        return [];
      }

      return this.show.genres.map((genre: Record<string, any>) => {
        // Fallback to EN if local is missing.
        genre.name[this.userLanguage] = genre.name[this.userLanguage] || genre.name.en;

        return genre;
      });
    },
    continueWatchingAction(): string {
      if (!this.continueWatching.shortDisplayAction) {
        return '';
      }
      const key = this.continueWatching.shortDisplayAction.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word: string, index: number) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
      const contentHero = this.contentHero as Record<string,any>;
      return contentHero[key];
    },
  },
  methods: {
    ...mapActions({
      fetchEpisodesBySeason: 'fetchEpisodesBySeason',
      fetchShowDetailsBySlug: 'fetchShowDetailsBySlug',
      addShowToQueue: 'addShowToQueue',
      deleteShowFromQueue: 'deleteShowFromQueue',
    }),
    ...mapMutations([
      'SET_REGION_RESTRICTION',
    ]),
    getEpisodePctComplete(episode: any): number {
      if (!episode.duration ) {
        return 0;
      }
      return episode.checkpoint / episode.duration;
    },
    async addToQueue() {
      const slug = this.route.params.slug || window.defaultShow;
      this.loading = true;
      await this.addShowToQueue(this.show.venueId);
      this.loading = false;
      this.fetchShowDetailsBySlug({
        slug: slug,
        region: this.region,
        deviceType: 'web',
        locale: this.userLanguage,
      });
    },
    async removeFromQueue() {
      const slug = this.route.params.slug || window.defaultShow;
      this.loading = true;
      await this.deleteShowFromQueue(this.show.venueId);
      this.loading = false;
      this.fetchShowDetailsBySlug({
        slug: slug,
        region: this.region,
        deviceType: 'web',
        locale: this.userLanguage,
      });
    },
  },
});
