import { PluginObject } from 'vue';
import { Store } from 'vuex';

import VideoPlayerMetaOverlay from './VideoPlayerMetaOverlay.vue';

type StoreInstance = Store<undefined>;

/** Sets-up context and returns a Vue Plugin. */
const pluginFactory = (storeInstance: StoreInstance): PluginObject<typeof storeInstance> => {
  return {
    install(Vue: any) {
      Vue.component('video-player-meta-overlay', VideoPlayerMetaOverlay);
    },
  };
};

export default pluginFactory;

