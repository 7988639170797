





















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'NextEpisode',

  computed: {
    ...mapGetters({
      currentShowSlug: 'videoPlayerCatalog/currentShowSlug',
      nextEpisodeSlug: 'videoPlayerCatalog/nextEpisodeSlug',
    }),

    nextEpisodeHref(): string {
      const origin = window.location.origin; // "https://www.funimation.com"
      const routerBase = this.$router.options.base; // "/v/"" or "/"
      const showSlug = this.currentShowSlug;
      const nextEpisodeSlug = this.nextEpisodeSlug;

      return origin + routerBase + `${showSlug}/${nextEpisodeSlug}`;
    },
  },
});
