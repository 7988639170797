<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-expansion-panels light>
            <v-expansion-panel flat>
              <v-expansion-panel-header
                class="headline primary--text text-uppercase font-weight-bold"
                disable-icon-rotate
              >
                Tabs
                <template v-slot:actions>
                  <v-icon class="primary--text" x-large>mdi-code-tags</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card color="transparent" flat>
                  <v-card-text>
                    <div v-highlight >
                      <pre class="example-code-block language-Markup"><code>{{tabsLayout}}</code></pre>
                    </div>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <content-details-tabs />
    </v-col>
  </v-row>

</template>
<script>
import Vue from 'vue';
import 'vue-code-highlight/themes/duotone-sea.css';
import 'vue-code-highlight/themes/window.css';
import VueCodeHighlight from 'vue-code-highlight';
Vue.use(VueCodeHighlight);
export default {
  data () {
    return {
      tabsLayout: `
<template>
  <v-row>
    <v-col>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="white"
        :height="$vuetify.breakpoint.mdAndUp ? '72px' : '48px'"
        show-arrows
        slider-size="3"
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
          class="text-capitalize"
        >
        {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-divider style="margin-top: -2px"/>
      <v-tabs-items v-model="tab" class="py-10 transparent">
        <v-tab-item
        v-for="item in items"
        :key="item.tab"
        >
        <!--tabbed content start-->
        <component :is="item.tag" />
        <!--tabbed content end-->
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>
        `,
    };
  },
};
</script>
<style scoped lang="scss">
pre.example-code-block {
  width: 100%;
}
</style>
