






































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'GoogleAuth',

  computed: {
    ...mapState('googleAuth', [
      'idToken',
      'signingInOut',
      'profileData',
      'ready',
    ]),

    ...mapGetters('googleAuth', [
      'isSignedIn',
    ]),
  },

  methods: {
    ...mapActions('googleAuth', [
      'signIn',
      'signOut',
    ]),
  },
});
