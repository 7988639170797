import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';

type RootState = Record<string, any>;

type State = {
  // TODO:
};

/** Used to get initial state of store. */
export function getDefaultState(): State {
  return {
    // TODO:
  };
}

const actions: ActionTree<State, RootState> = {
  // TODO:
};

const getters: GetterTree<State, RootState> = {
  // TODO:
};

const mutations: MutationTree<State> = {
  // TODO:

  STATE_RESET(state, payload = {}) {
    Object.assign(state, {
      ...getDefaultState(),
      ...payload,
    });
  },
};

const store: Module<State, RootState> = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: getDefaultState(),
};

export default store;
