



















































































import Vue from 'vue';
export default Vue.extend({
  name: 'not-found',
  data() {
    return {
      pageNotFound: this.$t('pageNotFound'),
    };
  },
});
