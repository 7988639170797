








































































































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
  name: 'global-header-search',
  data() {
    return {
      searchInput: '',
      searchForm: this.$t('searchForm'),
    };
  },
  computed: {
    ...mapState({
      searchResults: (state: any) => state.searchResults.paneItem,
    }),
    region(): string {
      return window.region;
    },
    topResult(): any {
      return this.searchResults.items.hits[0];
    },
    description(): any {
      if (this.topResult.type === 'Product') {
        return this.topResult.shortDescription;
      } else {
        const locale = window.locale;
        return this.topResult.synopsisExt[locale].mediumSynopsis;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchSearchResults: 'fetchSearchResults',
    }),
    debounce(fn: any, wait: number, immed = false) {
      let timer: any;
      return function (this: any, ...args: any[]) {
        if (timer === undefined && immed) {
          fn.apply(this, args);
        }
        clearTimeout(timer);
        timer = setTimeout(() => fn.apply(this, args), wait);
        return timer;
      };
    },
    async search() {
      await this.fetchSearchResults({
        index: 'search-shows-products',
        region: this.region,
        searchParams: {
          lang: 'en',
          limit: 10,
          offset: 0,
          searchTerm: this.searchInput,
          origin: 'searchPane',
        },
      });
    },
  },
});
