/**
 * Minimal Event Bus abstraction, on top of the browsers native event methods.
 *
 * Taken from: https://css-tricks.com/lets-create-a-lightweight-native-event-bus-in-javascript/#an-example
 */
export default class EventBus<EventDetail = any> {
  private eventTarget: EventTarget;

  /**
   * Creates an EventTarget (via document.createComment) to dispatch and listen
   * to events on.
   */
  constructor() {
    // Note, EventTarget constructor not full supported in all target browsers.
    this.eventTarget = document.createComment('funi-event-bus');
  }

  /** Fires an Event on the Event Bus. */
  emit(type: string, detail?: EventDetail): void {
    const event = new CustomEvent(type, { detail });
    this.eventTarget.dispatchEvent(event);
  }

  /** Removes an Event Listener from the Event Bus */
  off(type: string, listener: EventListener): void {
    this.eventTarget.removeEventListener(type, listener);
  }

  /** Add an Event Listener to the Event Bus */
  on(type: string, listener: EventListener): void {
    this.eventTarget.addEventListener(type, listener);
  }

  /**
   * Add an Event Listener to the Event Bus; which only fires at most once after
   * being added. Listener will automatically be removed once fired.
   */
  once(type: string, listener: EventListener): void {
    this.eventTarget.addEventListener(type, listener, { once: true });
  }
}
