
































































































































































































import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';

export default Vue.extend({
  name: 'filter-sort-panel',
  data() {
    return {};
  },
  props: {
    context: Object,
    showGenre: Boolean,
    showRating: Boolean,
    alphabetical: Boolean,
    sequential: Boolean,
    chronological: Boolean,
    isEpisodeCardGrid: Boolean,
  },
  beforeMount() {
    this.CLEAR_FILTERS();
  },
  mounted(): void {
    this.setFilterContext(this.context);
  },
  computed: {
    ...mapState({
      versions: (state: any) => state.filterSort.versions,
      languages: (state: any) => state.filterSort.languages,
      genres: (state: any) => state.filterSort.genres,
      ratings: (state: any) => state.filterSort.ratings,
      panel: (state: any) => state.filterSort.panel,
      _sortBy: (state: any) => state.filterSort.sortBy,
      _genre: (state: any) => state.filterSort.genre,
      _language: (state: any) => state.filterSort.language,
      _rating: (state: any) => state.filterSort.rating,
      _version: (state: any) => state.filterSort.version,
    }),
    region(): string {
      return window.region;
    },
    locale(): string {
      return window.locale;
    },
    ratingsIntl(): any[] {
      if (!this.region || !this.ratings.regions) {
        return [];
      }
      const ratings = [] as any[];
      const systems = this.ratings.regions[this.region.toUpperCase()];
      systems.forEach((system: string) => {
        Object.keys(this.ratings.systems[system]).forEach((ratingId: string) => {
          const rating = this.ratings.systems[system][ratingId];
          if (!ratings.some(r => r.title === rating[0])) {
            ratings.push({
              system,
              ratingId,
              alt: `${system}: ${rating[0]}`,
              // | character used in search URL.
              // much easier to leave encoded here than try to
              // split later on.  A bit hacky, but rating needs to
              // be rewritten once we have a proper canonical API
              // so this is OK for now.
              searchValue: `${system}|${ratingId}`,
              mature: rating[1],
              title: rating[0],
            });
          }
        });
      });
      return ratings;
    },
    sortBy: {
      get(): string {
        return this._sortBy;
      },
      set(val: string): void {
        this.SET_FILTER_SORTED_BY(val);
      },
    },
    genre: {
      get(): string {
        return this._genre;
      },
      set(val: string): void {
        this.SET_FILTER_GENRE(val);
      },
    },
    language: {
      get(): string {
        return this._language;
      },
      set(val: string): void {
        this.SET_FILTER_LANGUAGE(val);
      },
    },
    rating: {
      get(): string {
        return this._rating;
      },
      set(val: string): void {
        this.SET_FILTER_RATING(val);
      },
    },
    version: {
      get(): string {
        return this._version;
      },
      set(val: string): void {
        this.SET_FILTER_VERSION(val);
      },
    },
    filterSortPanel: {
      get(): boolean {
        return this.panel;
      },
      set(val: boolean) {
        this.setFilterSortPanelState(val);
      },
    },
  },
  methods: {
    ...mapActions([
      'fetchSearchResults',
      'setFilterSortPanelState',
      'setFilterContext',
    ]),
    ...mapMutations([
      'CLEAR_FILTERS',
      'SET_FILTER_SORTED_BY',
      'SET_FILTER_GENRE',
      'SET_FILTER_LANGUAGE',
      'SET_FILTER_RATING',
      'SET_FILTER_VERSION',
    ]),
  },
  watch: {
    context(): void {
      this.setFilterContext(this.context);
    },
  },
});
