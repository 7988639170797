





















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'RewatchEpisode',

  computed: {
    ...mapGetters({
      currentShowSlug: 'videoPlayerCatalog/currentShowSlug',
    }),
  },

  methods: {
    replayEpisode() {
      // TODO: Refactor as action 'videoPlayerCore/replay'
      this.$store.state.videoPlayerCore.player.currentTime(0);
      this.$store.state.videoPlayerCore.player.play();

      // Reset module state
      this.$store.commit('SET_SHOW_END_SCREEN', false);
    },
  },
});
