<template>
  <v-row>
    <v-col>
      <h1 class="text-h1 text-uppercase font-weight-bold">Brand Colors</h1>
      <v-divider class="my-9" />
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card
            color="primary"
            height="144"
          >
            <div class="overline font-weight-bold pa-4">
              Primary:<br /> {{$vuetify.theme.themes.dark.primary}}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            color="secondary"
            height="144"
          >
            <div class="overline font-weight-bold pa-4">
              Secondary / Info:<br /> {{$vuetify.theme.themes.dark.secondary}}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            color="accent"
            height="144"
          >
            <div class="overline font-weight-bold pa-4">
              Accent:<br /> {{$vuetify.theme.themes.dark.accent}}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            color="error"
            height="144"
          >
            <div class="overline font-weight-bold pa-4">
              Error / Warning:<br /> {{$vuetify.theme.themes.dark.error}}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            color="success"
            height="144"
          >
            <div class="overline font-weight-bold pa-4">
              Success:<br /> {{$vuetify.theme.themes.dark.success}}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            color="white"
            height="144"
            light
          >
            <div class="overline font-weight-bold pa-4">
              White:<br /> {{$vuetify.theme.themes.dark.white}}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            color="black"
            height="144"
          >
            <div class="overline font-weight-bold pa-4">
              Black:<br /> {{$vuetify.theme.themes.dark.black}}
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Vue from 'vue';
import 'vue-code-highlight/themes/duotone-sea.css';
import 'vue-code-highlight/themes/window.css';
import VueCodeHighlight from 'vue-code-highlight';
Vue.use(VueCodeHighlight);
export default {
  name: 'Colors',
  data() {
    return {};
  },
};
</script>
<style lang="scss">
pre.example-code-block {
    width: 100%;
}
</style>
