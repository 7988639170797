export const aunzHeader: any = {
  0: {
    title:'What\'s New',
    to:'/whats-new',
    subNavItems:[
      {
        title:'New Releases',
        to:'/new-releases',
      },
      {
        title:'Schedule',
        to:'/schedule',
      },
      {
        title:'News',
        to:'/blog',
      },
    ],
  },
  1: {
    title:'Shows',
    to:'/shows',
    subNavItems:[
      {
        title:'All Shows',
        to:'/shows/all-shows',
      },
      {
        title:'Genres',
        to:'/genre',
      },
      {
        title:'SIMULDUB \u2122',
        to:'/simuldubs',
      },
    ],
  },
};
  