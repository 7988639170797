/* eslint-disable @typescript-eslint/no-use-before-define */


export function setNextEpisodeWatchers(context: Record<string, any>): Record<string, any> {
  // Get next episode data. Must be done in 4 steps in order to get metadata and calculate whether next episode experience matches.

  // Get Current Show Playlist
  const currentShowIdWatchRef = watchThenGetShowPlaylist(context);

  /* getNextEpisode */
  const showPlaylistWatchRef = watchThenGetNextEpisode(context);

  /* getNextEpisodeVideos */
  const nextEpisodeIdWatchRef = watchThenGetNextEpisodeVideos(context);

  /* getNextEpisodeExperienceMatches */
  const nextEpisodeVideosWatchRef = watchThenGetNextEpisodeExperienceMatches(context);

  return {
    currentShowIdWatchRef,
    showPlaylistWatchRef,
    nextEpisodeIdWatchRef,
    nextEpisodeVideosWatchRef
  };
}

function watchThenGetShowPlaylist(context: Record<string, any>) {
  return context.watch(
    function validateShowId() {
      return showIdValidate(context);
    },
    function getShowPlaylist() {
      if (!showIdValidate(context)) {
        return;
      }
  
      context.dispatch(
        'videoPlayerCatalog/getShowPlaylist',
        context.getters['videoPlayerCatalog/currentShowId']
      );
    },
    { immediate: true }
  );
}
  
function showIdValidate(context: Record<string, any>) {
  return context.getters['videoPlayerCatalog/currentShowId'];
}
  
function watchThenGetNextEpisode(context: Record<string, any>) {
  return context.watch(
    function validateShowPlaylist() {
      return showPlaylistValidate(context);
    },
    function fetchNextEpisode() {
      if (!showPlaylistValidate(context)) {
        return;
      }
  
      context.dispatch(
        'videoPlayerCatalog/getEpisodeData',
        {
          episodeToGet: 'nextEpisode'
        }
      );
    },
    { immediate: true }
  );
}
  
function showPlaylistValidate(context: Record<string, any>) {
  return context.state.videoPlayerCatalog?.showPlaylist && context.getters['videoPlayerCatalog/currentEpisodeId'];
}
  
function watchThenGetNextEpisodeVideos(context: Record<string, any>) {
  return context.watch(
    function validateNextEpisodeId() {
      return nextEpisodeIdValidate(context);
    },
    function fetchNextEpisodeVideos() {
      if (!nextEpisodeIdValidate(context)) {
        return;
      }
  
      context.dispatch(
        'videoPlayerCatalog/getEpisodeVideos',
        {
          episodeToGet: 'nextEpisode'
        }
      );
    },
    { immediate: true }
  );
}
  
function nextEpisodeIdValidate(context: Record<string, any>) {
  return context.getters['videoPlayerCatalog/nextEpisodeId'] &&
      context.state.userInfo?.region &&
      context.state.userInfo?.userDataReady;
}
  
function watchThenGetNextEpisodeExperienceMatches(context: Record<string, any>) {
  return context.watch(
    function validateNextEpisodeVideos() {
      return nextEpisodeVideosValidate(context);
    },
    function getNextEpisodeExperienceMatches() {
      if (!nextEpisodeVideosValidate(context)) {
        return;
      }
  
      context.dispatch('videoPlayerCatalog/getNextEpisodeExperienceMatches');
    },
    { immediate: true }
  );
}
  
function nextEpisodeVideosValidate(context: Record<string, any>) {
  return context.state.videoPlayerCatalog?.nextEpisodeVideos;
}
