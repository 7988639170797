














































































































import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'poster-card',
  data() {
    return {
      hover: false,
      posterCardContent: this.$t('posterCardContent'),
    };
  },
  props: {
    item: Object,
  },
  computed: {
    ...mapState({
      sourceRatings: (state: any) => state.filterSort.ratings,
      region: (state: any) => state.userInfo.region,
    }),
    ratings(): string {
      if (!this.region) {
        return '';
      }
      const systems = this.sourceRatings.regions[this.region as string];
      if (!systems) {
        return '';
      }
      const ratings = [] as string[];

      systems.forEach((system: string) => {
        this.item.ratingPairs.forEach((ratingPair: string) => {
          const ratingSystem = ratingPair.split('|')[0];
          const rating = ratingPair.split('|')[1];
          if (system === ratingSystem) {
            ratings.push(rating);
          }
        });
      });
      return ratings.join(', ');
    },
    languagesWithKey(): any[] {
      return this.item.languages.map((l: Record<string, string>) => {
        const key = `language${l}`.replace(/[^a-z0-9]+/gi, '');
        return { key, value: l };
      });
    },
    genresWithKey(): any[] {
      return this.item.genres.map((g: string) => {
        const key = `genre${g}`.replace(/[^a-z0-9]+/gi, '');
        return { key, value: g };
      });
    },
    synopsis() {
      const locale = window.locale;
      return locale === 'en'? this.item.synopsis.mediumSynopsis : this.item.synopsisExt[locale]?.mediumSynopsis || '';
    },
  },
  methods: {
    sanitizedGenre(genre: string): string {
      if (!genre) {
        return '';
      }
      return '/genre/' + genre.toLowerCase().replace(/\s+|\//g, '-');
    },
  },
});
