<template>
<v-row>
  <v-col>
    <v-expansion-panels light>
      <v-expansion-panel flat>
        <v-expansion-panel-header
          class="headline primary--text text-uppercase font-weight-bold"
          disable-icon-rotate
        >
          Global Footer
          <template v-slot:actions>
            <v-icon class="primary--text" x-large>mdi-code-tags</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card color="transparent" flat>
            <v-card-text>
              <div v-highlight >
                <pre class="example-code-block language-Markup"><code></code></pre>
              </div>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</v-row>
</template>

<script>
import Vue from 'vue';
import 'vue-code-highlight/themes/duotone-sea.css';
import 'vue-code-highlight/themes/window.css';
import VueCodeHighlight from 'vue-code-highlight';
Vue.use(VueCodeHighlight);

export default {
  computed: {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
