import {
  ContinueWatching,
  PlaylistEpisode,
  TitleEpisode,
  VideoSource,
  VideoPlayerCatalogState
} from '../types';


export default {
  SET_AIPS(state: VideoPlayerCatalogState, payload: Record<string, any>[] ) {
    state.aips = payload;
  },
  SET_CURRENT_EPISODE(state: VideoPlayerCatalogState, payload: TitleEpisode) {
    state.currentEpisode = payload;
  },
  SET_SHOW_PLAYLIST(state: VideoPlayerCatalogState, payload: PlaylistEpisode[]) {
    state.showPlaylist = payload;
  },
  SET_CURRENT_EPISODE_VIDEOS(state: VideoPlayerCatalogState, payload: VideoSource[]) {
    state.currentEpisodeVideos = payload;
  },
  SET_SELECTED_VIDEO(state: VideoPlayerCatalogState, payload: VideoSource | undefined) {
    state.selectedVideo = payload;
  },
  SET_CONTINUE_WATCHING(state: VideoPlayerCatalogState, payload: ContinueWatching) {
    state.continueWatching = payload;
  },
  SET_NEXT_EPISODE(state: VideoPlayerCatalogState, payload: TitleEpisode) {
    state.nextEpisode = payload;
  },
  SET_NEXT_EPISODE_VIDEOS(state: VideoPlayerCatalogState, payload: VideoSource[]) {
    state.nextEpisodeVideos = payload;
  },
  SET_NEXT_EPISODE_EXPERIENCE_MATCHES(state: VideoPlayerCatalogState, payload: boolean) {
    state.nextEpisodeExperienceMatches = payload;
  },
  SET_NEXT_EPISODE_SVOD_PAYWALL_ERROR(state: VideoPlayerCatalogState, payload: boolean) {
    state.nextEpisodeSVODPaywallError = payload;
  },
  SET_NEXT_EPISODE_EST_ONLY_PAYWALL_ERROR(state: VideoPlayerCatalogState, payload: boolean) {
    state.nextEpisodeESTOnlyPaywallError = payload;
  },
  SET_MATCHING_NEXT_EPISODE_EXPERIENCE_IS_SVOD_LOCKED(state: VideoPlayerCatalogState, payload: boolean) {
    state.matchingNextEpisodeExperienceIsSVODLocked = payload;
  },
  SET_MATCHING_NEXT_EPISODE_EXPERIENCE_IS_EST_ONLY_LOCKED(state: VideoPlayerCatalogState, payload: boolean) {
    state.matchingNextEpisodeExperienceIsESTOnlyLocked = payload;
  },
  SET_ALT_NEXT_EPISODE_EXPERIENCE(state: VideoPlayerCatalogState, payload: VideoSource) {
    state.altNextEpisodeExperience = payload;
  },
};
