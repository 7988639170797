





























































































































import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'episode-card-grid',
  data() {
    return {
      selectedExtrasFilter: '',
      deltaSelectedSeasonId: '',
      showId: null as number | null,
      episodeLoading: true,
      episodeCardGridContent: this.$t('episodeCardGridContent'),
      languages: [] as any[],
      versions: [] as any[],
    };
  },
  props: {
    isExtras: {
      type: Boolean,
    },
  },
  mounted() {
    this.fetchShow();
  },
  watch: {
    show() {
      if (!this.show || this.show.seasons.length === 0) {
        this.deltaSelectedSeasonId = '';
        return;
      }
      let seasonData = window.sessionStorage.getItem(`selectedSeason-${this.show.id}`);
      if (!this.show.availableSeasonIds.includes(seasonData)) {
        seasonData = null;
        window.sessionStorage.removeItem(`selectedSeason-${this.show.id}`);
      }
      const selectedSeasonId = seasonData ? seasonData : this.seasonSelection[0]?.value;
      this.selectedSeasonId = (this.isExtras || this.seasonSelection.length === 0)
        ? this.show.seasons[this.show.seasons.length - 1].id
        : selectedSeasonId;
    },
  },
  computed: {
    ...mapState({
      route: (state: any) => state.route,
      show: (state: any) => state.showDetails.show,
      seasons: (state: any) => state.showDetails.seasons,
      userData: (state: any) => state.userInfo.userData,
      filterSort: (state: any) => state.filterSort,
      userLanguage: (state: any) => state.userInfo.userLanguage,
    }),
    ...mapGetters([
      'filter',
      'sort',
    ]),
    region(): string {
      return window.region;
    },
    context(): any {
      return {
        content: this.seasons[this.selectedSeasonId],
      };
    },
    extrasFilter(): any {
      return (episode: any) => {
        if (!this.isExtras) {
          return true;
        }
        if (this.selectedExtrasFilter === 'all') {
          return true;
        }
        return episode.type === this.selectedExtrasFilter;
      };
    },
    episodes(): any[] | undefined {
      this.filterSort.stateVersion;
      // NOT loaded yet, return undefined to prevent rendering
      if (!this.seasons || !this.seasons[this.selectedSeasonId]) {
        return undefined;
      }
      // filter based on user selections
      return this.seasons[this.selectedSeasonId]
        .filter(this.filter)
        .filter(this.extrasFilter)
        .sort(this.sort);
    },
    selectedSeasonId: {
      get(): string {
        return this.deltaSelectedSeasonId;
      },
      async set(val: string): Promise<void> {
        if (val === '') {
          return;
        }
        if (!this.isExtras) {
          window.sessionStorage.setItem(`selectedSeason-${this.show.id}`, val.toString());
        }
        this.deltaSelectedSeasonId = val;
        this.episodeLoading = true;
        await this.fetchEpisodesBySeason({
          id: val,
          region: this.region,
          deviceType: 'web',
          locale: this.userLanguage,
        });
        this.selectedExtrasFilter = 'all';
        this.episodeLoading = false;
      },
    },
    extrasSelection(): any[] {
      if (!this.seasons || !this.seasons[this.selectedSeasonId]) {
        return [];
      }
      const sel = [ 'all' ];
      this.seasons[this.selectedSeasonId].forEach((e: any) => {
        if (sel.indexOf(e.type) === -1) {
          sel.push(e.type);
        }
      });
      return sel.map((i: string) => {
        return {
          text: this.extrasNameMap(i),
          value: i,
        };
      });
    },
    seasonSelection(): any[] {
      if (!this.show) {
        return [];
      }
      return this.show.seasons
        .filter((season: any) => {
          return !/extras/i.test(season.type);
        })
        .sort((a: any, b: any) => {
          const n = (e: any): number =>
            e.order;
          return n(a) > n(b) ? 1 : -1;
        })
        .map((season: any) => {
          const language = this.userLanguage || 'en';
          return {
            text: season.name[language],
            value: season.id,
          };
        });

    },
  },
  methods: {
    ...mapActions([
      'fetchEpisodesBySeason',
      'fetchShowDetailsBySlug',
    ]),
    extrasNameMap(i: string) {
      return (
        ({
          'trailer': this.$t('episodeCardGridContent.trailer'),
          'extra feature': this.$t('episodeCardGridContent.extraFeature'),
          'commentary': this.$t('episodeCardGridContent.commentary'),
          'clip': this.$t('episodeCardGridContent.clip'),
          'all': this.$t('episodeCardGridContent.allVideoExtras'),
          'feature': this.$t('episodeCardGridContent.extraFeature'),
        } as any)[i] || this.$t('episodeCardGridContent.feature')
      );
    },
    async fetchShow(): Promise<void> {
      if (!this.region) {
        // mounted call might NOT yet have region
        // wait for region to change in the watch
        return;
      }
      const slug = this.route.params.slug || window.defaultShow;
      await this.fetchShowDetailsBySlug({
        slug: slug,
        region: this.region,
        deviceType: 'web',
        locale: this.userLanguage,
      });
    },
  },
});
