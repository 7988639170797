<template>
<v-row>
  <v-col>
    <v-expansion-panels light>
      <v-expansion-panel flat>
        <v-expansion-panel-header
          class="headline primary--text text-uppercase font-weight-bold"
          disable-icon-rotate
        >
          Bottom Sheet
          <template v-slot:actions>
            <v-icon class="primary--text" x-large>mdi-code-tags</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card color="transparent" flat>
            <v-card-text>
              <div v-highlight >
                <pre class="example-code-block language-Markup"><code>{{BottomSheetLayout}}</code></pre>
              </div>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
  <bottom-sheet />
</v-row>
</template>

<script>
import Vue from 'vue';
import 'vue-code-highlight/themes/duotone-sea.css';
import 'vue-code-highlight/themes/window.css';
import VueCodeHighlight from 'vue-code-highlight';
Vue.use(VueCodeHighlight);

export default {
  data() {
    return {
      BottomSheetLayout: `
<v-bottom-sheet
  class="ma-0 pa-0 pa-md-3"
  color="secondary"
  v-model="sheet"
  persistent
  hide-overlay
  dense
>
  <v-card color="secondary" tile>
    <v-container fluid>
      <v-row
        align="center"
        dense
      >
        <v-col class="shrink text-center">
          <v-btn
            class="mr-lg-16 mr-sm-2 mr-0 ml-sm-n2 ml-md-0"
            depressed
            icon
            @click="sheet = !sheet"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col class="align-center justify-sm-center grow ">
          <p
            v-if="$vuetify.breakpoint.smAndDown"
            class="white--text ma-0 mr-0 mr-md-3 body-1 text-right"
            style="font-size: 14px!important;"
          >
          Watch ad-free <br />with Premium Plus
          </p>
          <p
            v-else
            class=" white--text ma-0 subtitle-1 text-right mr-2"
          >
          Watch all 88 episodes plus ad-free with Premium Plus
            <span class="d-block text-uppercase font-weight-bold">
              My Hero Academia
            </span>
          </p>
        </v-col>

        <v-col class="shrink text-center">
          <v-btn
            class="px-5"
            color="accent"
            depressed
            style="color: #222222!important"
            :small="$vuetify.breakpoint.smAndDown"
          >
            Start Free Trial
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</v-bottom-sheet>
        `,
    };
  },
};
</script>

<style lang="scss" scoped>
pre.example-code-block {
  width: 100%;
}
</style>
