











































































import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'video-player-modal',
  computed: {
    ...mapState([ 'videoPlayerModal' ]),
  },
  methods: {
    onBackClick() {
      window.history.back();
    },
    buttonClick() {
      if (this.videoPlayerModal.buttonUrl) {
        if (this.videoPlayerModal.buttonUrl.substring(0, 4) == 'http') {
          window.open(this.videoPlayerModal.buttonUrl, '_blank');
        } else {
          location.href = this.videoPlayerModal.buttonUrl;
        }
      }
    },
    closeModal() {
      this.$store.dispatch('videoPlayerModal/setShow', false);
    },
    reloadPage() {
      location.reload();
    },
  },
});
