import { ActionTree } from 'vuex';
import { playerSeek } from '../utils';
import { getVideoCues } from '../api';
import {
  PluginOptions,
  RootState,
  State
} from '../types';


export function createActions(options: PluginOptions): ActionTree<State, RootState> {
  return {
    async getVideoCues(context, videoId: number): Promise<void> {
      const videoCueData = await getVideoCues(options.videoCuesService, videoId);
      if (!videoCueData) {
        return;
      }
      if (
        !Array.isArray(videoCueData.eventMarkers) ||
        videoCueData.eventMarkers.length < 1
      ) {
        console.error(`VIDEO CUES MALFORMED ERROR: Video cues are malformed for video with id: ${videoId}`);
        return;
      }

      context.commit('SET_VIDEO_CUES', videoCueData.eventMarkers);
    },
    jumpToIntroEnd(context): void {
      const timeDelta = context.getters.introEndTimeDelta;
      context.dispatch('videoPlayerAnalytics/trackEvent', {
        eventAction: 'user skipped',
        eventLabel: 'Skip Opening',
      }, { root: true });
      playerSeek(context, timeDelta);
    },
    jumpToCreditsEnd(context): void {
      const timeDelta = context.getters.creditsEndTimeDelta;
      context.dispatch('videoPlayerAnalytics/trackEvent', {
        eventAction: 'user skipped',
        eventLabel: 'Skip Ending',
      }, { root: true });
      playerSeek(context, timeDelta);
    },
  };
}
