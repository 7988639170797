














import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'video-player-up-next-panel',
  computed: {
    ...mapGetters({
      nextEpisodeNumber: 'videoPlayerCatalog/nextEpisodeNumber',
      nextEpisodeKeyPosterArt: 'videoPlayerCatalog/nextEpisodeKeyPosterArt',
    }),
  },
});
