
























































































































import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
export default Vue.extend({
  computed: {
    ...mapState({
      error: (state: any) => state.error,
      ribbon: (state: any) => (state.ribbon ? state.ribbon.item : {}),
    }),
    appError: {
      get() {
        return this.error;
      },
      set(val: any) {
        if (!val) {
          this.dismissError();
        }
      },
    },
    isGlobalHeader(): boolean {
      return /global-header/.test(this.$store.state.route.path);
    },
  },
  mounted() {
    this.fetchRibbon();
  },
  methods: {
    ...mapActions({
      dismissError: 'dismissError',
      fetchRibbon: 'fetchRibbon',
    }),
  },
  watch: {
    '$store.state.userInfo.userDataReady'(newValue: boolean) {
      if (!newValue) {
        return;
      }

      this.fetchRibbon();
    },
  },
  data() {
    return {
      clipped: false,
      drawer: false,
      items: [
        {
          title: 'Welcome',
          to: '/',
        },
        {
          title: 'Bottom Sheet',
          to: '/bottom-sheet',
        },
        {
          title: 'Brand Colors',
          to: '/brand-colors',
        },
        {
          title: 'Buttons',
          to: '/buttons',
        },
        {
          title: 'Content Carousel',
          to: '/content-carousel',
        },
        {
          title: 'Content FAQ',
          to: '/content-faq',
        },
        {
          title: 'Content Hero',
          to: '/content-hero',
        },
        {
          title: 'Episode Cards',
          to: '/episode-cards',
        },
        {
          title: 'Filter Sort',
          to: '/filter-sort',
        },
        {
          title: 'Global Footer',
          to: '/global-footer',
        },
        {
          title: 'Global Header',
          to: '/global-header',
        },
        {
          title: 'Tab Layout',
          to: '/tab-layout',
        },
        {
          title: 'Video Player Core',
          to: '/video-player-core',
        },
        {
          title: 'Video Player',
          to: '/video-player/assassination-classroom/assassination-time',
        },
        {
          title: 'Video Player End Screen',
          to: '/video-player-end-screen/my-hero-academia/izuku-midoriya-origin/',
        },
        {
          title: 'Video Player Meta Overlay',
          to: '/video-player-meta-overlay/my-hero-academia/izuku-midoriya-origin/',
        },
        {
          title: 'Video Player Modal',
          to: '/video-player-modal/my-hero-academia/izuku-midoriya-origin',
        },
        {
          title: 'Video Player Catalog',
          to: '/video-player-catalog/my-hero-academia/izuku-midoriya-origin',
        },
        {
          title: 'Video Player Controls',
          to: '/video-player-controls/my-hero-academia/izuku-midoriya-origin/',
        },
        {
          title: 'Video Player Co-Watching',
          to: '/video-player-co-watching',
        },
        {
          title: 'Google Auth',
          to: '/google-auth',
        },
        {
          title: 'Watch History',
          to: '/watch-history',
        },
        {
          title: 'Remote Config',
          to: '/remote-config',
        },
        {
          title: 'Page Not Found',
          to: '*',
        },
        {
          title: 'Out Of Territory',
          to: '/out-of-territory',
        },
        {
          title: 'Pin Login',
          to: '/pin-login',
        },
      ],
    };
  },
});
