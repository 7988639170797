














import Vue from 'vue';
export default Vue.extend({
  name: 'dynamic-image',
  props: {
    dynamicWidth: Number,
    dynamicHeight: Number,
    dynamicQuality: Number,
    src: String,
  },
  computed: {
    dynamicUrl() {
      if (!this.src) {
        return '';
      }
      if ( !this.dynamicWidth && !this.dynamicHeight && !this.dynamicQuality) {
        return this.src;
      }
      let resizeString = '';
      resizeString = this.dynamicWidth? `w_${this.dynamicWidth},` : '';
      resizeString += this.dynamicHeight? `h_${this.dynamicHeight},` : '';
      resizeString += this.dynamicQuality? `q_${this.dynamicQuality},` : '';
      resizeString += 'c_fill';
      return this.src.replace('upload/', `upload/${resizeString}/`);
    },
  },
});
