
import * as utils from './utils';

const setupStore = (context: any) => {
  const store = {
    namespaced: true,
    state: {
      hitQuartile0: false,
      hitQuartile1: false,
      hitQuartile2: false,
      hitQuartile3: false,
      hitQuartile4: false,
      hitPlaybackStart: false,
      hitPlaybackComplete: false,
    },
    actions: {
      trackEvent(_: any, payload: any) {
        utils.trackEvent(context, payload.eventAction, payload.eventLabel);
      }
    },
    mutations: {
      HIT_QUARTILE_0(state: any, payload: boolean) {
        state.hitQuartile0 = payload;
      },

      HIT_QUARTILE_1(state: any, payload: boolean) {
        state.hitQuartile1 = payload;
      },

      HIT_QUARTILE_2(state: any, payload: boolean) {
        state.hitQuartile2 = payload;
      },

      HIT_QUARTILE_3(state: any, payload: boolean) {
        state.hitQuartile3 = payload;
      },

      HIT_QUARTILE_4(state: any, payload: boolean) {
        state.hitQuartile4 = payload;
      },

      HIT_PLAYBACK_START(state: any, payload: boolean) {
        state.hitPlaybackStart = payload;
      },

      HIT_PLAYBACK_COMPLETE(state: any, payload: boolean) {
        state.hitPlaybackComplete = payload;
      },
    },
    getters: {
      audioPreference: (state: any, getters: any, rootState: any) => rootState.userInfo?.userData?.user?.profileData?.audioPreference,
      autoPlay: (state: any, getters: any, rootState: any) => rootState.userInfo?.userData?.user?.profileData?.autoPlay,
      episodeNumber: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['videoPlayerCatalog/currentEpisodeNumber'],
      episodeLanguage: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['videoPlayerCatalog/selectedSpokenLanguageTranslation'],
      showRating: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['videoPlayerCatalog/currentEpisodeRatingCode'],
      episodeSeason: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['videoPlayerCatalog/currentSeasonNumber'],
      episodeSubtitleLanguage: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['selectedSubtitleLanguage'],
      episodeTitle: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['videoPlayerCatalog/currentEpisodeName'],
      isMatureRestricted: (state: any, getters: any, rootState: any) => rootState.userInfo?.userData?.user?.profileData?.restrictMatureContent,
      showID: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['videoPlayerCatalog/currentShowId'],
      showName: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['videoPlayerCatalog/currentShowName'],
      videoID: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['videoPlayerCatalog/selectedVideoId'],
      videoType: (state: any, getters: any, rootState: any, rootGetters: any) => rootGetters['videoPlayerCatalog/currentEpisodeType'],
      avodSvod(state: any, getters: any, rootState: any, rootGetters: any) {
        return rootGetters['videoPlayerCatalog/selectedVideoAccessType'];
      },
      showAndEpisodeName(state: any, getters: any) {
        const showName = getters.showName;
        const episodeTitle = getters.episodeTitle;
        if (!showName || !episodeTitle) {
          return '';
        }

        return `${showName}: ${episodeTitle}`;
      },
      playbackPercentage(state: any, getters: any, rootState: any): number {
        const timeCurrent = rootState.videoPlayerCore?.timeCurrent;
        const timeTotal = rootState.videoPlayerCore?.timeTotal;
        const percentage = timeCurrent / timeTotal * 100;

        return Number.isNaN(percentage) ? 0 : percentage;
      },
      customerAge(state: any, getters: any, rootState: any): number | undefined {
        const birthYear = rootState.userInfo?.userData?.user?.profileData?.birthYear;

        return birthYear ? new Date().getFullYear() - birthYear : undefined;
      },
      customerGender(state: any, getters: any, rootState: any): string | undefined {
        const customerGender = rootState.userInfo?.userData?.user?.profileData?.customerGender;
        const gender = rootState.userInfo?.userData?.user?.profileData?.gender;

        return customerGender ? 'customerGender' : gender;
      },
      customerType(state: any, getters: any, rootState: any): string {
        return rootState.userInfo?.userData ? 'Repeat' : 'New';
      },
      dateAdded(state: any, getters: any, rootState: any, rootGetters: any): string | undefined {
        const releaseDate = rootGetters['videoPlayerCatalog/currentEpisodeReleaseDate'];
        if (!releaseDate) {
          return;
        }

        const date = new Date(releaseDate);
        if (Number.isNaN(date.getTime())) {
          return;
        }

        return date.toISOString();
      },
      enableClosedCaption: (state: any, getters: any, rootState: any) => rootState.userInfo?.userData?.user?.profileData?.enableClosedCaption,
      enableSubtitles: (state: any, getters: any, rootState: any) => rootState.userInfo?.userData?.user?.profileData?.enableSubtitles,
      freeTrial(state: any, getters: any, rootState: any): string | undefined {
        return rootState.userInfo?.userData?.subscription?.freeTrial ? 'true' : undefined;
      },
      planName(state: any, getters: any, rootState: any): string | undefined {
        const plan = rootState.userInfo?.userData?.subscription?.plan;
        const frequency = rootState.userInfo?.userData?.subscription?.subscriptionFrequency;
        if (!plan || !frequency) {
          return;
        }
        return `${plan} - ${frequency === 'month' ? 'Monthly' : 'Yearly'}`;
      },
      qualityPreference: (state: any, getters: any, rootState: any) => rootState.userInfo?.userData?.user?.profileData?.qualityPreference,
      territory: (state: any, getters: any, rootState: any) => rootState.userInfo?.region,
      userAccessLevel: (state: any, getters: any, rootState: any) => rootState.userInfo?.userData?.subscription?.plan,
      userCountry: (state: any, getters: any, rootState: any) => rootState.userInfo?.userData?.subscription?.subscriptionRegion,
      userID: (state: any, getters: any, rootState: any) => rootState.userInfo?.userData?.user?.id,
    },
  };

  context.registerModule('videoPlayerAnalytics', store);

  utils.setAnalyticsListeners(context);

  context.watch(
    function getSelectedSubtitleLanguage() {
      return context.state.videoPlayerAnalytics?.hitPlaybackStart && context.getters.selectedSubtitleLanguage;
    },
    function selectedSubtitleLanguageChange(newValue: string) {
      if (!newValue) {
        return;
      }

      utils.trackEvent(context, `Subtitle ${newValue} Selected`);
    }
  );

  context.watch(
    function getSelectedSpokenLanguage() {
      return context.state.videoPlayerAnalytics?.hitPlaybackStart && context.getters['videoPlayerCatalog/selectedSpokenLanguageTranslation'];
    },
    function selectedSpokenLanguageChange(newValue: string) {
      if (!newValue) {
        return;
      }

      utils.trackEvent(context, `Language ${newValue} Selected`);
    }
  );
  context.watch(
    function getAutoMarathon() {
      return context.state.videoPlayerAnalytics?.hitPlaybackStart && context.getters.autoMarathon;
    },
    function autoMarathonChange(newValue: boolean) {
      utils.trackEvent(context, `Auto Play ${newValue ? 'On' : 'Off'}`);
    }
  );
};

export default (context: any) => {
  setupStore(context);
  return {
    install() { }, // eslint-disable-line
  };
};
