import axios from 'axios';
import { urls } from './urls';


export async function getVideoCues(
  videoCuesServiceDomain: string,
  videoId: number
): Promise<any | undefined> {
  let result;
  try {
    result = await axios.get(
      `${videoCuesServiceDomain}${urls.getCues(videoId)}`
    );
  } catch (err) {
    console.warn(`Error getting video cues for video with id: ${videoId}: ${err}`);
    return;
  }

  return result?.data;
}
