



































































import Vue from 'vue';
import { compareStrings } from '@funimation/comp-utils';


export default Vue.extend({
  name: 'not-found-links',
  data() {
    return {
      pageNotFoundLinks: this.$t('pageNotFoundLinks'),
    };
  },
  computed: {
    isCanada(): boolean {
      return compareStrings(window.region, 'ca');
    },
    isUs(): boolean {
      return compareStrings(window.region, 'us');
    },
  },
});
