import WSSDataProvider from './WssDataProvider';
import Vue from 'vue';
export function newId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  });
}
export default class PublicAnnouncementClient {
  constructor(config: any, store: any) {
    this.config = config;
    this.wss = new WSSDataProvider(config, this.wsMessage, this.wsOpen, this.wsClose);
    if (store) {
      store.registerModule('publicAnnouncementClient', this.store());
    }
  }
  wsMessage(message: any): void {} // eslint-disable-line
  wsOpen(e: WSSDataProvider): void {
    if (!e.store) {
      return;
    }
    e.store.commit('SET_STATUS', true);
  }
  wsClose(e: WSSDataProvider): void {
    if (!e.store) {
      return;
    }
    e.store.commit('SET_STATUS', false);
  }
  private store(): any {
    const publicAnnouncementClient = this; // eslint-disable-line
    return {
      getters: {
        saving(state: any) {
          return state.pendingEvents.length !== 0;
        },
        orderedComponents(state: any) {
          const comps = JSON.parse(JSON.stringify(state.local)).components;
          if (!comps) {
            return [];
          }
          return Object.keys(comps).sort((a: string, b: string) => {
            return comps[a].order - comps[b].order;
          }).map((key: string) => {
            return comps[key];
          });
        },
        document(state: any) {
          return JSON.parse(JSON.stringify(state.local));
        },
      },
      state: {
        componentSummaries: null,
        components: {},
        remote: {},
        local: {},
        pendingEvents: [],
        sentEvents: [],
        isOpen: false,
        subscriptions: {},
        subscribers: {},
        toc: {},
        message: null,
        messages: [],
        events: [],
        createdDocumentId: null,
      },
      actions: {
        async connect(context: any): Promise<void> {
          return new Promise((success) => {
            publicAnnouncementClient.wss.connect(async () => {
              publicAnnouncementClient.store = context;
              context.commit('SET_STATUS', true);
              await context.dispatch('subscribe', {
                channelId: 'toc-changes',
                callback: (e: any) => {
                  context.commit('UPDATE_TOC', e);
                },
              });
              success();
            });
          });
        },
        disconnect(): void {
          publicAnnouncementClient.wss.disconnect();
        },
        async subscribe(context: any, payload: any): Promise<void> {
          await publicAnnouncementClient.wss.subscribe(payload.channelId, payload.callback);
        },
        unsubscribe(context: any, payload: any): void {
          publicAnnouncementClient.wss.unsubscribe(payload.channelId, payload.callback);
        },
        sendToAll(context: any, payload: any): void {
          publicAnnouncementClient.wss.sendToAll(payload);
        },
        sendToChannel(context: any, payload: any): void {
          publicAnnouncementClient.wss.sendToChannel(payload.channelId, {
            channelId: payload.channelId,
            value: {
              channelId: payload.channelId,
              response: payload.value,
            },
          });
        },
        sendToConnection(context: any, payload: any): void {
          publicAnnouncementClient.wss.sendToConnection(payload.connectionId, payload);
        },
      },
      mutations: {
        UPDATE_TOC(state: any, payload: any) {
          Vue.set(state.toc, payload.sk, {
            epochMils: new Date().getTime(),
            id: payload.state.id,
            userId: payload.state.userId || 'Unknown',
            version: payload.state.version,
          });
        },
        SET_STATUS(state: any, payload: any) {
          state.isOpen = payload;
        },
      },
    };
  }
  /** Vue plugin installer */
  install(): void {} // eslint-disable-line
  /** Configuration */
  config: any;
  wss: WSSDataProvider;
}
