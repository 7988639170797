/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  PlayerSpokenLanguage,
  PlaylistEpisode,
  PlayerVersion,
  VideoSource
} from '../types';
import { compareStrings } from '@funimation/comp-utils';


const ASIAN_LANGUAGES = [
  'ja',
  'ko',
  'zh',
  'zh_MN',
  'zh-MN',
  'zh_CT',
  'zh_TW',
  'zh_CN',
  'cn'
];


export function getNextEpisodeId(context: Record<string, any>): string | undefined {
  const currentEpisodeId = context.getters.currentEpisodeId;
  if (!currentEpisodeId) {
    console.error('getNextEpisode: current episode id is missing');
    return;
  }
      
  const playlist: PlaylistEpisode[] = context.state.showPlaylist;
  if (!Array.isArray(playlist)) {
    console.error('getNextEpisode: show playlist is malformed');
    return;
  }
  if (playlist.length < 1) {
    console.error('getNextEpisode: show playlist is empty');
    return;
  }

  let nextEpisodeId;

  const nextEpisodeIndex = playlist.findIndex(episode => compareStrings(currentEpisodeId, episode.contentId));
  if (nextEpisodeIndex !== -1) {
    nextEpisodeId = playlist[nextEpisodeIndex+1]?.contentId;
  } else {
    console.error('getNextEpisode: episode not found in show playlist');
  }

  return nextEpisodeId;
}

export function calculateWhetherEpisodeExperienceMatches(
  videoList: VideoSource[],
  episodeSpokenLanguages: PlayerSpokenLanguage[],
  episodeVersions: PlayerVersion[],
  selectedSpokenLanguage: string,
  selectedVersion: string
): Record<string, any> {
  const response: {
    hasMatchingExperience: boolean;
    matchingNextEpisodeExperienceIsSVODLocked: boolean;
    matchingNextEpisodeExperienceIsESTOnlyLocked: boolean;
    alternateExperience: undefined | VideoSource;
  } = {
    hasMatchingExperience: false,
    matchingNextEpisodeExperienceIsSVODLocked: false,
    matchingNextEpisodeExperienceIsESTOnlyLocked: false,
    alternateExperience: undefined
  };

  // find matching experience
  const matchingExperienceVideo = getMatchingExperienceVideo(videoList, selectedSpokenLanguage, selectedVersion);
  if (matchingExperienceVideo) {
    response.hasMatchingExperience = true;
    return response;
  }
  
  // determine if the matching experience is locked.
  const foundSpokenLanguage = episodeSpokenLanguages.find(spokenLanguage => compareStrings(spokenLanguage.languageCode, selectedSpokenLanguage));
  const foundVersion = episodeVersions.find(version => compareStrings(version.version, selectedVersion));
  if (
    foundSpokenLanguage?.isLocked ||
    foundVersion?.isLocked
  ) {
    if (
      compareStrings(foundSpokenLanguage?.lockedReason as string, 'SVOD') ||
      compareStrings(foundVersion?.lockedReason as string, 'SVOD')
    ) {
      response.matchingNextEpisodeExperienceIsSVODLocked = true;
    } else if (
      compareStrings(foundSpokenLanguage?.lockedReason as string, 'EST') ||
      compareStrings(foundVersion?.lockedReason as string, 'EST')
    ) {
      response.matchingNextEpisodeExperienceIsESTOnlyLocked = true;
    }
  }

  // find alternate matching experience
  const alternateExperienceVideo = videoList.find((video: VideoSource) => {
    const spokenLanguageMatch = compareStrings(video.audioLanguage, selectedSpokenLanguage);

    return spokenLanguageMatch;
  });
  if (alternateExperienceVideo) {
    response.alternateExperience = alternateExperienceVideo;
  } else {
    response.alternateExperience = videoList[0];
  }

  return response;
}

export function getSelectedVideoForUser(
  videos: VideoSource[],
  selectedSpokenLanguage: string,
  setSelectedSpokenLanguage: (newSpokenLanguage: string) => void,
  selectedVersion: string,
  setSelectedVersion: (newVersion: string) => void,
  dispatchCorruptedVideoError: () => void
): VideoSource | undefined {
  if (videos.length < 1) {
    return;
  }

  let strictMatchFound = true;

  // use matching experience
  let result: VideoSource | undefined = getMatchingExperienceVideo(videos, selectedSpokenLanguage, selectedVersion);

  // if no matching experience, use uncut experience with matching spoken language
  if (!result) {
    strictMatchFound = false;
    result = getMatchingSpokenLanguageUncutVideo(videos, selectedSpokenLanguage);
  }

  // if no matching spoken language cut experience, use matching cut, spoken language experience
  if (!result) {
    result = getMatchingSpokenLanguageNotUnCutVideo(videos, selectedSpokenLanguage);
  }

  // if no matching cut spoken language experience found, use first uncut asian spoken language experience.
  const asianLanguageVideos = getAsianLanguageVideos(videos);
  if (!result && asianLanguageVideos.length > 0) {
    result = getFirstMatchingUncutVideo(asianLanguageVideos);

    // if no matching uncut asian spoken language experience, use matching not uncut asian spoken language experience
    if (!result) {
      result = getFirstMatchingNotUnCutVideo(asianLanguageVideos);
    }
  }

  // if no asian experiences, use first alternate uncut experience
  if (!result) {
    result = getFirstMatchingUncutVideo(videos);

    // if no alternate uncut experience, use first not uncut experience
    if (!result) {
      result = getFirstMatchingNotUnCutVideo(videos);
    }
  }

  if (result?.corruptVideo) {
    dispatchCorruptedVideoError();
  }

  // if no strict match found, set users spoken language and type to matching video's spoken language and type
  if (!strictMatchFound && result) {
    setSelectedSpokenLanguage(result.audioLanguage);
    setSelectedVersion(result.version);
  }

  return result;
}

function getMatchingExperienceVideo(
  videos: VideoSource[],
  selectedSpokenLanguage: string,
  selectedVersion: string
): VideoSource | undefined {
  const filterFunction = (video: VideoSource) => {
    const spokenLanguageMatch = compareStrings(video.audioLanguage, selectedSpokenLanguage);
    const episodeVersionMatch = compareStrings(video.version, selectedVersion);

    return spokenLanguageMatch && episodeVersionMatch;
  };
  const notCorruptedVideos = videos.filter(video => !video.corruptVideo);

  return notCorruptedVideos.find(filterFunction) ?? videos.find(filterFunction);
}

function getMatchingSpokenLanguageUncutVideo(videos: VideoSource[], selectedSpokenLanguage: string): VideoSource | undefined {
  const filterFunction = (video: VideoSource) => {
    const spokenLanguageMatch = compareStrings(video.audioLanguage, selectedSpokenLanguage);
    const episodeVersionMatch = compareStrings(video.version, 'uncut');
    

    return spokenLanguageMatch && episodeVersionMatch;
  };
  const notCorruptedVideos = videos.filter(video => !video.corruptVideo);

  return notCorruptedVideos.find(filterFunction) ?? videos.find(filterFunction);
}

function getMatchingSpokenLanguageNotUnCutVideo(allowedVideos: VideoSource[], selectedSpokenLanguage: string): VideoSource | undefined {
  const filterFunction = (video: VideoSource) => {
    const spokenLanguageMatch = compareStrings(video.audioLanguage, selectedSpokenLanguage);
    const episodeVersionMatch = !compareStrings(video.version, 'uncut');

    return spokenLanguageMatch && episodeVersionMatch;
  };
  const notCorruptedVideos = allowedVideos.filter(video => !video.corruptVideo);

  return notCorruptedVideos.find(filterFunction) ?? allowedVideos.find(filterFunction);
}

function getAsianLanguageVideos(allowedVideos: VideoSource[]): VideoSource[] {
  return allowedVideos.filter((video: VideoSource) => {
    const asianLanguageMatch = ASIAN_LANGUAGES.some((languageCode: string) => compareStrings(video.audioLanguage, languageCode));

    return asianLanguageMatch;
  });
}

function getFirstMatchingUncutVideo(videos: VideoSource[]): VideoSource | undefined {
  const filterFunction = (video: VideoSource) => {
    const episodeVersionMatch = compareStrings(video.version, 'uncut');

    return episodeVersionMatch;
  };
  const notCorruptedVideos = videos.filter(video => !video.corruptVideo);

  return notCorruptedVideos.find(filterFunction) ?? videos.find(filterFunction);
}

function getFirstMatchingNotUnCutVideo(videos: VideoSource[]): VideoSource | undefined {
  const filterFunction = (video: VideoSource) => {
    const episodeVersionMatch = !compareStrings(video.version, 'uncut');

    return episodeVersionMatch;
  };
  const notCorruptedVideos = videos.filter(video => !video.corruptVideo);

  return notCorruptedVideos.find(filterFunction) ?? videos.find(filterFunction);
}

export function markCorruptVideos(allowedVideos: VideoSource[], episodeId = ''): VideoSource[] {
  const unCorruptedVideos: VideoSource[] = [];

  for (const possibleVideo of allowedVideos) {
    const newVideo = {...possibleVideo};
    let errorMessage = '';

    if (!possibleVideo.venueVideoId) {
      errorMessage = `${errorMessage}Video with id: ${possibleVideo.venueVideoId} has no venue video id property.\n`;
      newVideo.corruptVideo = true;
    }

    if (!possibleVideo.audioLanguage) {
      errorMessage = `${errorMessage}Video with id: ${possibleVideo.venueVideoId} has no audio language property.\n`;
      newVideo.corruptVideo = true;
    }

    if (!Array.isArray(possibleVideo.subtitles)) {
      errorMessage = `${errorMessage}Video with id: ${possibleVideo.venueVideoId} has no subtitles property.\n`;
      newVideo.subtitles = [];
      newVideo.corruptVideo = true;
    }

    if (!possibleVideo.version) {
      errorMessage = `${errorMessage}Video with id: ${possibleVideo.venueVideoId} has no version property.\n`;
      newVideo.corruptVideo = true;
    }

    if (errorMessage) {
      console.error(`CORRUPTED VIDEO ERROR for episode id ${episodeId}: ${errorMessage}: Video data:\n${JSON.stringify(possibleVideo)}`);
    }

    unCorruptedVideos.push(newVideo);
  }

  return unCorruptedVideos;
}
