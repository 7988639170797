













import Vue from 'vue';
export default Vue.extend({
  name: 'content-details-card',
  props: {
    headline: String,
    content: [ String, Number, Array ],
  },
});
