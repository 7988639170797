








































































import Vue from 'vue';
import {mapState, mapActions} from 'vuex';
export default Vue.extend({
  name: 'shows-poster-grid',
  methods: {
    ...mapActions([
      'nextSearchPage',
    ]),
    onIntersect(e: any, observer: any, visible: boolean) {
      if (e.length === 0) {
        return;
      }
      const el = e[0].target;
      if (visible && el) {
        this.nextSearchPage();
        // commit suicide so the observer is NOT hit twice
        el.parentNode.removeChild(el);
      }
    },
  },
  computed: {
    ...mapState({
      _items: (state: any) => state.searchResults.items,
      loading: (state: any) => state.searchResults.loading,
    }),
    items(): any {
      return this._items.filter((item: any) => {
        return !/product/i.test(item.type);
      });
    },
    showRating(): any {
      return window.region !== 'AU' && window.region !== 'NZ';
    },
  },
});
