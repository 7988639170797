import { PluginFunction } from 'vue';
import { Store } from 'vuex';

import VueComponent from './index.vue';
import vuexModule from './store';

type RootState = Record<string, any>;
type RootStoreInstance = Store<RootState>;

type PluginOptions = {
  store: RootStoreInstance;
  someConfigProp: string;
};

/**
 * Vue Plugin, which is called on Vue.use().
 *
 * @example
 * import myVuePlugin from '@funimation/comp-template-rename'
 * Vue.use(myVuePlugin, {
 *  someConfigProp: 'ANYTHING TRUTHY',
 *  store: myVuexStoreInstance,
 * });
 */
const vuePlugin: PluginFunction<PluginOptions> = function (Vue, pluginOptions): void {
  // Validate PluginOptions
  if (!pluginOptions?.store || !pluginOptions?.someConfigProp) {
    throw new Error('TemplateRename requires "pluginOptions.store" & "pluginOptions.someConfigProp".');
  }

  Vue.component('template-rename', VueComponent);

  pluginOptions.store.registerModule('templateRename', vuexModule);

  /* // Just an example of watching & reacting to state changes.
  pluginOptions.store.watch(
    function SomeWatchGetter(state, getters): string | undefined {
      return state.someNamespacedVuexModule?.someStateProp || getters['someNamespacedVuexModule/someGetter'];
    },
    function SomeWatchCallback(newValue: string | undefined): void {
      if (!newValue) {
        return;
      }
      pluginOptions.store.dispatch('someNamespacedVuexModule/someAction', newValue);
    }
  );
  */
};

export default vuePlugin;
