import { GetterTree } from 'vuex';
import { compareStrings } from '@funimation/comp-utils';
import {
  RootState,
  State,
  VideoCue
} from '../types';
import { buildCues } from '../utils';
  
  
const SHOW_SKIP_BUTTON_THRESHOLD = 7;
  
  
export const getters: GetterTree<State, RootState> = {
  startCues(state, getters, rootState, rootGetters): VideoCue[] | null {
    if (!Array.isArray(state.videoCues) || state.videoCues.length < 1) {
      return null;
    }
  
    return buildCues(
      'start',
      state.videoCues,
      rootGetters['videoPlayerCatalog/currentEpisodeDuration']
    );
  },
  endCues(state, getters, rootState, rootGetters): VideoCue[] | null {
    if (!Array.isArray(state.videoCues) || state.videoCues.length < 1) {
      return null;
    }
  
    return buildCues(
      'end',
      state.videoCues,
      rootGetters['videoPlayerCatalog/currentEpisodeDuration']
    );
  },
  introStartCue(state, getters): VideoCue | null {
    if (!Array.isArray(getters.startCues) || getters.startCues.length < 1) {
      return null;
    }
  
    return getters.startCues.find((cue: VideoCue) => compareStrings(cue.markerTypeName, 'introstart')) ?? null;
  },
  creditsStartCue(state, getters): VideoCue | null {
    if (!Array.isArray(getters.startCues) || getters.startCues.length < 1) {
      return null;
    }
  
    return getters.startCues.find((cue: VideoCue) => compareStrings(cue.markerTypeName, 'creditsstart')) ?? null;
  },
  showSkipIntro(state, getters, rootState): boolean {
    if (!getters.introStartCue) {
      return false;
    }
  
    const timeCurrent = rootState.videoPlayerCore.timeCurrent;
    const secondsAfterIntro = timeCurrent - getters.introStartCue.timecodeSeconds;
  
    return secondsAfterIntro >= 0 && secondsAfterIntro <= SHOW_SKIP_BUTTON_THRESHOLD;
  },
  showSkipCredits(state, getters, rootState): boolean {
    if (!getters.creditsStartCue) {
      return false;
    }
  
    const timeCurrent = rootState.videoPlayerCore.timeCurrent;
    const secondsAfterCredits = timeCurrent - getters.creditsStartCue.timecodeSeconds;
  
    return secondsAfterCredits >= 0 && secondsAfterCredits <= SHOW_SKIP_BUTTON_THRESHOLD;
  },
  introEndCue(state, getters): VideoCue | null {
    if (!Array.isArray(getters.endCues) || getters.endCues.length < 1) {
      return null;
    }
  
    return getters.endCues.find((cue: VideoCue) => compareStrings(cue.markerTypeName, 'introend')) ?? null;
  },
  creditsEndCue(state, getters): VideoCue | null {
    if (!Array.isArray(getters.endCues) || getters.endCues.length < 1) {
      return null;
    }
  
    return getters.endCues.find((cue: VideoCue) => compareStrings(cue.markerTypeName, 'creditsend')) ?? null;
  },
  introEndTimeDelta(state, getters, rootState): number | null {
    if (!getters.introEndCue) {
      return null;
    }
  
    const timeCurrent = rootState.videoPlayerCore.timeCurrent;
  
    return getters.introEndCue.timecodeSeconds - timeCurrent;
  },
  creditsEndTimeDelta(state, getters, rootState): number | null {
    if (!getters.creditsEndCue) {
      return null;
    }
  
    const timeCurrent = rootState.videoPlayerCore.timeCurrent;
  
    return getters.creditsEndCue.timecodeSeconds - timeCurrent;
  },
};