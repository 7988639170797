










































































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
export default Vue.extend({
  name: 'video-player-controls',
  data() {
    return {
      volumeHover: false,
    };
  },
  computed: {
    ...mapState({
      isMuted: (state: any) => state.videoPlayerCore.isMuted || state.videoPlayerCore.volumeLevel === 0,
      isFullScreen: (state: any) => state.videoPlayerCore.isFullScreen,
      timeCurrent: (state: any) => state.videoPlayerCore.timeCurrent,
      timeTotal: (state: any) => state.videoPlayerCore.timeTotal,
      volumeLevelDecimal: (state: any) => state.videoPlayerCore.volumeLevel,
      selectedVideoAdMarkers: (state: any) => state.videoPlayerCatalog.aips,
    }),
    ...mapGetters({
      nextEpisodeSlug: 'videoPlayerCatalog/nextEpisodeSlug',
      currentShowSlug: 'videoPlayerCatalog/currentShowSlug',
      selectedVideoAccessType: 'videoPlayerCatalog/selectedVideoAccessType',
      startCues: 'videoPlayerSkipCues/startCues',
      showSkipIntro: 'videoPlayerSkipCues/showSkipIntro',
      showSkipCredits: 'videoPlayerSkipCues/showSkipCredits',
      getValue: 'getValue',
      isUserPremium: 'isUserPremium',
    }),
    enableCuePoints(): boolean {
      return this.isUserPremium;
    },
    startCuePoints(): Record<string, any>[] {
      return this.enableCuePoints ? this.startCues : [];
    },
    showSkipIntroButton(): boolean {
      return this.enableCuePoints && this.showSkipIntro;
    },
    showSkipCreditsButton(): boolean {
      return this.enableCuePoints && this.showSkipCredits;
    },
    adMarkers(): Record<string, any>[] {
      if (this.selectedVideoAccessType !== 'AVOD') {
        // Ads only required for AVOD
        return [];
      }
      return this. selectedVideoAdMarkers || [];
    },
    nextEpisodeHref(): string {
      const routerBase = this.$router.options.base; // "/v/"" or "/"
      const showSlug = this.currentShowSlug;
      const episodeSlug = this.nextEpisodeSlug;
      if (showSlug && episodeSlug) {
        return `${routerBase}${showSlug}/${episodeSlug}`;
      }
      return '';
    },
    volumeLevelModel: {
      get(): number {
        return this.volumeLevelDecimal * 100;
      },
      set(newValue: number) {
        this.setVolume(newValue / 100);
      },
    },
    isMobileAgent(): boolean {
      // FIXME: Rework navigator.userAgent usage
      // https://blog.chromium.org/2021/05/update-on-user-agent-string-reduction.html
      const mobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      return mobileBrowser;
    },
    duration(): string {
      const date = new Date(0);
      date.setSeconds(Math.ceil(this.timeTotal));
      if (this.timeTotal) {
        const timeString = date.toISOString().substr(11, 8).replace(/^00:|^0/, '');
        return timeString;
      }
      return '00:00';
    },
    currentTime(): string {
      const date = new Date(0);
      date.setSeconds(Math.ceil(this.timeCurrent));
      if (this.timeCurrent) {
        const timeString = date.toISOString().substr(11, 8).replace(/^00:|^0/, '');
        return timeString;
      }
      return '00:00';
    },
  },
  methods: {
    ...mapActions('videoPlayerCore', [
      'fullscreenToggle',
      'setVolume',
      'muteToggle',
    ]),
    ...mapActions('videoPlayerSkipCues', [
      'jumpToIntroEnd',
      'jumpToCreditsEnd',
    ]),
    isVolumeHovered(): boolean {
      return this.volumeHover = !this.volumeHover;
    },
    adMarkerPlacement(marker: number): string {
      return `left: ${(marker / Math.ceil(this.timeTotal) * 100)}%;`;
    },
  },
});
