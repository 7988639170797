<template>
  <v-row>
    <v-col>
      <h1 class="text-h1 text-uppercase font-weight-bold">Buttons</h1>
      <v-divider class="my-9" />
      <v-row
        v-for="size in sizes"
        :key="size.type"
      >
        <v-col cols="12">
          <v-expansion-panels class="mb-4" light flat>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="headline primary--text text-uppercase font-weight-bold"
                disable-icon-rotate
              >
                {{size.type}}
                <template v-slot:actions>
                  <v-icon class="primary--text" x-large>mdi-code-tags</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-tabs
                  v-model="tabs[size.type]"
                  background-color="transparent"
                  color="primary"
                  slider-size="5"
                  grow
                  show-arrows
                  :height="$vuetify.breakpoint.mdAndUp ? '72px' : '48px'"
                >
                  <v-tab
                    v-for="buttonType in buttonTypes"
                    :key="buttonType.type"
                    :color="buttonType.type.toLowerCase()"
                  >
                    {{ buttonType.type }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabs[size.type]">
                  <v-tab-item
                    v-for="buttonType in buttonTypes"
                    :key="buttonType.type"
                  >
                    <v-card
                      flat
                      light
                      tile
                    >
                      <v-card-text>
                        <div v-highlight >
                          <pre class="example-code-block language-html"><code>{{buildButtonSnippet(buttonType, size)}}</code></pre>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card
            class="mb-9"
            flat
            light
          >
            <v-card-actions class="flex-column flex-md-row align-start">
              <v-btn
                v-for="buttonType in buttonTypes"
                :key="buttonType.type"
                class="px-5 ml-0 ml-md-2 mb-2 mb-md-0"
                :style="buttonType.type === 'accent' ? `color: #222222!important` : null"
                :color="buttonType.type.toLowerCase()"
                depressed
                :small="size.type === 'small'"
                :large="size.type === 'large'"
              >
                {{buttonType.type}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Vue from 'vue';
import 'vue-code-highlight/themes/duotone-sea.css';
import 'vue-code-highlight/themes/window.css';
import VueCodeHighlight from 'vue-code-highlight';
Vue.use(VueCodeHighlight);

export default {
  name: 'Buttons',
  data() {
    return {
      tabs: {
        standard: null,
        small: null,
        large: null,
      },
      sizes: [
        {
          type: 'standard',
        },
        {
          type: 'small',
        },
        {
          type: 'large',
        },
      ],
      buttonTypes: [
        {
          type: 'primary',
        },
        {
          type: 'secondary',
        },
        {
          type: 'accent',
          style: '\n  style="color: #222222!important"',
        },
      ],
    };
  },
  methods: {
    buildButtonSnippet(buttonType, size) {
      return `<v-btn
  class="px-5"
  color="${buttonType.type.toLowerCase()}"
  depressed${buttonType.style || ''}${size.type !== 'standard' ? `\n  ${size.type}` : ''}
 >
  ${buttonType.type}
</v-btn>`;
    },
  },
};
</script>
<style lang="scss">
pre.example-code-block {
  width: 100%;
}
</style>
