import { render, staticRenderFns } from "./GlobalHeader.vue?vue&type=template&id=7a9001a2&scoped=true&"
import script from "./GlobalHeader.vue?vue&type=script&lang=ts&"
export * from "./GlobalHeader.vue?vue&type=script&lang=ts&"
import style0 from "./GlobalHeader.vue?vue&type=style&index=0&id=7a9001a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a9001a2",
  null
  
)

/* custom blocks */
import block0 from "./GlobalHeader.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCol,VContainer,VExpandTransition,VRow,VSheet})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
