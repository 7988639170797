import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { ActionTree, Module, MutationTree } from 'vuex';
// @ts-expect-error
import installHotkeys from './libs/videojs.hotkeys.ts';
import { compareStrings } from '@funimation/comp-utils';
import {
  fontColorMap,
  lightColors,
  backgroundColorMap,
  fontSizeMap
} from './subtitleStyles';
import {
  autoplayFeatureDetection,
  normalizeToPlayerSources,
  sortSourcesTypesFirst,
  sortSourcesM3U8sFirst
} from './utils';

installHotkeys(videojs);

type RootState = Record<string, any>;

type PlayerInstanceCustom = null | VideoJsPlayer & {
  // External plugin
  hotkeys: any;
  ads: any;

  // @types/video.js out-of-date
  requestFullscreen: (options: any) => void;

  // @types/video.js out-of-date
  textTrackSettings: Record<string, any>;
};

type VideoJsPlayerOptionsCustom = VideoJsPlayerOptions & {
  // @types/video.js out-of-date
  playsinline: boolean;

  // @types/video.js out-of-date
  preferFullWindow: boolean;
};

interface State {
  autoplayAllowed: boolean;
  autoplayAudioAllowed: boolean;
  autoplayTested: boolean;
  isFullScreen: boolean;
  isMuted: boolean;
  isPaused: boolean;
  player: PlayerInstanceCustom;
  poster: string;
  quality: number | 'auto';
  timeCurrent: number;
  timeTotal: number;
  videojs: (typeof videojs);
  videoRepresentations: VideoQualities;
  volumeLevel: number;
}

type VideoQuality = {
  bandwidth: number;
  enabled: (enable?: boolean) => boolean;
  height: number;
};
type VideoQualities = Array<VideoQuality>;

interface SubtitleSource {
  fileExt: string;
  filePath: string;
  contentType: string;
  languageCode: string;
}

interface AIP {
  out: number;
  in: number;
}

interface VideoSource {
  manifestPath: string;
  fileExt: string;
  subtitles: SubtitleSource[];
  accessType: string;
  sessionId: string;
  audioLanguage: string;
  version: string;
  aips: AIP[];
  drmToken: string;
  drmType: string;
  errorName: string | undefined;
  venueVideoId: string | undefined;
}

/** Used to get initial state of store. */
function getDefaultState(): State {
  return {
    autoplayAllowed: false,
    autoplayAudioAllowed: false,
    autoplayTested: false,
    isFullScreen: false,
    isPaused: true, // Need to test for autoplay first
    isMuted: false, // Need to test for autoplay first
    player: null,
    poster: '',
    quality: 'auto',
    timeCurrent: 0,
    timeTotal: 0,
    videojs,
    videoRepresentations: [],
    volumeLevel: 1,
  };
}

export const playPauseKeyFn = function (event: any, player: PlayerInstanceCustom) {
  const spaceKeyPressed = (event.which === 32);
  if (!spaceKeyPressed) {
    return false;
  }

  // Check for ad breaks of ads initialized
  // TODO: Move inAdBreak logic to store in @funimation/comp-video-player-ads-gam
  const playerAds = player?.ads;
  if (playerAds?.inAdBreak && playerAds.inAdBreak()) {
    return false; // if the ad is playing, then do not allow the user to use spacebar hotkey
  }

  return true;
};

const actions: ActionTree<State, RootState> = {
  /** Sets-up a listener to fire once on first playback */
  _addPlayOnceListener(context): void {
    const player = context.state.player;

    // First time play is called.
    player?.one('play', () => {
      // TODO: Move to TextTrack.on('load')
      context.dispatch('_setSubtitleStyles');

      context.rootState.videoPlayer.eventBus.emit('playback-firstplay');

      // Player should now be trusted by the browser to autoplay.
      player?.autoplay(true);
    });
  },

  /**
   * Cleans-up the player instance. Called on VideoPlayerCore.beforeDestroy().
   */
  _destroy(context): void {
    const player = context.state.player;
    if (player) {
      const disposable = !player.isDisposed();
      if (disposable) {
        player.dispose();
      }
    }

    context.commit('PLAYER', null);
  },

  /** Inits State & Player (video.js). */
  async _init(context, elementRef): Promise<void> {
    await context.dispatch('_initExternalLocale');

    // Ensure fresh state.
    context.commit('STATE', getDefaultState());

    const playerOptions: VideoJsPlayerOptionsCustom = {
      bigPlayButton: false,
      controlBar: {
        children: [
          'progressControl',
        ],
      },
      controls: true,
      defaultVolume: context.rootState.userInfo?.selectedVolume ?? 1,
      fluid: true,
      // html5 Tech options: https://docs.videojs.com/tutorial-options.html#html5
      html5: {
        // For consistent experience, always use non-native audio
        nativeAudioTracks: false,

        // For consistent experience, always use custom/styled controls
        nativeControlsForTouch: false,

        // For consistent experience, always use non-native captions
        nativeTextTracks: false,

        // For consistent experience, always use non-native video
        nativeVideoTracks: false,

        // vhs options: https://github.com/videojs/http-streaming#list
        vhs: {
          // For consistent experience, try to use Media Source Extensions instead of native HLS.
          overrideNative: true,
        },
      },
      language: context.rootState.userInfo?.userLanguage,
      loop: false,

      preload: 'auto',
      responsive: true,

      // Play videos inline (not fullscreen/native) by default (for iOS Safari).
      playsinline: true,

      // On devices which do not support the HTML5 fullscreen API (iPhone), the
      // player will be stretched to fill the browser window.
      preferFullWindow: true,

      sources: [],
      techOrder: [ 'html5' ], // default but here to be explicit
    };

    // Init player with detected autoplay support
    try {
      const result = await autoplayFeatureDetection();

      context.commit('AUTOPLAY_ALLOWED', result.autoplayAllowed);
      context.commit('AUTOPLAY_AUDIO_ALLOWED', result.audioAllowed);
      context.commit('IS_MUTED', !result.audioAllowed);

      playerOptions.autoplay = result.autoplayAllowed;
      playerOptions.muted = !result.audioAllowed;
    } catch (error) {
      const errorMessage = 'Failed to detect autoplay support';
      console.error(new Error(errorMessage));
      console.error(error);
    }
    context.commit('AUTOPLAY_TESTED');

    const player = context.state.videojs(elementRef, playerOptions);
    context.commit('PLAYER', player);

    context.dispatch('_initEventListeners');
  },

  /**
   * Initialize Player Event Listeners
   *
   * Additional events at:
   *  - https://docs.videojs.com/player#event:beforepluginsetup:$name
   *  - https://html.spec.whatwg.org/multipage/media.html#mediaevents
   */
  _initEventListeners(context): void {
    const player = context.state.player;
    if (!player) {
      console.error('Unable to add Event Listeners, context.state.player wasn\'t available.');
      return;
    }

    const eventBus = context.rootState.videoPlayer.eventBus;

    /*
     * BASE LIFECYCLE PROXIES
     */

    /*
     * Fires when player is ready.
     *
     * NOTE, don't use the on(...) event format, as it may be too late to listen
     * to ready.
     */
    player.ready(() => {
      // Turn on player's spinner by default
      player.addClass('vjs-waiting');

      context.dispatch('_initHotkeys');

      eventBus.emit('player-ready');
    });

    /* Fires when the current playlist is empty. */
    player.on('emptied', () => {
      eventBus.emit('player-emptied');
    });

    /* Fires when the player is being disposed of. */
    player.on('dispose', () => {
      eventBus.emit('player-dispose');
    });

    /*
     * Fires when the player, a tech, a plugin, or a component emits an error
     * event.
     *
     * See: https://docs.videojs.com/mediaerror
     */
    player.on('error', () => {
      const message = context.state.player?.error()?.message;
      eventBus.emit('media-error', message);
      context.dispatch('videoPlayerModal/dispatchTechnicalError', undefined, { root: true });
    });

    /*
     * PLAYBACK PROXIES
     */

    /*
     * Fires when playback (any of):
     *  - started via autoplay
     *  - started via play() method
     *  - no longer paused
     */
    player.on('play', () => {
      context.commit('IS_PAUSED', player.paused());
      eventBus.emit('playback-play');
    });

    /* Fires whenever the video has been paused. */
    player.on('pause', () => {
      context.commit('IS_PAUSED', player.paused());
      eventBus.emit('playback-pause');
    });

    /* Fires when the end of the video is reached (currentTime == duration). */
    player.on('ended', async () => {
      // iPhone fires ended after each ad
      if (context.state.videojs.browser.IS_IPHONE) {
        try {
          const inAdBreak = await context.dispatch('inAdBreak');
          if (inAdBreak) {
            return; // Abort if in Ad Break.
          }
        } catch (error) {
          // No nothing
        }
      }

      // TODO: Refactor uses of playback-ended to use state instead.
      eventBus.emit('playback-ended');
    });

    /* Fires whenever the player has started seeking to a new position. */
    player.on('seeking', () => {
      eventBus.emit('playback-seeking');
    });

    /* Fires whenever the player has finished seeking to a new position. */
    player.on('seeked', () => {
      eventBus.emit('playback-seeked');
    });

    /*
     * Fires when the current playback position has changed.
     *
     * During playback this is fired every 15-250 milliseconds, depending on the
     * playback technology in use.
     */
    player.on('timeupdate', () => {
      // TODO: Debounce every 500 ms
      const currentTime = player.currentTime();
      context.commit('TIME_CURRENT', currentTime);
      eventBus.emit('playback-time-current', currentTime);
    });

    /* Fires when the video duration has changed. */
    player.on('durationchange', () => {
      const duration = player.duration();

      // TODO: Refactor uses of TIME_TOTAL to use playback-time-total instead.
      context.commit('TIME_TOTAL', duration);

      eventBus.emit('playback-time-total', duration);
    });

    /*
     * PROP UPDATE PROXIES
     */

    /* Fires when the player enters/exits fullscreen. */
    player.on('fullscreenchange', () => {
      // Sync from player
      context.commit('IS_FULLSCREEN', player.isFullscreen());
    });

    /* Fires when the player volume or muted is changed. */
    player.on('volumechange', () => {
      const volumeLevel = player.volume();
      const isMuted = player.muted();

      // Sync from player
      context.commit('VOLUME_LEVEL', volumeLevel);
      context.commit('IS_MUTED', isMuted);

      eventBus.emit('volume-changed', {
        isMuted,
        volumeLevel
      });
    });

    /*
     * USER ACTIVITY PROXIES
     */

    /* Fires when user is active/inactive */
    player.on('useractive', () => {
      eventBus.emit('user-active', true);
    });
    player.on('userinactive', () => {
      eventBus.emit('user-active', false);
    });

    /*
     * NETWORK PROXIES
     */

    /* Fires when the browser begins looking for video data to download. */
    player.on('loadstart', () => {
      eventBus.emit('network-start');
    });

    /* Fires while the browser is downloading video data. */
    player.on('progress', () => {
      eventBus.emit('network-progress');
    });

    /* Fires when the browser is intentionally not downloading video data. */
    player.on('suspend', () => {
      eventBus.emit('network-suspend');
    });

    /*
     * Fires when the browser stops downloading video data before it is
     * completely downloaded, but not due to an error.
     */
    player.on('abort', () => {
      eventBus.emit('network-abort');
    });

    /*
     * Fires when the browser is trying to download video data, but the data is
     * unavailable.
     */
    player.on('stalled', () => {
      eventBus.emit('network-stalled');
    });

    /*
     * READY STATE PROXIES
     */

    /*
     * Fires when the browser has just determined the duration and dimensions of
     * the video resource, and the text tracks are ready.
     */
    player.on('loadedmetadata', () => {
      eventBus.emit('readystate-loadedmetadata');

      // Read Video Representations (Qualities) after metadata is loaded
      context.dispatch('_setVideoRepresentations');
    });

    /*
     * Fires when the browser has downloaded the video data at the current
     * playback position for the first time.
     */
    player.on('loadeddata', () => {
      eventBus.emit('readystate-loadeddata');
    });

    /*
     * Fires when the browser can resume playback of the video data, BUT doesn't
     * have enough buffer to reach the end of the video.
     */
    player.on('canplay', () => {
      eventBus.emit('readystate-canplay');
    });

    /*
     * Fires when the browser can resume playback of the video data, AND has
     * enough buffer to reach the end of the video.
     */
    player.on('canplaythrough', () => {
      eventBus.emit('readystate-canplaythrough');
    });

    /*
     * Fires when playback is first started, and whenever it is restarted. For
     * example it is fired when playback resumes after having been paused or
     * delayed due to lack of data.
     */
    player.on('playing', () => {
      eventBus.emit('readystate-playing');
    });

    /*
     * Fires when playback playback has stopped because the next frame is not
     * available, but the browser expects that frame to become available in due
     * course.
     */
    player.on('waiting', () => {
      eventBus.emit('readystate-waiting');
    });
  },

  /** Add localizations to `video.js` */
  async _initExternalLocale(context): Promise<void> {
    let language: string = context.getters.uiLanguage;
    const notEnglish = language && language !== 'en';
    if (notEnglish) {
      language = language.toLowerCase();
      try {
        const locals = await import(`video.js/dist/lang/${language}.json`);
        context.state.videojs.addLanguage(language, locals);

        context.rootState.videoPlayer.eventBus.emit('videojs-locale-loaded', language);
      } catch (error) {
        const cannotFindModule = error.message.includes('Cannot find module');
        if (cannotFindModule) {
          console.error(`Unable to load "${language}" locale.`);
          return;
        }

        console.error(error);
      }
    }
  },

  /**
   * Initialize Hotkeys plugin
   *
   * Options: https://www.npmjs.com/package/videojs-hotkeys#options
   */
  _initHotkeys(context): void {
    if (!context.state.player?.hotkeys) {
      return;
    }

    context.state.player?.hotkeys({
      alwaysCaptureHotkeys: true,
      enableInactiveFocus: true,
      enableModifiersForNumbers: false,
      enableVolumeScroll: false, // true = Preventing menu scrolling.
      seekStep: 5,
      volumeStep: 0.05,
      playPauseKey: playPauseKeyFn,
    });
  },

  /**
   * Toggles between fullscreen states, and updates `isFullscreen` state.
   *
   * See Details: https://docs.videojs.com/player#requestFullscreen
   */
  fullscreenToggle(context): void {
    const player = context.state.player;
    if (!player) {
      console.error('"player" instance missing');
      return;
    }

    if (player.isFullscreen()) {
      player.exitFullscreen();
    } else {
      player.requestFullscreen();
    }
  },

  /**
   * Returns true when ad plugin is in ad mode and MAY play ads.
   *
   * TODO: Move to store in @funimation/comp-video-player-ads-gam
   */
  inAdBreak(context): boolean {
    const player = context.state.player;
    if (!player) {
      return false;
    }

    const playerAds = player.ads;
    if (!playerAds) {
      return false;
    }

    return playerAds.inAdBreak();
  },

  /** Toggles between muted states, and updates `isMuted` state. */
  async muteToggle(context): Promise<void> {
    const player = context.state.player;
    if (player) {
      const newValue = !player.muted();
      player.muted(newValue);
    }
  },

  /** Pauses player, and updates `isPaused` state. */
  pause(context): void {
    const player = context.state.player;
    if (player) {
      player.pause();

      // Sync from player
      context.commit('IS_PAUSED', player.paused());
    }
  },

  /**
   * REQUEST the player play, and updates `isPaused` state.
   *
   * Also catches play request errors thrown by the browser.
   *
   * See Details: https://videojs.com/blog/autoplay-best-practices-with-video-js/
   */
  async playRequest(context): Promise<void> {
    const player = context.state.player;
    if (player) {
      try {
        await player.play();
      } catch (error) {
        // Play/autoplay was prevented by browser.
        // Nothing to do, but wait for user to hit play.
        console.warn(error);
      }

      // Sync from player
      context.commit('IS_PAUSED', player.paused());
    }
  },

  /** Toggles between playing and paused states. */
  playToggle(context): void {
    const player = context.state.player;
    if (player) {
      if (player.paused()) {
        context.dispatch('playRequest');
      }
      else {
        context.dispatch('pause');
      }
    }
  },

  /** Seeks playback forward/back by `offsetSeconds`. */
  seek(context, offsetSeconds: number): void {
    const player = context.state.player;
    if (player) {
      const currentTime = player.currentTime();
      const targetTime = currentTime + (offsetSeconds || 0);
      // TODO: Do we need to prevent targetTime < 0 or targetTime > timeTotal?
      player.currentTime(targetTime);
    }
  },

  /** Sets player volume; in decimal format. */
  setVolume(context, percentAsDecimal: number): void {
    const player = context.state.player;
    if (player) {
      player.volume(percentAsDecimal ?? 1);
    }
  },

  setSubtitleTrack(context, payload): void {
    const player = context.state.player;
    const tracks = player?.textTracks() as any;
    if (!tracks) {
      return;
    }
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      track.mode = track.language === payload ? 'showing' : 'hidden';
    }
  },

  setQuality(context, payload): void {
    if (!isNaN(payload)) {
      // Enable matches, and disable all other representations
      context.state.videoRepresentations.forEach((quality) => {
        let enabled = quality.height === payload;

        // FIXME: 270p & 234p are causing media errors, so we're turning them off for now.
        if ([ 270, 234 ].includes(quality.height)) {
          enabled = false;
        }

        quality.enabled(enabled);
      });
    }
    else {
      // (auto) Enable all representations
      context.state.videoRepresentations.forEach((quality) => {
        quality.enabled(true);
      });
    }

    context.commit('QUALITY', payload);
  },

  /** Adds Subtitle Tracks to the player. */
  _initSubtitleTracks(context, payload: Array<SubtitleSource>): void {
    const MANUAL_TRACK_CLEANUP = false; // false = TextTrack will be auto removed on a source change

    const player = context.state.player;
    if (!player) {
      // Player not ready yet.
      return;
    }

    if (!Array.isArray(payload)) {
      console.error(`payload is not an array: ${JSON.stringify(payload)}`);
      return;
    }

    const selectedSubtitleLanguage: string = context.rootGetters.selectedSubtitleLanguage;

    payload.forEach((subtitleSource) => {
      const ext = subtitleSource.fileExt;
      if (!ext) {
        console.error(`subtitleSource does not have a valid ext: ${JSON.stringify(subtitleSource)}`);
        return;
      }

      // Video.js only supports WebVTT
      if ( !compareStrings(ext, 'vtt') ) {
        // This is okay, don't log error.
        return;
      }

      const src = subtitleSource.filePath;
      if (!src) {
        console.error(`subtitleSource does not have a valid filePath: ${JSON.stringify(subtitleSource)}`);
        return;
      }

      const languageCode = subtitleSource.languageCode;
      if (!languageCode) {
        console.error(`subtitleSource does not have a valid languageCode: ${JSON.stringify(subtitleSource)}`);
        return;
      }

      // https://html.spec.whatwg.org/multipage/media.html#text-track-mode
      const mode = languageCode === selectedSubtitleLanguage ? 'showing' : 'disabled';

      const textTrackOptions: videojs.TextTrackOptions = {
        kind: 'subtitles',
        language: languageCode,
        mode,
        src,
        srclang: languageCode,
      };

      player.addRemoteTextTrack(
        textTrackOptions,
        MANUAL_TRACK_CLEANUP
      );
    });
  },

  /** Sets Video Representations (Qualities) */
  _setVideoRepresentations(context): void {
    const MUTE_WARNING = true;
    const tech = context.state.player?.tech(MUTE_WARNING) as any;
    let representations: VideoQualities = tech?.vhs?.representations ? tech?.vhs?.representations() : [];

    // Sort 1 by bandwidth
    representations = representations.sort((a, b) => {
      return b.bandwidth - a.bandwidth;
    });

    // Sort 2 by height (resolution)
    representations = representations.sort((a, b) => {
      return b.height - a.height;
    });

    // FIXME: 270p & 234p are causing media errors, so we're turning them off for now.
    representations.forEach((representation) => {
      if ([ 270, 234 ].includes(representation.height)) {
        representation.enabled(false);
      }
    });

    context.commit('VIDEO_REPRESENTATIONS', representations);
  },

  /** Adds Video Sources to the player after normalizing and sorting. */
  setVideoSource(context, payload: VideoSource): void {
    if (!payload) {
      return; // No source, so nothing to do.
    }

    context.dispatch('_addPlayOnceListener');

    // Normalize, sort, and set source
    const normalizedSources = normalizeToPlayerSources(payload);
    const sortedSourcesTypes = sortSourcesTypesFirst(normalizedSources);
    const sortedSourcesM3U8s = sortSourcesM3U8sFirst(sortedSourcesTypes);
    context.state.player?.src(sortedSourcesM3U8s);
  },

  /** Sets the visual style for Subtitles */
  _setSubtitleStyles(context): void {
    const textTrackSettings = context.state.player?.textTrackSettings;

    if (!textTrackSettings) {
      const error = new TypeError('"player.textTrackSettings" is not available');
      console.error(error);
      return;
    }

    const OPACITY_OPAQUE = '1';
    const OPACITY_SEMI_TRANS = '0.5';
    const OPACITY_TRANS = '0';

    const subtitleStyles = context.rootGetters.subtitleStyles;

    const color = fontColorMap[subtitleStyles?.fontColorPreference];
    const backgroundColor = backgroundColorMap[subtitleStyles?.backgroundPreference];
    const fontPercent = fontSizeMap[subtitleStyles?.textSizePreference];

    const semiTransText = subtitleStyles?.fontOpacityPreference === 'true';
    const textOpacity = semiTransText ? OPACITY_SEMI_TRANS : OPACITY_OPAQUE;

    let backgroundOpacity = OPACITY_TRANS;
    if (backgroundColor) {
      if (semiTransText) {
        backgroundOpacity = OPACITY_SEMI_TRANS;
      } else {
        backgroundOpacity = OPACITY_OPAQUE;
      }
    }
    // TODO: remove all text classes if we decide not to use them
    if (!backgroundColor) {
      const isLightColor = lightColors.includes(subtitleStyles?.fontColorPreference);
      if (isLightColor) {
        context.state.player?.el().classList.add('text-edge-style-drop-shadow');
        // edgeStyle = 'uniform';
      } else {
        context.state.player?.el().classList.add('text-edge-style-inset');
        // edgeStyle = 'depressed';
      }
    }

    /*
     * Values passed below must be enumerated values from
     * https://docs.videojs.com/tracks_text-track-settings.js.html
     */
    textTrackSettings.setValues({
      backgroundColor,
      backgroundOpacity,
      color,
      edgeStyle: 'none',
      fontFamily: 'proportionalSansSerif',
      fontPercent,
      textOpacity,
    });
  }
};

const mutations: MutationTree<State> = {
  AUTOPLAY_ALLOWED(state, payload) {
    state.autoplayAllowed = payload;
  },

  AUTOPLAY_AUDIO_ALLOWED(state, payload) {
    state.autoplayAudioAllowed = payload;
  },

  AUTOPLAY_TESTED(state) {
    state.autoplayTested = true;
  },

  IS_FULLSCREEN(state, payload) {
    state.isFullScreen = payload;
  },

  IS_MUTED(state, payload) {
    state.isMuted = payload;
  },

  IS_PAUSED(state, payload) {
    state.isPaused = payload;
  },

  PLAYER(state, payload) {
    state.player = payload;
  },

  POSTER(state, payload) {
    state.poster = payload;
    if (state.player) {
      state.player.poster(payload);
    }
  },

  QUALITY(state, payload) {
    state.quality = payload;
  },

  TIME_CURRENT(state, payload) {
    state.timeCurrent = payload;
  },

  TIME_TOTAL(state, payload) {
    state.timeTotal = payload;
  },

  STATE(state, newState) {
    Object.assign(state, newState);
  },

  VIDEO_REPRESENTATIONS(state, payload) {
    state.videoRepresentations = payload;
  },

  VOLUME_LEVEL(state, payload) {
    state.volumeLevel = payload;
  },
};

const getters = {
  videoPlayerQuality() {
    return 'auto';
  }
};

const store: Module<State, RootState> = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: getDefaultState(),
};

export default store;
