


























































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'NextEpisodePaywallError',

  computed: {
    ...mapGetters({
      currentShowSlug: 'videoPlayerCatalog/currentShowSlug',
      currentShowName: 'videoPlayerCatalog/currentShowName',
      nextEpisodeSlug: 'videoPlayerCatalog/nextEpisodeSlug',
      nextEpisodeType: 'videoPlayerCatalog/nextEpisodeType',
      currentEpisode: 'videoPlayerCatalog/currentEpisode',
      nextEpisodeSVODPaywallError: 'videoPlayerCatalog/nextEpisodeSVODPaywallError',
      nextEpisodeESTOnlyPaywallError: 'videoPlayerCatalog/nextEpisodeESTOnlyPaywallError',
    }),
    upsellRedirectPayload(): Record<string, any> {
      const showSlug = this.currentShowSlug;
      const episodeSlug = this.nextEpisodeSlug;
      const showName = this.currentShowName;
      
      return {
        showSlug,
        episodeSlug,
        showName
      };
    }
  },
  methods: {
    ...mapActions([ 'setUpsellRedirect', 'setDigitalCopyUpsellRedirect' ]),
  },
});
