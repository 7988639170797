export const API_URLS = {
  titleService: {
    continueWatching: (episodeId: number, region: string, deviceType: string) => {
      return `/episodes/${episodeId}/meta?region=${region}&deviceType=${deviceType}`;
    },
  },
  playbackService: {
    anonPlay: (contentId: string, deviceType: string) => {
      return `/play/anonymous/${contentId}?deviceType=${deviceType}`;
    },
    play: (contentId: string, deviceType: string, sessionId: string) => {
      return `/play/${contentId}?deviceType=${deviceType}&playbackStreamId=${sessionId}`;
    },
  },
  playlistService: {
    show: (showId: string) => {
      return `/playlist/show/${showId}`;
    },
  },
  catalogProjectorService: {
    episode: (contentId: string) => {
      return `/data/v1/episodes/${contentId}.json`;
    },
  },
};
