<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="12" md="5">
          <v-card
            class="mx-auto mb-9"
            color="transparent"
            flat
          >
            <div class="pa-4">
              <episode-card />
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels light>
            <v-expansion-panel flat>
              <v-expansion-panel-header
                class="headline primary--text text-uppercase font-weight-bold"
                disable-icon-rotate
              >
                Episode Card Grid
                <template v-slot:actions>
                  <v-icon class="primary--text" x-large>mdi-code-tags</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card color="transparent" flat>
                  <v-card-text>
                    <div v-highlight >
                      <pre class="example-code-block language-Markup"><code>{{cardGridLayout}}</code></pre>
                    </div>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <episode-card-grid  />
    </v-col>
  </v-row>

</template>
<script>
import Vue from 'vue';
import 'vue-code-highlight/themes/duotone-sea.css';
import 'vue-code-highlight/themes/window.css';
import VueCodeHighlight from 'vue-code-highlight';
Vue.use(VueCodeHighlight);
export default {
  name: 'episode-cards',
  data() {
    return {
      seasonSelect: 'Season 1',
      languages: [],
      versions: [],
      sortedBy: [],
      cardLayout: `
<template>
  <v-card
    class="episode__card"
    flat
    tile
    color="transparent"
    :to="/"
  >
    <v-img
      src="https://picsum.photos/id/11/256/144"
      :aspect-ratio="16/9"
      contain
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
      <span class="episode__card--subscribe py-1 px-4 text-uppercase">subscribe</span>
      <span class="episode__card--rating py-1 px-2 text-uppercase">TV-14</span>
    </v-img>
    <div
      class="overline text-capitalize pt-4"
      style="line-height: 1; letter-spacing: 0!important;"
    >
    Episode 64
    </div>
    <!--text-line-clamp-1 gives 0px padding for bottom. must compensate with bottom margin-->
    <v-card-title class="body-1 pt-0 px-0 text-line-clamp-1 mb-4">
    Suspendisse blanit uliamcorper
    </v-card-title>
    <v-card-subtitle class="caption px-0">
      22:36 | English
      <v-icon color="accent" size="12">mdi-lock</v-icon>
      , Japanese
    </v-card-subtitle>
  </v-card>
</template>
<style scoped lang="scss">
.episode__card {
  &--subscribe,
  &--rating {
    display: block;
    position: absolute;
    color: var(--v-white-base) !important;
  }
  &--subscribe {
    background-color: var(--v-warning-base) !important;
    font-size: 10px;
    font-weight: 800;
    top: 0;
    left: 0;
  }
  &--rating {
    background-color: var(--v-black-base) !important;
    border-radius: 2px;
    font-size: 11px;
    bottom: 10px;
    right: 10px;
  }
}
</style>
      `,
      cardGridLayout: `
<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <div
            class="d-flex align-center flex-column flex-sm-row"
            color="transparent"
          >
            <v-select
              v-model="seasonSelect"
              class="mb-4 mb-md-0 shrink"
              min-width="100%"
              :items="['Season 1', 'Season 2', 'Season 3', 'Season 4']"
              label="Solo field"
              solo
              light
              hide-details
            ></v-select>
            <v-spacer />
            <div class="d-flex align-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="text-capitalize mb-4 mb-md-0"
                    text
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <v-icon class="mr-2" small>mdi-format-list-bulleted</v-icon>
                    language
                  </v-btn>
                </template>
                <v-list flat dense>
                  <v-list-item-group
                    v-model="languages"
                    multiple
                    active-class=""
                  >
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action class="mr-4">
                          <v-checkbox color="secondary" :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>English</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="text-capitalize mb-4 mb-md-0"
                    text
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <v-icon class="mr-2" small>mdi-format-list-bulleted</v-icon>
                    version
                  </v-btn>
                </template>
                <v-list flat dense>
                  <v-list-item-group
                    v-model="versions"
                    multiple
                    active-class=""
                  >
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action class="mr-4">
                          <v-checkbox color="secondary" :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Simulcast</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="text-capitalize mb-4 mb-md-0"
                    text
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <v-icon class="mr-2" small>mdi-sort-ascending</v-icon>
                    version
                  </v-btn>
                </template>
                <v-list flat dense>
                  <v-list-item-group
                    v-model="sortedBy"
                    multiple
                    active-class=""
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Sequential</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Date Added</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Most Popular</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(n, index) in 12"
          :key="index"
          cols="12" sm="6" md="3" xl="2"
        >
          <EpisodeCard />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
      `,
    };
  },
};
</script>
<style scoped lang="scss">
pre.example-code-block {
  width: 100%;
}
</style>
