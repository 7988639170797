import { Module } from 'vuex';
import {
  PluginOptions,
  RootState,
  State
} from '../types';
import { getDefaultState } from './state';
import { createActions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';


export default function createStore(options: PluginOptions): Module<State, RootState> {
  return {
    actions: createActions(options),
    getters,
    mutations,
    state: getDefaultState(),
    namespaced: true,
  };
}
