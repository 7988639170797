




































































import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
export default Vue.extend({
  name: 'main-nav-panel',
  data() {
    return {
      hover: false,
    };
  },
  props: {
    mainNav: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapMutations([ 'SET_DRAWER_STATE' ]),
    closeDrawer() {
      this.SET_DRAWER_STATE(false);
    },
  },
  computed: {
    ...mapGetters([ 'isLoggedIn' ]),
  },
});
