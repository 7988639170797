import axios from 'axios';
import { API_URLS } from './urls';
import { dispatchPlayBackErrors } from './errorHandling';
import { ContinueWatching, TitleEpisode, PlaylistEpisode, PlayResponse } from '../types';


export async function getEpisodeData(
  catalogProjectorServiceBase: string,
  context: Record<string, any>,
  contentId: string,
  dispatchError = true
): Promise<TitleEpisode | undefined> {
  let result;
  try {
    result = await axios.get(
      `${catalogProjectorServiceBase}${API_URLS.catalogProjectorService.episode(contentId)}`
    );
  } catch (err) {
    if (dispatchError) {
      context.dispatch('videoPlayerModal/dispatchContentNotFoundError', undefined, { root: true });
    }

    console.error(`CATALOG PROJECTION ERROR: Error getting episode with content id: ${contentId}: ${err}`);
    return;
  }
  
  return result?.data;
}
  
export async function getEpisodeVideos(
  episodeToGet: string,
  episodeId: string,
  token: string | undefined,
  playbackServiceBase: string,
  context: Record<string, any>,
  dispatchErrors = true,
  isUserPremium: boolean
): Promise<PlayResponse | undefined> {
  let requestHeaders;
  let requestPath = API_URLS.playbackService.anonPlay(episodeId, 'web');
  if (token) {
    requestHeaders = {
      headers: {
        Authorization: `Token ${token}`,
      }
    };
    requestPath = API_URLS.playbackService.play(
      episodeId,
      'web',
      context.rootGetters['sessionId']
    );
  }
  
  let response;
  try {
    const requestUrl =`${playbackServiceBase}${requestPath}`;
    response = await axios.get(requestUrl, requestHeaders);
  } catch (err) {
    const dispatchedError = dispatchPlayBackErrors(episodeToGet, context, (err as Record<string, any>), episodeId, dispatchErrors);
    if (episodeToGet === 'nextEpisode') {
      if (dispatchedError.ESTOnlyError) {
        context.commit('SET_NEXT_EPISODE_EST_ONLY_PAYWALL_ERROR', true);
      }
      else if (!isUserPremium && dispatchedError.SVODError) {
        context.commit('SET_NEXT_EPISODE_SVOD_PAYWALL_ERROR', true);
      } else {
        // when there is a next episode state that is not accounted for (eg. out of territory, unknown 500, svod error when user is premium).
        // show the normal endcard and let the next video handle the error.
        context.commit('SET_NEXT_EPISODE_EXPERIENCE_MATCHES', true);
      }
    }
  
    return;
  }
  
  return response?.data;
}

export async function getContinueWatchingData(
  titleServiceBase: string,
  episodeVenueId: number,
  context: Record<string, any>
) {
  const token = context.rootState.userInfo?.token;
  if (!token) {
    return;
  }

  const requestHeaders = {
    headers: {
      Authorization: `Token ${token}`,
    }
  };
  const region = context.rootState.userInfo?.region;
  const requestPath = API_URLS.titleService.continueWatching(episodeVenueId, region, 'web');

  let response;
  try {
    const requestUrl =`${titleServiceBase}${requestPath}`;
    response = await axios.get(requestUrl, requestHeaders);
  } catch (err) {
    console.error(`Error getting continue watching data with episode venue id: ${episodeVenueId}: ${err}`);
    return;
  }

  const continueWatchingData: ContinueWatching = response?.data;
  if (
    !continueWatchingData
  ) {
    console.error(`CONTINUE WATCHING ERROR: No continue watching data present in response for episode with episode id: ${episodeVenueId}`);
    return;
  }

  return continueWatchingData;
}

export async function getShowPlaylist(
  playlistServiceBase: string,
  showId: string
): Promise<PlaylistEpisode[] | undefined> {
  let result;
  try {
    result = await axios.get(`${playlistServiceBase}${API_URLS.playlistService.show(showId)}`);
  } catch (err) {
    console.error(`Error getting playlist for show with id: ${showId}: ${err}`);
    return;
  }

  const playlist: PlaylistEpisode[] = result?.data?.playlist;
  if (!playlist) {
    console.error(`SHOW PLAYLIST ERROR: No results from server for playlist for show with id: ${showId}`);
    return;
  }
  if (!Array.isArray(playlist)) {
    console.error(`SHOW PLAYLIST ERROR: playlist is malformed for show with id: ${showId}`);
    return;
  }
  if (playlist.length < 1) {
    console.error(`SHOW PLAYLIST ERROR: playlist is empty for show with id: ${showId}`);
    return;
  }

  return playlist;
}
