








































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {};
  },

  computed: {
    ...mapGetters('videoPlayerCatalog', [
      'episodeKeyPosterArt',
      'episodeSpokenLanguages',
      'subtitleSources',
      'episodeVersions',
    ]),
  },
});
