/* istanbul ignore file */

import ContentDetailsTabs from './ContentDetailsTabs.vue';

export default {
  install(Vue: any) {
    Vue.component('content-details-tabs', ContentDetailsTabs);
  },
};


