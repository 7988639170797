import { PluginObject } from 'vue';
import { Store } from 'vuex';

import VideoPlayerModal from './index.vue';

export interface ModalContent {
  headerContent: string;
  bodyContent: string;
  buttonText: string;
  buttonUrl: string;
  backButton: boolean; // TODO: Allow passing URL || history.back()
  reloadButton: boolean;
  closeButton: boolean;
  showModal: boolean;
  transparent: boolean;
}

export const store = {
  namespaced: true,
  state: {
    headerContent: '',
    bodyContent: '',
    buttonText: '',
    buttonUrl: '',
    backButton: false,
    reloadButton: false,
    closeButton: false,
    showModal: false,
    transparent: false,
  },
  actions: {
    dispatchTechnicalError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.technical.headerContent',
        bodyContent: 'errors.technical.bodyContent',
        reloadButton: true,
        backButton: true,
        showModal: true,
      });
    },
    dispatchContentNotFoundError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.contentNotFound.headerContent',
        bodyContent: 'errors.contentNotFound.bodyContent',
        backButton: true,
        showModal: true,
      });
    },
    dispatchOutOfTerritoryError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.outOfTerritory.headerContent',
        bodyContent: 'errors.outOfTerritory.bodyContent',
        backButton: true,
        showModal: true,
      });
    },
    dispatchNotAvailableOnDeviceError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.notAvailableOnDevice.headerContent',
        bodyContent: 'errors.notAvailableOnDevice.bodyContent',
        backButton: true,
        showModal: true,
      });
    },
    dispatchNotAvailableYetError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.notAvailableYet.headerContent',
        bodyContent: 'errors.notAvailableYet.bodyContent',
        backButton: true,
        showModal: true,
      });
    },
    dispatchNoLongerAvailableError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.noLongerAvailable.headerContent',
        bodyContent: 'errors.noLongerAvailable.bodyContent',
        backButton: true,
        showModal: true,
      });
    },
    dispatchStreamLimitError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.streamLimit.headerContent',
        bodyContent: 'errors.streamLimit.bodyContent',
        backButton: true,
        showModal: true,
      });
    },
    dispatchDigitalCopyError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.digitalCopy.headerContent',
        bodyContent: 'errors.digitalCopy.bodyContent',
        buttonText: 'errors.digitalCopy.buttonText',
        buttonUrl: '/digitalcopy',
        backButton: true,
        showModal: true,
      });
    },
    dispatchPaywallError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.paywall.headerContent',
        bodyContent: 'errors.paywall.bodyContent',
        buttonText: 'errors.paywall.buttonText',
        buttonUrl: '/subscribe',
        backButton: true,
        showModal: true,
      });
    },
    dispatchMatureContentError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.matureContent.headerContent',
        bodyContent: 'errors.matureContent.bodyContent',
        buttonText: 'errors.matureContent.buttonText',
        buttonUrl: '/account/#preferences',
        backButton: true,
        showModal: true,
      });
    },
    dispatchCorruptVideoError(context: Record<string, any>) {
      context.dispatch('setModalContent', {
        headerContent: 'errors.corruptVideo.headerContent',
        bodyContent: 'errors.corruptVideo.bodyContent',
        showModal: true,
        closeButton: true,
        transparent: true,
      });
    },
    setModalContent(context: Record<string, any>, payload: ModalContent) {
      context.commit('SET_HEADER_CONTENT', payload.headerContent);
      context.commit('SET_BODY_CONTENT', payload.bodyContent);
      context.commit('SET_BUTTON_TEXT', payload.buttonText);
      context.commit('SET_BUTTON_URL', payload.buttonUrl);
      context.commit('SET_BACK_BUTTON', payload.backButton);
      context.commit('SET_RELOAD_BUTTON', payload.reloadButton);
      context.commit('SET_CLOSE_BUTTON', payload.closeButton);
      context.commit('SET_SHOW_MODAL', payload.showModal);
      context.commit('SET_TRANSPARENT', payload.transparent);
    },
    setShow(context: Record<string, any>, payload: boolean) {
      context.commit('SET_SHOW_MODAL', payload);
    },
  },
  mutations: {
    SET_HEADER_CONTENT(state: Record<string, any>, payload: string) {
      state.headerContent = payload;
    },
    SET_BODY_CONTENT(state: Record<string, any>, payload: string) {
      state.bodyContent = payload;
    },
    SET_BUTTON_TEXT(state: Record<string, any>, payload: string) {
      state.buttonText = payload;
    },
    SET_BUTTON_URL(state: Record<string, any>, payload: string) {
      state.buttonUrl = payload;
    },
    SET_BACK_BUTTON(state: Record<string, any>, payload: boolean) {
      state.backButton = payload;
    },
    SET_RELOAD_BUTTON(state: Record<string, any>, payload: boolean) {
      state.reloadButton = payload;
    },
    SET_CLOSE_BUTTON(state: Record<string, any>, payload: boolean) {
      state.closeButton = payload;
    },
    SET_SHOW_MODAL(state: Record<string, any>, payload: boolean) {
      state.showModal = payload;
    },
    SET_TRANSPARENT(state: Record<string, any>, payload: boolean) {
      state.transparent = payload;
    },
  },
};

type StoreInstance = Store<undefined>;

/** Sets-up context and returns a Vue Plugin. */
const pluginFactory = /* istanbul ignore next */(storeInstance: StoreInstance): PluginObject<typeof storeInstance> => {
  if (!storeInstance) {
    throw new Error('VideoPlayerModal requires "storeInstance" argument.');
  }

  return {
    install(Vue) {
      storeInstance.registerModule('videoPlayerModal', store);

      Vue.component('video-player-modal', VideoPlayerModal);
    },
  };
};

export default pluginFactory;
