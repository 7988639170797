import Vue from 'vue';
import axios from 'axios';
import SearchData from './SearchData';
import {
  API_URLS,
  SearchParams
} from './config/apiUrls';

export interface SearchRequest {
  index: string;
  region: string;
  searchParams: SearchParams;
}

export interface SearchResultsState {
  item: SearchData | null;
  items: any[];
  paneItem: SearchData | null;
  paneItems: any[];
  loading: boolean;
}

export class SearchResults {
  constructor(config: any = {
    service: 'https://search.dev.funimationsvc.com',
  }) {
    this.searchCancelToken = null;
    this.config = {
      base: config.service + '/v1',
    };
  }
  searchCancelToken: any;
  config: any;
  async fetchSearchResults(index: string, region: string, searchParams: SearchParams): Promise < any > {
    if (this.searchCancelToken) {
      this.searchCancelToken.cancel();
    }
    this.searchCancelToken = axios.CancelToken.source();
    const response = await axios.get(this.config.base + API_URLS.searchService.search(index, region, searchParams), {
      cancelToken: this.searchCancelToken.token,
    } as any);
    this.searchCancelToken = null;
    const data = response.data;
    data.origin= searchParams.origin;
    return data;
  }
}

const setupStore = (context: any, config? : any) => {
  const searchResults = new SearchResults(config);
  const store = {
    state: {
      item: null,
      items: [],
      paneItem: null,
      paneItems: [],
      loading: false,
    },
    actions: {
      async fetchSearchResults(context: any, payload: SearchRequest) {
        let result;
        try {
          context.commit('SET_SEARCH_LOADING', true);
          result = await searchResults.fetchSearchResults(payload.index, payload.region, payload.searchParams);
          context.commit('SET_SEARCH_RESULTS', result);
          context.commit('SET_SEARCH_LOADING', false);
        } catch (error) {
          if (!error || /cancel/i.test(error)) {
            // no error, but request was canceled due to rapid requests
            return;
          }
          const err = new Error('Error fetching search results: ' + error);
          context.commit('ERROR', err, {
            root: true,
          });
        }
      },
    },
    mutations: {
      SET_SEARCH_LOADING(state: SearchResultsState, e: boolean) {
        state.loading = e;
      },
      CLEAR_SEARCH(state: SearchResultsState) {
        state.item = null;
        state.items = [];
      },
      SET_SEARCH_RESULTS(state: SearchResultsState, payload: any) {
        const stateSlice = payload.origin === 'searchPane'? 'paneItem': 'item';
        const stateSliceItems = payload.origin === 'searchPane'? 'paneItems': 'items';
        state[stateSlice] = payload;
        if (payload.offset === 0) {
          state[stateSliceItems] = [];
        }
        for (let x = 0; x < payload.items.hits.length; x += 1) {
          Vue.set(state[stateSliceItems], payload.offset + x, payload.items.hits[x]);
        }
      },

    },
  };
  context.registerModule('searchResults', store);
};

export default (context: any, config? : any) => {
  setupStore(context, config);
  return {
    install() {}, // eslint-disable-line
  };
};
