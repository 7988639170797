import axios from 'axios';
import { API_URLS } from './config/apiUrls';


const SET_PLAYBACK_PROGRESS_INTERVAL = 30000;

function setUpWatchHistoryWatchers(context: any) {
  let lastTime = 0;
  let updateWatchHistoryTimeoutId: number;

  const setPlaybackProgress = () => {
    const loop = () => {
      window.clearTimeout(updateWatchHistoryTimeoutId);
      updateWatchHistoryTimeoutId = window.setTimeout(setPlaybackProgress, SET_PLAYBACK_PROGRESS_INTERVAL);
    };

    // dont' update when the time is 0
    // don't update the same exact time over and over
    // don't update when current episode venue id is not present
    if (
      lastTime === context.state.videoPlayerCore.timeCurrent ||
      context.state.videoPlayerCore.timeCurrent === 0 ||
      !context.getters['videoPlayerCatalog/selectedVideoId']
    ) {
      return loop();
    }

    if (context.getters['videoPlayerCatalog/selectedVideoId']) {
      lastTime = context.state.videoPlayerCore.timeCurrent;
      context.dispatch('setPlaybackProgress', {
        deviceType: 'web',
        checkpoint: Math.floor(context.state.videoPlayerCore.timeCurrent),
        lang: context.getters.selectedSpokenLanguage,
        videoId: context.getters['videoPlayerCatalog/selectedVideoId'],
      });
    }

    loop();
  };

  context.dispatch('videoPlayer/modulesRegister', {
    name: 'watch-history',

    install() {
      context.state.videoPlayer.eventBus.once('playback-time-current', () => {
        setPlaybackProgress();
      });
    },

    destroy() {
      window.clearTimeout(updateWatchHistoryTimeoutId);
    },
  });
}

const setupStore = (context: any, config: Record<string, any>) => {
  if (!config || !config.videoStreamTrackerBaseUrl) {
    throw new TypeError('config must contain videoStreamTrackerBaseUrl');
  }

  const store = {
    state: {},
    getters: {},
    actions: {
      async setPlaybackProgress(context: any, payload: Record<string, any>) {
        if (!payload) {
          console.warn(new TypeError('setPlaybackProgress: action must include payload'));
          return;
        }
        if (!payload.deviceType) {
          console.warn(new TypeError(`setPlaybackProgress: payload must contain deviceType, payload: ${JSON.stringify(payload)}`));
          return;
        }
        if (!Number.isFinite(payload.checkpoint)) {
          console.warn(new TypeError(`setPlaybackProgress: checkpoint must be a number, payload: ${JSON.stringify(payload)}`));
        }
        if (!payload.lang) {
          console.warn(new TypeError(`setPlaybackProgress: payload must contain lang, payload: ${JSON.stringify(payload)}`));
          return;
        }
        if (!payload.videoId) {
          console.warn(new TypeError(`setPlaybackProgress: payload must contain videoId, payload: ${JSON.stringify(payload)}`));
          return;
        }

        const token = context.rootState.userInfo?.token;
        if (!token) {
          return;
        }

        try {
          return await axios.post(
            config.videoStreamTrackerBaseUrl + API_URLS.setPlaybackProgressService,
            {
              'device_type': payload.deviceType,
              'checkpoint': payload.checkpoint,
              'session_id': context.rootGetters['sessionId'],
              'lang': payload.lang,
              'video_id': payload.videoId,
              'device_id': payload.deviceId,
              'watched': payload.watched,
              'query_id': undefined,
            },
            {
              headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
        } catch (err) {
          console.error(`Error setting playback progress: subscription: ${context.rootState.userInfo?.userData?.subscription} video: ${payload.videoId} error: ${err}`);
        }
      },
    },
  };

  context.registerModule('watchHistory', store);
  setUpWatchHistoryWatchers(context);
};

export default (context: any, config: Record<string, any>) => {
  setupStore(context, config);
  return {
    install() { }, // eslint-disable-line
  };
};
