



















import Vue from 'vue';

const VueComponent = Vue.extend({
  name: 'video-player',

  mounted() {
    this.$store.dispatch('videoPlayer/_init');
  },


  beforeDestroy() {
    this.$store.dispatch('videoPlayer/_destroy');
  },
});

export default VueComponent;
