


















import Vue from 'vue';

export default Vue.extend({
  watch: {
    '$store.state.videoPlayerCore.player'(newValue) {
      if (newValue) {
        this.setSources();
      }
    },
  },

  methods: {
    async setSources() {
      const sources = [
        {
          filePath: 'https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8',
          ext: 'm3u8',
        }
      ];

      this.$store.dispatch('videoPlayerCore/setVideoSource', sources);
    },
  },
});
