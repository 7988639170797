
















































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
  name: 'big-play-button',

  data() {
    return {
      muteToggledOnce: false,
      playToggledOnce: false,
    };
  },

  computed: {
    ...mapState('videoPlayerCore', [
      'autoplayAllowed',
      'autoplayAudioAllowed',
      'autoplayTested',
      'isMuted',
      'isPaused',
    ]),

    showBigPlayButton(): boolean {
      return (
        this.autoplayTested && // Autoplay testing is done
        !this.playToggledOnce && // Video has NOT played yet
        !this.autoplayAllowed // Autoplay NOT allowed
      );
    },

    showUnmuteButton(): boolean {
      return (
        this.autoplayTested && // Autoplay testing is done
        !this.muteToggledOnce && // Mute has NOT been toggled off yet
        this.autoplayAllowed && // Autoplay is allowed...
        !this.autoplayAudioAllowed // ...but NOT with audio
      );
    },
  },

  watch: {
    isMuted(muted) {
      if (muted) {
        return;
      }
      this.muteToggledOnce = true;
    },

    isPaused(paused) {
      if (paused) {
        return;
      }
      this.playToggledOnce = true;
    },
  },

  methods: {
    ...mapActions('videoPlayerCore', [
      'playRequest',
      'muteToggle',
    ]),
  },
});
