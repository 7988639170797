






















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'video-player-meta-overlay',
  data() {
    return {
    };
  },

  computed: {
    ...mapGetters({
      currentEpisode: 'videoPlayerCatalog/currentEpisode',
      currentEpisodeType: 'videoPlayerCatalog/currentEpisodeType',
      currentShowSlug: 'videoPlayerCatalog/currentShowSlug',
    }),
  },
});
