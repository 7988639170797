
















import Vue from 'vue';
import { enOutOfTerritory  } from './enOutOfTerritory';

export default Vue.extend({
  name: 'out-of-territory',
  data() {
    return {
      outOfTerritory: this.$t('outOfTerritory'),
    };
  },
  computed: {
    region(): string {
      return window.region;
    },
    outOfTerritoryData(): any {
      const outOfTerritory: any = this.outOfTerritory;
      const englishOutOfTerritoryData: any = enOutOfTerritory;
      const enLocales = [ 'CA', 'GB', 'IE', 'AU', 'NZ' ];
      if ( this.region && enLocales.includes(this.region)) {
        outOfTerritory.message = englishOutOfTerritoryData[this.region].outOfTerritory.message;
      }
      return outOfTerritory.message;
    },

  },
});
