/* istanbul ignore file */
import './adStyles.css';
import Ad from './AdSetup';

export default (store: any, config: any) => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    install(Vue: any): void {
      function getRequiredState(store: any): string | undefined {
        return store.getters['videoPlayerCatalog/selectedVideoId'] &&
          store.state.userInfo.userDataReady;
      }

      function getVideoId(store: any): string | undefined {
        return store.getters['videoPlayerCatalog/selectedVideoId'];
      }

      store.dispatch('videoPlayer/modulesRegister', {
        name: 'video-ad-setup-GAM',
        install(context: any) {
          store.watch(
            () => getRequiredState(store),
            () => {
              const videoId = getVideoId(store);
              if (!videoId || !(window as any).google) {
                console.warn('I noticed you\'re using an ad blocker.  Watching ads pays for the animes you enjoy.  Consider unblocking us!');
                return;
              }
              const ad = new Ad({ context, store, config, videoId });
              ad.start();
            }
          );
        },
        destroy() {}, // eslint-disable-line
      });
    },
  };
};
