var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h6 font-weight-bold text-center text-line-clamp-1",domProps:{"textContent":_vm._s(_vm.$t('issueMsg'))}}),_c('p',{staticClass:"text-center text-body-2 font-weight-normal my-6"},[(_vm.matchingNextEpisodeExperienceIsSVODLocked)?_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t('mismatch-svod', {
          version: _vm.$t(_vm.selectedVersion),
          altVersion: _vm.$t(_vm.altNextEpisodeExperienceType),
          lang: _vm.$t(_vm.selectedSpokenLanguage),
          altLang: _vm.$t(_vm.altNextEpisodeExperienceSpokenLanguage),
          episodeType: _vm.$t(_vm.nextEpisodeType)
        })
      )}}):(_vm.matchingNextEpisodeExperienceIsESTOnlyLocked)?_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t('mismatch-est', {
          version: _vm.$t(_vm.selectedVersion),
          altVersion: _vm.$t(_vm.altNextEpisodeExperienceType),
          lang: _vm.$t(_vm.selectedSpokenLanguage),
          altLang: _vm.$t(_vm.altNextEpisodeExperienceSpokenLanguage),
          episodeType: _vm.$t(_vm.nextEpisodeType)
        })
      )}}):_vm._e()]),_c('div',{staticClass:"text-no-wrap"},[(_vm.matchingNextEpisodeExperienceIsSVODLocked)?_c('v-btn',{staticClass:"text-uppercase primary--text",attrs:{"color":"white"},domProps:{"textContent":_vm._s(_vm.$t('upgrade'))},on:{"click":function($event){return _vm.setUpsellRedirect(_vm.upsellRedirectPayload)}}}):(_vm.matchingNextEpisodeExperienceIsESTOnlyLocked)?_c('v-btn',{staticClass:"text-uppercase primary--text",attrs:{"color":"white"},domProps:{"textContent":_vm._s(_vm.$t('purchase'))},on:{"click":function($event){return _vm.setDigitalCopyUpsellRedirect(_vm.upsellRedirectPayload)}}}):_vm._e(),_c('v-btn',{staticClass:"ml-4 text-uppercase",attrs:{"depressed":"","color":"white","outlined":""},domProps:{"textContent":_vm._s(_vm.$t('continue'))},on:{"click":_vm.goToAlternateVideo}}),(_vm.currentEpisode)?_c('v-btn',{staticClass:"ml-4 text-uppercase",attrs:{"data-test":"button_currentepisode","depressed":"","color":"white","outlined":"","href":("/shows/" + _vm.currentShowSlug)},domProps:{"textContent":_vm._s(_vm.$t('back'))}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }