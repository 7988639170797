// @ts-expect-error
import contribAdsPlugin from 'videojs-contrib-ads';
import InteractiveAd from './InteractiveAd';
import 'videojs-contrib-ads/dist/videojs-contrib-ads.css';
// @ts-expect-error
import ImaPlugin from 'videojs-ima';
import 'videojs-ima/dist/videojs.ima.css';

declare global {
  interface Window {
    google: any;
  }
}

export default class Ad {
  accessTypeAvod: boolean;
  player: any;
  adsManager: any;
  ad: any;
  videoId: any;
  config: any;
  context: any;
  store: any;

  constructor(args: Record<string, any>) {
    const selectedVideoAccessType = args.store.getters['videoPlayerCatalog/selectedVideoAccessType'];

    // setting the video player
    this.player = args.context.player;
    this.videoId = args.videoId;
    this.config = args.config;
    this.context = args.context;
    this.store = args.store;
    this.accessTypeAvod = selectedVideoAccessType === 'AVOD';
  }

  start() {
    // VAST ad tag URL parameters
    // Docs: https://support.google.com/admanager/answer/10678356
    const adUrlParams = {
      url: encodeURIComponent(window.location.href),
      adEnv: this.accessTypeAvod ? this.config.ad_env_avod : this.config.ad_env,
      description: encodeURIComponent(window.location.href),
      videoId: this.videoId,
      cmsId: this.config.cmsid,
      correlator: Math.floor((Math.random() * 10000) + 1),
      gdfp: '1',
    };

    // Google IMA Payload
    const options = {
      adTagUrl: `https://pubads.g.doubleclick.net/gampad/ads?iu=/21925627211/${adUrlParams.adEnv}&tfcd=0&npa=0&sz=640x480%7C400x300&ciu_szs=1x1&gdfp_req=${adUrlParams.gdfp}&output=vmap&unviewed_position_start=1&env=vp&vpmute=0&url=${adUrlParams.url}&description_url=${adUrlParams.description}&vid=${adUrlParams.videoId}&cmsid=${adUrlParams.cmsId}&impl=s&correlator=${adUrlParams.correlator}&ad_rule=1`,
      debug: this.config.debug,
      // VAST load/request timeout (ms) for a single VAST wrapper. Default 5000.
      vastLoadTimeout: 20000,

      // Google IMA AdsRenderingSettings
      // https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdsRenderingSettings
      adsRenderingSettings: {
        // Timeout (ms) to load a single video ad. If exceeded, the ad playback is
        // canceled and the next ad plays. Default: -1 (for 8000).
        loadVideoTimeout: 30000,
      },

      // Additional settings for contrib-ads plugin.
      // http://videojs.github.io/videojs-contrib-ads/integrator/options.html
      contribAdsSettings: {
        // Max time (ms) to wait in ad mode, before an ad break begins, before
        // ad-mode ends and content resumes. Default 5000.
        timeout: 8000,

        // Override the timeout setting just for preroll ads.
        prerollTimeout: undefined,

        // Override the timeout setting just for postroll ads.
        postrollTimeout: undefined,
      },

      // If adtimeout occurred, prevent ads from starting after content has started.
      preventLateAdStart: false,


      adsManagerLoadedCallback: this.onAdsManagerLoaded.bind(this)
    };

    this.player.on('ads-manager', (response: any) => {
      this.adsManager = response.adsManager;
      const cuePoints = this.adsManager.getCuePoints();
      const midRollCuePoints = cuePoints.filter((cuePoint: any) => this.accessTypeAvod && cuePoint > 0);
      const mappedCuePoints = midRollCuePoints.map((cuePoint: any) => {
        return {
          in: cuePoint,
        };
      });
      this.store.dispatch('videoPlayerCatalog/setAips', mappedCuePoints);
    });

    this.context.videojs.registerPlugin('ads', contribAdsPlugin);
    this.context.videojs.registerPlugin('ima', function(this: any, options: any) {
      // From videojs-ima/src/ima-plugin.js
      this.ima = new ImaPlugin(this, options);
    });

    this.player.ima(options);

    // Ensure poster doesn't flash/display between ad plays
    this.context.eventBus.on('ad-start', () => {
      this.context.player.poster('');
    });

    // Ensure ad's muted state matches player's
    this.context.eventBus.on('volume-changed', (event: Record<string, any>) => {
      const isMuted: boolean = event.detail?.isMuted;

      // From videojs-ima/dist/videojs.ima.es.js
      if (isMuted) {
        this.player.ima.controller.adUi.mute();
        this.player.ima.controller.sdkImpl.mute();
      } else {
        this.player.ima.controller.adUi.unmute();
        this.player.ima.controller.sdkImpl.unmute();
      }
    });

    // Event Bus emitter for the Video Player Ad
    const eventMap = {
      'ads-ad-started': 'ad-start',
      'adserror': 'ad-error',
      'adend': 'ad-completed',
      'adscanceled': 'ad-interrupted',
    } as Record<string, string>;

    this.player.on([
      'ads-ad-started',
      'adserror',
      'adend',
      'adscanceled',

      // Un-mapped player events
      // 'ads-manager',
      // 'ads-loader',
      // 'adplaying',
      // 'ads-request',
    ], (event: Record<string, any>) => {
      const normalizedEvent = eventMap[event.type];
      if (normalizedEvent) {
        return this.context.eventBus.emit(normalizedEvent, event);
      }
    });
  }

  onAdEvent(event: any) {
    if (event.type === 'start') {
      this.onAdStarted(event.getAd());
    }
  }

  onAdStarted(ad: any) {
    this.ad = ad;
    const podInfo = ad.getAdPodInfo();
    // For true[X] IMA integration, the first ad in an ad break points to the interactive ad,
    // everything else are the fallback ad videos, or else non-truex ad videos.
    // So anything not an interactive ad we just let play.
    const isInteractiveAd = ad.getAdSystem() == 'trueX' && podInfo.getAdPosition() == 1;
    if (!isInteractiveAd) { return; }
    const truexParameters = JSON.parse(ad.getTraffickingParametersString());
    let vastConfigUrl = truexParameters.vast_config_url;
    vastConfigUrl = vastConfigUrl && vastConfigUrl.trim();
    if (!vastConfigUrl) { return; }
    if (!vastConfigUrl.startsWith('http')) {
      vastConfigUrl = 'https://' + vastConfigUrl;
    }
    const adObj = new InteractiveAd(vastConfigUrl, this);
    setTimeout(() => adObj.start(), 1); // show the ad "later" to work around hangs/crashes on the PS4

    return true; // ad started
  }

  onAdsManagerLoaded() {
    const imaAdEvent = global.window.google.ima.AdEvent;
    const adEvents = [
      imaAdEvent.Type.STARTED,
    ];
    this.player.ima.addEventListener(adEvents, this.onAdEvent.bind(this));
  }
}
