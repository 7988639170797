const VIDEO_PLAYER_LOCAL_STORAGE_KEYS = {
  selectedSpokenLanguage: 'videoPlayer/selectedSpokenLanguage',
  selectedSubtitleLanguage: 'videoPlayer/selectedSubtitleLanguage',
  selectedQuality: 'videoPlayer/selectedQuality',
  selectedVersion: 'videoPlayer/selectedVersion',
  selectedVolume: 'videoPlayer/selectedVolume',
};

const VIDEO_PLAYER_VUEX_MUTATIONS: Record<string, any> = {
  selectedSpokenLanguage: 'SET_SELECTED_SPOKEN_LANGUAGE',
  selectedSubtitleLanguage: 'SET_SELECTED_SUBTITLE_LANGUAGE',
  selectedQuality: 'SET_SELECTED_QUALITY',
  selectedVersion: 'SET_SELECTED_VERSION',
  selectedVolume: 'SET_SELECTED_VOLUME',
};

export const SESSION_ID_LOCAL_STORAGE_KEY = 'userInfo/sessionId';

export function saveVideoPlayerSettingsToLocalStorage(context: Record<string, any>) {
  for (const [ vuexStateKey, localStorageKey ] of Object.entries(VIDEO_PLAYER_LOCAL_STORAGE_KEYS)) {
    window.localStorage.setItem(localStorageKey, context.state[vuexStateKey]);
  }
}
export function getInitialVideoPlayerSettings(context: Record<string, any>) {
  for (const [ vuexStateKey, localStorageKey ] of Object.entries(VIDEO_PLAYER_LOCAL_STORAGE_KEYS)) {
    const localStorageValue = window.localStorage.getItem(localStorageKey);
    let myAccountValue;
    if (vuexStateKey === 'selectedSpokenLanguage') {
      myAccountValue = context.state.userInfo?.userData?.profileData?.audioPreference;
    } else if (vuexStateKey === 'selectedQuality') {
      myAccountValue = context.state.userInfo?.userData?.profileData?.qualityPreference;
    }

    if (!localStorageValue && !myAccountValue) {
      continue;
    }

    const mutation = VIDEO_PLAYER_VUEX_MUTATIONS[vuexStateKey];
    const valueToUse = localStorageValue ?? myAccountValue;
    context.commit(mutation, valueToUse);
  }
}
