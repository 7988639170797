import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';
import index from '../views/index.vue';
import BottomSheet from '../views/BottomSheet.vue';
import BrandColors from '../views/BrandColors.vue';
import Buttons from '../views/Buttons.vue';
import ContentCarousel from '../views/ContentCarousel.vue';
import ContentHero from '../views/ContentHero.vue';
import EpisodeCards from '../views/EpisodeCards.vue';
import ContentFaq from '../views/ContentFaq.vue';
import GlobalFooter from '../views/GlobalFooter.vue';
import GlobalHeader from '../views/GlobalHeader.vue';
import TabLayout from '../views/TabLayout.vue';
import FilterSort from '../views/FilterSort.vue';
import VideoPlayerCore from '../views/VideoPlayerCore.vue';
import VideoPlayer from '../views/VideoPlayer.vue';
import VideoPlayerCatalog from '../views/VideoPlayerCatalog.vue';
import VideoPlayerControls from '../views/VideoPlayerControls.vue';
import VideoPlayerEndScreen from '../views/VideoPlayerEndScreen.vue';
import VideoPlayerMetaOverlay from '../views/VideoPlayerMetaOverlay.vue';
import VideoPlayerModal from '../views/VideoPlayerModal.vue';
import VideoPlayerCoWatching from '../views/VideoPlayerCoWatching.vue';
import NotFoundPage from '../views/NotFoundPage.vue';
import GoogleAuth from '../views/GoogleAuth.vue';
import WatchHistory from '../views/WatchHistory.vue';
import OutOfTerritory from '../views/OutOfTerritory.vue';
import RemoteConfig from '../views/RemoteConfig.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: index,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/abtest',
    name: 'AbTest',
    component: () => import('../views/AbTest.vue'),
  },
  {
    path: '/bottom-sheet',
    name: 'BottomSheet,',
    component: BottomSheet,
  },
  {
    path: '/brand-colors',
    name: 'BrandColors',
    component: BrandColors,
  },
  {
    path: '/buttons',
    name: 'Buttons',
    component: Buttons,
  },
  {
    path: '/content-carousel',
    name: 'ContentCarousel',
    component: ContentCarousel,
  },
  {
    path: '/content-hero',
    name: 'ContentHero',
    component: ContentHero,
  },
  {
    path: '/episode-cards',
    name: 'EpisodeCards',
    component: EpisodeCards,
  },
  {
    path: '/content-faq',
    name: 'ContentFaq',
    component: ContentFaq,
  },
  {
    path: '/global-footer',
    name: 'GlobalFooter',
    component: GlobalFooter,
  },
  {
    path: '/global-header',
    name: 'GlobalHeader',
    component: GlobalHeader,
  },
  {
    path: '/tab-layout',
    name: 'TabLayout',
    component: TabLayout,
  },
  {
    name: 'filter-sort',
    path: '/filter-sort',
    component: FilterSort,
  },
  {
    path: '/video-player-core',
    name: 'VideoPlayerCore',
    component: VideoPlayerCore,
  },
  {
    path: '/video-player/:showSlug/:episodeSlug/',
    name: 'VideoPlayer',
    component: VideoPlayer,
  },
  {
    path: '/video-player-meta-overlay/:showSlug/:episodeSlug/',
    name: 'video-player-meta-overlay',
    component: VideoPlayerMetaOverlay,
  },
  {
    path: '/video-player-catalog/:showSlug/:episodeSlug/',
    name: 'VideoPlayerCatalog',
    component: VideoPlayerCatalog,
  },
  {
    path: '/video-player-controls/:showSlug/:episodeSlug/',
    name: 'VideoPlayerControls',
    component: VideoPlayerControls,
  },
  {
    path: '/video-player-end-screen/:showSlug/:episodeSlug/',
    name: 'VideoPlayerEndScreen',
    component: VideoPlayerEndScreen,
  },
  {
    path: '/video-player-modal/:showSlug/:episodeSlug/',
    name: 'VideoPlayerModal',
    component: VideoPlayerModal,
  },
  {
    path: '/google-auth',
    name: 'GoogleAuth',
    component: GoogleAuth,
  },
  {
    path: '/watch-history',
    name: 'WatchHistory',
    component: WatchHistory,
  },
  {
    path: '*',
    name: 'NotFoundPage',
    component: NotFoundPage,
  },
  {
    path: '/out-of-territory',
    name: 'OutOfTerritory',
    component: OutOfTerritory,
  },
  {
    path: '/remote-config',
    name: 'RemoteConfig',
    component: RemoteConfig,
  },
  {
    path: '/video-player-co-watching',
    name: 'VideoPlayerCoWatching',
    component: VideoPlayerCoWatching,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
