import rootConfig from './config/index.json';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import BottomSheet from '../shared/BottomSheet';
import ContentCarousel from '../shared/ContentCarousel';
import ContentDetailsCard from '../shared/ContentDetailsCard';
import ContentDetailsCardGrid from '../shared/ContentDetailsGrid';
import ContentHero from '../shared/ContentHero';
import ContentDetailsTabs from '../shared/ContentDetailsTabs';
import EpisodeCard from '../shared/EpisodeCard';
import EpisodeCardGrid from '../shared/EpisodeCardGrid';
import ContentFaq from '../shared/ContentFaq';
import FilterSortPanel from '../shared/FilterSortPanel';
import Footer from '../shared/Footer';
import Header from '../shared/Header';
import NotFound from '../shared/NotFound';
import PosterCard from '../shared/PosterCard';
import PublicAnnouncementClient from '../shared/PublicAnnouncementClient';
import OutOfTerritory from '../shared/OutOfTerritory';
import ShowDetails from '../shared/ShowDetails';
import UserInfo from '../shared/UserInfo';
import RemoteConfig from '../shared/RemoteConfig';
import SearchResults from '../shared/SearchResults';
import ShowsPosterGrid from '../shared/ShowsPosterGrid';
import VideoPlayer from '../shared/VideoPlayer';
import VideoPlayerCore from '../shared/VideoPlayerCore';
import VideoPlayerAdsGAM from '../shared/VideoPlayerAdsGAM';
import VideoPlayerAnalytics from '../shared/VideoPlayerAnalytics';
import VideoPlayerCatalog from '../shared/VideoPlayerCatalog';
import VideoPlayerThumbnails from '../shared/VideoPlayerThumbnails';
import VideoPlayerControls from '../shared/VideoPlayerControls';
import VideoPlayerEndScreen from '../shared/VideoPlayerEndScreen';
import VideoPlayerMetaOverlay from '../shared/VideoPlayerMetaOverlay';
import VideoPlayerModal from '../shared/VideoPlayerModal';
import VideoPlayerCoWatching from '../shared/VideoPlayerCoWatching';
import DynamicImage from '../shared/DynamicImage';
import GoogleAuth from '../shared/GoogleAuth';
import WatchHistory from '../shared/WatchHistory';
import { sync } from 'vuex-router-sync';
import { mapConfig, getFirebaseConfig } from '../src/config/helpers';
import VideoPlayerDemoWrapper from './components/VideoPlayerDemoWrapper.vue';
import TemplateModule from '../shared/_TemplateRename';
import VideoPlayerSkipCues from '../shared/VideoPlayerSkipCues';

const env = ({
  'www.funimation.com': 'prd',
  'www-v2.funimation.com': 'stg',
  'qa-www.funimationtest.com': 'qa',
  'uat-www.funimationtest.com': 'dev',
} as Record<string, string>)[window.location.host as string] || 'prd';

const firebaseConfig = getFirebaseConfig(env, rootConfig);

const config = mapConfig(env, rootConfig);

declare global {
  interface Window {
    app: any;
    initVueApp: () => Promise<void>;
    locale: string;
    region: string;
    defaultShow: string;
    dataLayer: Array<Record<string, any>>;
  }
}

const isDeveloper = window.localStorage.getItem('isDeveloper') === 'true' ? 1 : 0;
const isQA = window.localStorage.getItem('isQA') === 'true' ? 1 : 0;

const trackUser = () => {
  store.state.config.analytics.logEvent('screen_view', {
    'page_location': store.state.route.fullPath,
    'page_path': store.state.route.path,
    'page_title': store.state.route.name,
  });

  if (!store.getters.isLoggedIn) {
    return;
  }
};

// TODO: Move setLanguage to ./i18n.ts
const setLanguage = (value: any) => {
  const esRegions = [
    'MX',
    'CL',
    'PE',
    'CO'
  ];
  if (esRegions.includes(value)) {
    i18n.locale = 'es';
  } else if (value === 'BR') {
    i18n.locale = 'pt';
  }
  else {
    i18n.locale = 'en';
  }
  window.locale = i18n.locale;
  const html = window.document.querySelector('html');
  if (html) {
    html.setAttribute('lang', i18n.locale);
  }
};
// TODO: Move i18n.missing to ./i18n.ts
i18n.missing = (locale, key) => {
  console.error(`i18n key missing in ${locale} for ${key}`);
};

const initVueApp = async () => {
  if (!window.region) {
    return;
  }
  // init firebase
  const remoteConfig: any = await RemoteConfig(store, firebaseConfig);

  const titleService = config.service_title;
  const searchService = config.service_search;
  const venueService = config.service_venue;
  const pinService = config.service_pin;
  const videoStreamTrackerBaseUrl = config.video_stream_tracker_service;
  const projectorService = config.projectorService;
  const geoService = config.service_geo;
  const catalogProjectorServiceBase = config.catalog_projector_service;
  const playbackServiceBase = config.playback_service;
  const playlistServiceBase = config.playlist_service;
  const publicAnnouncementService = config.service_public_announcement;
  const videoCuesService = config.video_cues_service;

  // setup modules
  sync(store, router);
  Vue.use(remoteConfig);
  Vue.use(BottomSheet);
  Vue.use(ContentCarousel);
  Vue.use(ContentDetailsCard);
  Vue.use(ContentDetailsCardGrid);
  Vue.use(ContentDetailsTabs);
  Vue.use(ContentHero);
  Vue.use(EpisodeCard);
  Vue.use(EpisodeCardGrid);
  Vue.use(ContentFaq);
  Vue.use(FilterSortPanel(store));
  Vue.use(ShowsPosterGrid(store));
  Vue.use(Footer);
  Vue.use(NotFound);
  Vue.use(PosterCard);
  Vue.use(OutOfTerritory);
  Vue.use(DynamicImage);
  Vue.use(ShowDetails(store, {
    service: titleService,
    projectorService: projectorService,
  }));
  Vue.use(SearchResults(store, {
    service: searchService,
  }));
  Vue.use(UserInfo(store, {
    venueService,
    geoService,
    pinService,
  }));
  Vue.use(Header(store, {
    service: venueService,
  }));
  if (window.location.hostname.includes('funimation.com')) {
    Vue.use(GoogleAuth(store, {
      clientConfig: {
        'hosted_domain': 'funimation.com',
      },
      clientId: '788734194048-uv1svts1st5em9lb13834mapbeddjd4l.apps.googleusercontent.com',
    }));
  }
  Vue.use(new PublicAnnouncementClient(publicAnnouncementService, store));
  Vue.use(VideoPlayer(store));
  Vue.use(VideoPlayerCore(store));
  Vue.use(VideoPlayerCatalog(store, {
    titleServiceBase: titleService,
    catalogProjectorServiceBase,
    playbackServiceBase,
    playlistServiceBase
  }));
  Vue.use(VideoPlayerControls, { store });
  Vue.use(VideoPlayerThumbnails, { store });
  Vue.use(WatchHistory(store, {
    videoStreamTrackerBaseUrl,
  }));
  Vue.use(VideoPlayerEndScreen(store));
  Vue.use(VideoPlayerMetaOverlay(store));
  Vue.use(VideoPlayerModal(store));
  Vue.use(VideoPlayerCoWatching);
  Vue.use(VideoPlayerAdsGAM(store, config.gam_settings));
  Vue.use(VideoPlayerAnalytics(store));
  Vue.use(
    VideoPlayerSkipCues,
    {
      store,
      videoCuesService
    }
  );
  Vue.use(
    TemplateModule,
    {
      store,
      someConfigProp: 'SOME VALUE'
    }
  );

  Vue.component('VideoPlayerDemoWrapper', VideoPlayerDemoWrapper);

  // track user
  store.state.config.analytics.setUserProperties({
    'is_developer': isDeveloper,
    'is_qa': isQA,
  });
  trackUser();
  store.watch((state: any) => state.userInfo, () => trackUser, { deep: true });
  store.watch((state: any) => state.route, () => trackUser, { deep: true });
  setLanguage(window.region);
  Vue.config.productionTip = false;
  const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app');
  (window as any)['funi-build'] = {
    locale: i18n.locale,
    isDeveloper,
    isQA,
    app,
    env,
    config,
    stage: process.env.VUE_APP_STAGE,
    trigger: process.env.VUE_APP_TRIGGER,
    build: process.env.VUE_APP_BUILD,
  };
  // for e2e test, we make the app available on the window
  window.app = app;
};

window.initVueApp = initVueApp;

const mountPoint: any = document.querySelector('#app');
if (mountPoint) {
  const isVueMounted = mountPoint.__vue__;
  if (!isVueMounted && window.region) {
    initVueApp();
  }
}
