import Vue from 'vue';
import FilterSortPanel from './FilterSortPanel.vue';
let clearSearchTimer = 0;
export const defaultLanguages = [
  { languageCode: 'en', name: 'English' },
  { languageCode: 'ja', name: 'Japanese' },
  { languageCode: 'es', name: 'Spanish (Latin Am)' },
  { languageCode: 'pt', name: 'Portuguese (Brazil)' },
  { languageCode: 'ko', name: 'Korean' },
  { languageCode: 'zh_MN', name: 'Chinese (Mandarin, PRC)' },
  { languageCode: 'zh_CT', name: 'Chinese (Cantonese)' },
];
import defaultRatings from './ratings';

export const defaultVersions = [
  'Simulcast',
  'Uncut',
];
export class FilterSort {
  state: any;
  constructor(state: any) {
    this.state = state;
  }
  hasLanguage(): any {
    return (languageCodes: string[], episode: any) => {
      return !!episode.videoOptions.audioLanguages[window.region]?.all.find((lang: any) => {
        const hasLang = languageCodes.indexOf(lang.languageCode) !== -1;
        if (hasLang) {
          return true;
        }
        return false;
      });
    };
  }
  filterByLanguage(): any {
    return (episode: any) => {
      if (this.state.language.length === 0) {
        return true;
      }
      if (
        this.hasLanguage()(
          this.state.language,
          episode
        )
      ) {
        return true;
      }
      return false;
    };
  }
  filterByVersions(): any {
    return (episode: any): boolean =>  {
      if (this.state.version.length === 0) {
        return true;
      }
      return episode.videoOptions.versions.some((ver: string) => this.state.version.includes(ver));
    };
  }
  sortSequential(a: any, b: any): number {
    const n = (e: any): number =>
      e.sequence;
    return n(a) > n(b) ? 1 : -1;
  }
  sortDateAdded(a: any, b: any): number {
    return a.releaseDate > b.releaseDate ? -1 : 1;
  }
  sortAlphabetical(a: any, b: any, locale: string): number {
    if (typeof a.name === 'string') {
      return a.name.localeCompare(b.name);
    }
    return  a.name[locale].localeCompare(b.name[locale]);
  }
}
export const setupStore = (context: any) => {
  async function search() {
    const s = context.state.filterSort;
    await context.dispatch('fetchSearchResults', {
      region: window.region,
      index: 'catalog-shows',
      searchParams: {
        offset: s.offset,
        limit: s.limit,
        facet: {
          versions: s.version,
          genres: s.genre,
          languages: s.language,
          ratings: s.rating,
        },
        lang: s.language.join(','),
        sortBy: s.sortBy,
        sortDirection: s.sortBy === 'title' ? 'asc' : s.sortDirection,
      },
    });
  }
  const filterSortStore = {
    state: {
      limit: 25,
      stateVersion: 0,
      offset: 0,
      panel: false,
      mode: 'local',
      sortBy: 'title',
      sortDirection: 'desc',
      versions: [],
      languages: [],
      ratings: [],
      genres: [
        'Action/Adventure',
        'Comedy',
        'Drama',
        'Fan Service',
        'Fantasy',
        'Horror',
        'Live-Action',
        'Psychological',
        'Romance',
        'Sci Fi',
        'Shoujo',
        'Shounen',
        'Slice of Life',
      ],
      genre: [],
      language: [],
      rating: [],
      version: [],
    },
    actions: {
      setFilterSortPanelState(context: any, payload: boolean): void {
        context.commit('SET_FILTER_SORT_PANEL_STATE', payload);
      },
      nextSearchPage(context: any): void {
        context.commit('SET_OFFSET', context.state.limit + context.state.offset);
        search();
      },
      async setFilterContext(context: any, payload: any): Promise<void> {
        // when there is NO show data passed in to create filter context
        // create the default filter context (for search vs. episode)
        if (!payload) {
          context.commit('SET_LANGUAGES', defaultLanguages);
          context.commit('SET_VERSIONS', defaultVersions);
          context.commit('SET_RATINGS', defaultRatings);
          context.commit('SET_MODE', 'api');
          context.commit('SET_FILTER_SORTED_BY', 'title');
          search();
          return;
        }
        context.commit('SET_MODE', 'local');
        context.commit('SET_FILTER_SORTED_BY', 'sequential');
        // NOTE: More declarative mapping isn't practical due to inconsistent schema
        const versions = [] as any[];
        const languages = [] as any[];

        payload.content.forEach((episode: any): void => {
          if (episode.videoOptions.versions) {
            episode.videoOptions.versions.forEach((version: any) => {
              if (versions.indexOf(version) === -1) {
                versions.push(version);
              }
            });
          }
          const addLang = (lang: any) => {
            if (
              languages.find((l: any) => {
                return lang.languageCode === l.languageCode;
              })
            ) {
              return;
            }
            languages.push(lang);
          };
          episode.videoOptions.audioLanguages[window.region]?.all.forEach(addLang);
          episode.videoOptions.subtitleLanguages[window.region]?.all.forEach(addLang);
        });
        context.commit('SET_LANGUAGES', languages);
        context.commit('SET_VERSIONS', versions);
      },
    },
    getters: {
      filter(state: any): any {
        return (episode: any) => {
          const filterSort = new FilterSort(state);
          const filterByLanguage = filterSort.filterByLanguage();
          const filterByVersions = filterSort.filterByVersions();
          return filterByLanguage(episode)
            && filterByVersions(episode);
        };
      },
      sort(state: any): any {
        return (a: any, b: any) => {
          const filterSort = new FilterSort(state);
          if (state.sortBy === 'sequential') {
            return filterSort.sortSequential(a, b);
          }
          if (state.sortBy === 'title') {
            return filterSort.sortAlphabetical(a, b, context.state.userInfo.userLanguage);
          }
          if (state.sortBy === 'latestAvail') {
            return filterSort.sortDateAdded(a, b);
          }
        };
      },
    },
    mutations: {
      CLEAR_FILTERS(state: any): void {
        state.sortBy = context.state.filterSort.mode === 'api' ? 'title' : 'sequential';
        state.genre = [];
        state.language = [];
        state.rating = [];
        state.version = [];
        state.stateVersion += 1;
      },
      SET_FILTER_SORT_PANEL_STATE(state: any, e: boolean): void {
        state.panel = e;
        state.stateVersion += 1;
      },
      SET_FILTER_SORTED_BY(state: any, e: any): void {
        state.sortBy = e;
        state.stateVersion += 1;
      },
      SET_FILTER_GENRE(state: any, e: any): void {
        state.genre = e;
        state.stateVersion += 1;
      },
      SET_FILTER_LANGUAGE(state: any, e: any): void {
        state.language = e;
        state.stateVersion += 1;
      },
      SET_FILTER_RATING(state: any, e: any): void {
        state.rating = e;
        state.stateVersion += 1;
      },
      SET_FILTER_VERSION(state: any, e: any): void {
        state.version = e;
        state.stateVersion += 1;
      },
      SET_LANGUAGES(state: any, e: any): void {
        state.languages = e;
        state.stateVersion += 1;
      },
      SET_VERSIONS(state: any, e: any): void {
        state.versions = e;
        state.stateVersion += 1;
      },
      SET_RATINGS(state: any, e: any): void {
        state.ratings = e;
        state.stateVersion += 1;
      },
      SET_OFFSET(state: any, e: any): void {
        state.offset = e;
      },
      SET_MODE(state: any, mode: string): void {
        state.mode = mode;
      },
    },
  };
  const clearSearchState = () => {
    context.commit('SET_OFFSET', 0);
    if (context.state.filterSort.mode !== 'api') {
      return;
    }
    clearTimeout(clearSearchTimer);
    clearSearchTimer = setTimeout(() => {
      context.commit('CLEAR_SEARCH');
      search();
    }, 0) as any;
  };
  const deep = {
    deep: true,
  };
  context.registerModule('filterSort', filterSortStore);
  // watch for changes on key properties then reset the search
  Vue.nextTick(() => {
    [
      'genre',
      'language',
      'rating',
      'version',
      'sortBy',
    ].forEach((i: string): void =>{
      context.watch((state: any) => state.filterSort[i], clearSearchState, deep);
    });
  });
};
export default (context: any) => {
  setupStore(context);
  return {
    install(Vue: any): void {
      Vue.component('filter-sort-panel', FilterSortPanel);
    },
  };
};
