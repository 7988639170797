





























import Vue from 'vue';
import {mapActions} from 'vuex';
export default Vue.extend({
  name: 'example',
  data(): any  {
    return {
      chatInput: '',
      messages: [],
    };
  },
  async mounted(): Promise<void> {
    // gota connect to the web socket.
    // awaiting here helps you know when it's safe to send messages
    await this.connect();
    // you can subscribe to any channel and create callbacks
    // for when messages come in on that channel.
    await this.subscribe({
      channelId: 'test-channel',
      callback: (message: any) => {
        this.messages.unshift(message);
      },
    });
    // you can only send messages to channels you're subscribed
    // to on the client.  It'll still work otherwise, but you'll get
    // a timeout on the client as it expects an echo to confirm it worked.
    this.sendToChannel({
      channelId: 'test-channel',
      value: 'Hello World',
    });
  },
  methods: {
    ...mapActions([
      'connect',
      'subscribe',
      'sendToChannel',
    ]),
    sendMessage(): void {
      // you can send a message at any time to any channel.
      this.sendToChannel({
        channelId: 'test-channel',
        value: this.chatInput,
      });
      this.chatInput = '';
    },
  },
});
