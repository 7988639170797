import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: null,
    errors: [],
  },
  actions: {
    dismissError(context: any) {
      context.commit('DISMISS_ERROR');
    },
    error(context: any, payload: Error) {
      context.commit('ERROR', payload);
    },
  },
  mutations: {
    DISMISS_ERROR(state: any) {
      state.error = null;
    },
    ERROR(state: any, payload: Error) {
      state.error = payload;
      console.error(payload);
      state.errors.push(payload);
    },
    SET_PAGE_NOT_FOUND(state: Record<string, any>, payload: boolean) {
      state.is404 = payload;
    },
    SET_REGION_RESTRICTION(state: Record<string, any>, payload: boolean) {
      state.is403 = payload;
    },
  },
}) as any;
