






import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';


const URL_BASE_REGEX = /(?:http[s]?:\/\/)?(?:[^?/\s]+[?/])(.*)/;

export default Vue.extend({
  name: 'video-player-thumbnails-overlay',
  data() {
    return {
      bindTimer: 0 as any,
      staleTimer: 0 as any,
      timePct: 0,
      selTime: 0,
      visible: false,
      progressEl: null as any,
      images: {} as Record<string, any>,
      x: -500,
    };
  },
  mounted() {
    this.bindEvent();
    this.preloadThumbnails();
  },
  watch: {
    timeTotal() {
      if (!this.timeTotal) {
        return;
      }
      // FIXME: On iOS, timeTotal updates before and after each ad, causing
      // unnecessary preloadThumbnails() calls. A fix could be to also check
      // player.ads.inAdBreak() or player.ads.isInAdMode().
      this.preloadThumbnails();
    },
  },
  methods: {
    bindEvent(): void {
      // try to find the progress bar
      const el = document.querySelector('.video-player-controls__progress-bar');
      if (!el) {
        clearTimeout(this.bindTimer);
        this.bindTimer = setTimeout(() => {
          this.bindEvent();
        }, 100);
        return;
      }
      this.progressEl = el;
      el.removeEventListener('mousemove', this.mouseMove);
      el.addEventListener('mousemove', this.mouseMove);
      el.removeEventListener('mouseover', this.mouseOver);
      el.addEventListener('mouseover', this.mouseOver);
      el.removeEventListener('mouseout', this.mouseOut);
      el.addEventListener('mouseout', this.mouseOut);
    },
    pollStaleTimer() {
      clearTimeout(this.staleTimer);
      this.staleTimer = setTimeout(() => {
        this.visible = false;
      }, 2000);
    },
    mouseOver(): void {
      this.visible = true;
      this.pollStaleTimer();
    },
    mouseOut(ev: any): void {
      if (this.progressEl.contains(ev.relatedTarget) && ev.type !== 'touchend') {
        return;
      }
      this.visible = false;
    },
    mouseMove(ev: any): void {
      if (!this.progressEl.contains(ev.target)) {
        return;
      }
      this.pollStaleTimer();
      // offset that makes thumbs line up better
      const magicNumber = 0.0005;
      const x = ev.x;
      this.x = Math.min(Math.max(170, x), this.progressEl.offsetWidth - 110);
      this.timePct = (x - magicNumber - this.progressEl.offsetLeft) / this.progressEl.offsetWidth;
      this.$el.innerHTML = '';
      for (let x = 0; x < 3; x += 1) {
        const hoverTime = (this.timeTotal * this.timePct) + (x * 10) - 30;
        if (x === 1) {
          this.selTime = hoverTime;
        }
        const p = this.images[this.getFileTimeAt(hoverTime)];
        if (!p || !p.loaded) {
          continue;
        }
        this.$el.appendChild(p);
      }
    },
    getFileTimeAt(time: number): string {
      // FIXME: 00000.jpg will never exist. Causes HTTP 403 error.
      const divTime = '0000' + Math.floor(time / 10);
      const fileTime  = divTime.substr(divTime.length - 5);
      return `${this.urlPrefix}_thumb_${fileTime}.jpg`;
    },
    preloadThumbnails(): void {
      for (let x = 0; x < this.timeTotal; x += 10) {
        const thumbnail = new Image();
        const id = this.getFileTimeAt(x);
        thumbnail.src = id;
        this.images[id] = thumbnail;
        this.images[id].onload = () => {
          this.images[id].loaded = true;
        };
      }
    },
  },
  computed: {
    ...mapGetters({
      selectedVideoManifest: 'videoPlayerCatalog/selectedVideoManifest',
      selectedVideoManifestFileExt: 'videoPlayerCatalog/selectedVideoManifestFileExt',
      catalogProjectorBase: 'videoPlayerCatalog/catalogProjectorBase',
    }),
    ...mapState({
      timeTotal: (state: any) => state.videoPlayerCore.timeTotal,
    }),
    formattedHoverTime(): string {
      return new Date(1000 * this.selTime)
        .toISOString()
        .substr(11, 8)
        .replace(/^00:(.*)$/, '$1');
    },
    overlayStyle(): Record<string, any> {
      return {
        opacity: (this.visible && this.thumb) ? 1 : 0,
        left: 0,
        width: '100%',
      };
    },
    urlPrefix(): string | undefined {
      if (!this.selectedVideoManifest || !this.selectedVideoManifestFileExt || !this.catalogProjectorBase) {
        return;
      }

      const manifestPath = (this.selectedVideoManifest.match(URL_BASE_REGEX) ?? [])[1];
      if (!manifestPath) {
        return;
      }

      const manifestReplacedWithProjector = `${this.catalogProjectorBase}/${manifestPath}`;

      return manifestReplacedWithProjector.split(`.${this.selectedVideoManifestFileExt}`)[0];
    },
    thumb(): string {
      const hoverTime = this.timeTotal * this.timePct;
      return this.getFileTimeAt(hoverTime);
    },
  },
});
