export const enHeader: any = {
  0: {
    'title':'My Anime',
    'to':'/account/#queue',
    'subscriber': true,
    'subNavItems':[
      {
        'title':'Watch List',
        'to':'/account/#queue',
        'subscriber': true
      },
      {
        'title':'Digital Copy',
        'to':'/account/#mylibrary',
        'subscriber': true
      }
    ]
  },
  1: {
    'title':'Stream',
    'to':'/shows/',
    'subNavItems':[
      {
        'title':'All Titles',
        'to':'/shows/all-shows/'
      },
      {
        'title':'Current Season',
        'to':'/simuldubs/'
      },
      {
        'title':'Schedule',
        'to':'/schedule/'
      }
    ]
  },
  2: {
    'title':'Discover',
    'to':'/discover/',
    'subNavItems':[
      {
        'title':'Games',
        'to':'/games/'
      },
      {
        'title':'News',
        'to':'/blog/'
      }
    ]
  },
};
