




import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
    };
  },
  created() {
    // this.$store.dispatch('videoPlayerModal/dispatchTechnicalError', undefined, { root: true });
    // this.$store.dispatch('videoPlayerModal/dispatchDigitalCopyError', undefined, { root: true });
    // this.$store.dispatch('videoPlayerModal/dispatchPaywallError', undefined, { root: true });
    this.$store.dispatch('videoPlayerModal/dispatchMatureContentError', undefined, { root: true });
  },
});
