





































































































import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';

import { compareStrings } from '@funimation/comp-utils';

export default Vue.extend({
  name: 'episode-card',
  data() {
    return {
      contentCard: this.$t('contentCard'),
      showId: null as number | null,
    };
  },
  props: {
    episode: Object,
    episodeLoading: Boolean,
    isExtras: Boolean,
  },
  computed: {
    ...mapState({
      show: (state: any) => state.showDetails.show,
      userData: (state: any) => state.userInfo.userData,
      userLanguage: (state: any) => state.userInfo.userLanguage,
      region: (state: any) => state.userInfo.region,
    }),
    ...mapGetters([ 'isLoggedIn', 'isSubscriber', 'episodeLanguages', 'playerUrl', 'userEntitlements' ]),
    locale(): string {
      return window.locale;
    },
    progressBar(): number {
      if (!this.episode.checkpoint) {
        return 0;
      }
      return (this.episode.checkpoint / this.episode.duration) * 100;
    },
    digitalCopyLanguages(): string[] {
      const languages: string[] = [];
      for (const key of Object.keys(this.episode.videoDetail)) {
        const video = this.episode.videoDetail[key];
        if (this.userEntitlements.includes(video.videoId) && !languages.includes(video?.audioLanguages[0])) {
          languages.push(video.audioLanguages[0]);
        }
      }
      return languages;
    },
    showSubscriptionBanner(): boolean {
      const loggedOut = !this.isLoggedIn && this.episode.isSubRequired;
      const notSubscribed =
        this.isLoggedIn && this.episode.isSubRequired && !this.isSubscriber && !this.digitalCopyLanguages.length;
      return loggedOut || notSubscribed;
    },
    rating(): string {
      if (!Array.isArray(this.episode.ratings) || this.episode.ratings.length < 1) {
        return '';
      }

      const matchingRating = this.episode.ratings.find((rating: Record<string, any>) => {
        return compareStrings(rating.region, this.region);
      });

      return matchingRating?.ratingCode || '';
    },
    keyArt(): Record<string, string> {
      const defaultImg = {
        key: 'default',
        path: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==',
      };
      if (!this.episode.images || this.episode.images.length < 1) {
        return defaultImg;
      }
      const keyArt = this.episode.images.find((x: any) => {
        return x.key === 'Episode Thumbnail';
      });
      if (!keyArt) {
        return defaultImg;
      }
      return keyArt;
    },
    formatedTime(): string {
      if (!this.episode.duration) {
        return '';
      }
      const date = new Date(0);
      date.setSeconds(this.episode.duration);
      const timeString = date.toISOString().substr(11, 8).replace(/^00:|^0/, '');
      return timeString;
    },
    episodeType(): string {
      const key = this.episode.type.toLowerCase();
      const translations = this.contentCard as Record<string, any>;
      return translations[key] || '';
    },
    regionLocale(): string {
      let regionLocale = 'en';
      const enLocales = [ 'CA', 'GB', 'IE', 'AU', 'NZ', 'US' ];
      const region = window.region;
      if ( region && !enLocales.includes(region)) {
        regionLocale = `${this.userLanguage}-${region.toLowerCase()}`;
      }
      return regionLocale;
    },
    episodeName(): string {
      return this.episode.name[this.userLanguage];
    },
    isMovie(): boolean {
      return this.episode.type === 'movie';
    }
  },
  methods: {
    ...mapActions([ 'fetchShowDetailsBySlug' ]),
    hasDigitalCopy(language: any): boolean {
      return this.digitalCopyLanguages.includes(language.languageCode) && this.isLoggedIn;
    }
  },
});
