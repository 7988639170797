const WSS_TIMEOUT = 5000;
export function newId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line 
    return v.toString(16);
  });
}
export default class WSSDataProvider {
  asyncUpdate: boolean;
  wssUrl: string;
  keepOpen: boolean;
  store: any;
  chunks: any;
  webSocket: WebSocket;
  token = '';
  state: string;
  messages: any[];
  message: (e: any, w: WSSDataProvider) => void;
  open: (e: WSSDataProvider) => void;
  close: (e: WSSDataProvider) => void;
  events: any;
  pendingSubscriptions: {[key: string]: any};
  subscriptions: string[];
  constructor(wssUrl: string,
    message: (e: any, f: WSSDataProvider) => void,
    open: (e: WSSDataProvider) => void,
    close: (e: WSSDataProvider) => void) {
    if (!wssUrl) {
      throw new Error('No wssUrl was passed to WSSDataProvider');
    }
    this.asyncUpdate = true;
    this.events = {};
    this.chunks = {};
    this.messages = [];
    this.keepOpen = true;
    this.wssUrl = wssUrl;
    this.message = message;
    this.open = open;
    this.close = close;
    this.subscriptions = [];
    this.pendingSubscriptions = {};
    this.state = 'connecting';
    this.keepOpen = true;
    this.webSocket = new WebSocket(this.wssUrl);
  }
  send(e: any) {
    if (this.state !== 'open') {
      return this.messages.push(e);
    }
    const value = JSON.stringify(e);
    this.webSocket.send(value);
  }
  connect(callback: ((e: any) => void) | void) {
    if (this.state === 'closed') {
      this.state = 'connecting';
      this.webSocket = new WebSocket(this.wssUrl);
    }
    this.webSocket.addEventListener('open', () => {
      this.state = 'open';
      this.open(this);
      if (callback) {
        callback(this);
      }
      while (this.messages.length > 0) {
        this.send(this.messages.shift());
      }
      this.subscriptions.forEach((channelId) => {
        this.subscribe(channelId, null);
      });
    });
    this.webSocket.addEventListener('close', () => {
      this.state = 'closed';
      this.close(this);
      if (this.keepOpen) {
        this.connect();
      }
    });
    this.webSocket.addEventListener('message', (e) => {
      const val = JSON.parse(e.data);
      this.messageHandler(val);
    });
  }
  messageHandler(e: any) {
    if (e.chunkCollectionId) {
      this.chunks[e.chunkCollectionId] = this.chunks[e.chunkCollectionId] || [];
      this.chunks[e.chunkCollectionId].push(e);
      if (this.chunks[e.chunkCollectionId].length === e.parts) {
        const msg: any[] = [];
        this.chunks[e.chunkCollectionId].sort((a: any, b: any) => {
          return a.part - b.part;
        }).forEach((chunk: any) => {
          msg.push(chunk.value);
        });
        this.messageHandler(JSON.parse(msg.join('')));
      }
      return;
    }
    if (e.unsubscribed) {
      const idx = this.subscriptions.indexOf(e.unsubscribed);
      if (idx !== -1) {
        this.subscriptions.splice(idx, 1);
      }
    }
    if (e.messageId && typeof this.events[e.messageId] === 'function') {
      this.events[e.messageId](e.response);
    }
    if (e.response
      && e.response.id
      && typeof this.events[e.response.id] === 'function') {
      this.events[e.response.id](e.response);
    }
    if (e.subscribed && this.subscriptions.indexOf(e.subscribed) === -1) {
      this.subscriptions.push(e.subscribed);
    }
    if (this.pendingSubscriptions[e.subscribed]) {
      this.pendingSubscriptions[e.subscribed](e);
      this.pendingSubscriptions[e.subscribed] = undefined;
    }
    if (e.channelId) {
      if (this.events[e.channelId]) {
        this.events[e.channelId].forEach((listener: any) => {
          listener(e.response);
        });
      }
    }
    this.message(e, this);
  }
  disconnect() {
    this.keepOpen = false;
    this.webSocket.close();
  }
  subscribe(channelId: string, listener: ((e: any) => void) | null): Promise<any> {
    return new Promise((resolve, reject) => {
      if (listener) {
        if (!this.events[channelId]) {
          this.events[channelId] = [];
        }
        this.events[channelId].push(listener);
      }
      if (this.pendingSubscriptions[channelId]) {
        console.info(`subscribe: subscription for channelId ${channelId} already pending.`); // eslint-disable-line
      }
      this.send({
        action: 'v1/subscribe',
        channelId,
      });
      const timer = setTimeout(() => {
        console.error(`subscribe: channelId: ${channelId}.  Response from server timed out.`);
        reject();
      }, WSS_TIMEOUT);
      this.pendingSubscriptions[channelId] = (e: any) => {
        clearTimeout(timer);
        resolve(e);
      };
    });
  }
  unsubscribe(channelId: string, listener: (e: any) => void) {
    this.send({
      action: 'v1/unsubscribe',
      channelId,
    });
    if (!this.events[channelId]) {
      return;
    }
    const idx = this.events[channelId].indexOf(listener);
    if (idx === -1) {
      return;
    }
    this.events[channelId].splice(idx, 1);
  }
  sendToAll(value: any) {
    this.send({
      action: 'v1/sendToAll',
      value,
    });
  }
  sendToChannel(channelId: string, e: any) {
    this.send({
      action: 'v1/sendToChannel',
      value: e.value,
      channelId: e.channelId,
    });
  }
  sendToConnection(connectionId: string, e: any) {
    this.send({
      action: 'v1/sendToConnection',
      value: e.value,
      connectionId: e.connectionId,
    });
  }
}
