/* istanbul ignore file */
import VideoPlayerControls from './VideoPlayerControls.vue';
import BigPlayButton from './BigPlayButton/index.vue';
import VideoAuxBtn from './VideoAuxBtn/VideoAuxBtn.vue';
import VideoPlayerMainControls from './VideoPlayerMainControls/VideoPlayerMainControls.vue';
import VideoPlayerSettingsPanel from './VideoPlayerSettingsPanel/VideoPlayerSettingsPanel.vue';
import VideoPlayerUpNextPanel from './VideoPlayerUpNextPanel/VideoPlayerUpNextPanel.vue';
import VideoPlayerVersionLanguagePanel from './VideoPlayerVersionLanguagePanel/VideoPlayerVersionLanguagePanel.vue';
import { VueConstructor } from 'vue/types/vue';
import { Store } from 'vuex';

type RootState = Record<string, any>;
type RootStore = Store<RootState>;

function getMountedComponent(
  LocalVue: VueConstructor,
  VueComponent: any,
  options?: any
): any {
  const { store, vuetify, i18n, router } = window.app.$options;

  const component = new LocalVue({
    store,
    vuetify,
    i18n,
    router,
    render: (createElement) => createElement(VueComponent, options),
  });

  // IMPORTANT: Must manually call $destroy()
  component.$mount(/* Virtual Node */);

  return component;
}
export default {
  install(Vue: any, options: any): void {
    Vue.component('big-play-button', BigPlayButton);
    Vue.component('video-aux-btn', VideoAuxBtn);
    Vue.component('video-player-settings-panel', VideoPlayerSettingsPanel);
    Vue.component('video-player-up-next-panel', VideoPlayerUpNextPanel);
    Vue.component('video-player-version-language-panel', VideoPlayerVersionLanguagePanel);
    Vue.component('video-player-main-controls', VideoPlayerMainControls);

    let videoPlayerControls: any;
    let videoPlayerControlsMobile: any;

    function selectedVideo(store: RootStore) {
      return store.getters['videoPlayerCatalog/selectedVideo'];
    }

    options.store.dispatch('videoPlayer/modulesRegister', {
      name: 'video-player-controls',

      install(installContext: any) {
        videoPlayerControls = getMountedComponent(Vue, VideoPlayerControls);
        videoPlayerControlsMobile = getMountedComponent(Vue, VideoPlayerMainControls, {
          props: {
            isMobile: true,
          }
        });

        const player = installContext.player;

        // TODO: Move to VideoPlayer/index.ts
        const continueWatchingFn = () => {
          // Go to beginning of watched videos
          const continueWatching = options.store.getters['videoPlayerCatalog/continueWatching'];
          const episodeDuration = options.store.getters['videoPlayerCatalog/currentEpisodeDuration'];

          if (continueWatching) {
            const pct = continueWatching.checkpoint / episodeDuration;
            player.one('loadedmetadata', () => {
              player.currentTime(pct > 0.949 ? 0 : continueWatching.checkpoint);
            });
          }
        };

        // TODO: Move to VideoPlayer/index.ts
        options.store.watch(
          () => {
            return options.store.getters['videoPlayerCatalog/continueWatching'];
          },
          continueWatchingFn
        );

        // TODO: Move to VideoPlayer/index.ts
        options.store.watch(
          (state: any, getters: any) => {
            return getters.selectedSpokenLanguage;
          },
          continueWatchingFn
        );
        // Show UI for skip credit buttons
        options.store.watch(
          (state: any, getters: any) => {
            return getters['videoPlayerSkipCues/showSkipIntro']
              || getters['videoPlayerSkipCues/showSkipCredits'];
          },
          (showUi: any) => {
            if (showUi) {
              player.el().classList.add('skip-que-point');
            } else {
              player.el().classList.remove('skip-que-point');
            }
          }
        );
        options.store.watch(
          () => selectedVideo(options.store),
          () => {

            // Attach Full Controls
            const controlBar = player.getChild('ControlBar');
            controlBar.el().appendChild(videoPlayerControls.$el);

            // Attach Mobile Controls
            player.el().appendChild(videoPlayerControlsMobile.$el);

            const progressBarContainer = document.querySelector('.video-player-controls__progress-bar');

            if (progressBarContainer) {
              progressBarContainer.append(player.controlBar.progressControl.el());
            }

          }
        );
      },

      destroy() {
        videoPlayerControlsMobile.$destroy();
        videoPlayerControls.$destroy();
      },
    });
  },
};
