// TODO: Move this under shared/HydraIcons and deploy to package
export const iconsMap = {
  // US
  'mpaa': 'hydra-rating-mpaa',
  'mpaa-14': 'hydra-rating-mpaa-14',
  'mpaa-g': 'hydra-rating-mpaa-g',
  'mpaa-m': 'hydra-rating-mpaa-m',
  'mpaa-ma': 'hydra-rating-mpaa-ma',
  'mpaa-nc17': 'hydra-rating-mpaa-nc17',
  'mpaa-pg': 'hydra-rating-mpaa-pg',
  'mpaa-r': 'hydra-rating-mpaa-r',
  'tv-us-tv-14': 'hydra-rating-tv-us-tv-14',
  'us-tv-g': 'hydra-rating-tv-us-tv-g',
  'tv-us-tv-ma': 'hydra-rating-tv-us-tv-ma',
  'tv-us-tv-pg': 'hydra-rating-tv-us-tv-pg',
  'tv-us-14': 'hydra-rating-tv-us-tv-14',
  'tv-us-g': 'hydra-rating-tv-us-tv-g',
  'tv-us-ma': 'hydra-rating-tv-us-tv-ma',
  'tv-us-pg': 'hydra-rating-tv-us-tv-pg',
  // BR
  'djctq-l': 'hydra-rating-djctq-l',
  'djctq-10': 'hydra-rating-djctq-10',
  'djctq-12': 'hydra-rating-djctq-12',
  'djctq-14': 'hydra-rating-djctq-14',
  'djctq-16': 'hydra-rating-djctq-16',
  'djctq-18': 'hydra-rating-djctq-18',
  'crrbr-al': 'hydra-rating-crrbr-al',
  'crrbr-a10': 'hydra-rating-crrbr-a10',
  'crrbr-a12': 'hydra-rating-crrbr-a12',
  'crrbr-a14': 'hydra-rating-crrbr-a14',
  'crrbr-a16': 'hydra-rating-crrbr-a16',
  'crrbr-a18': 'hydra-rating-crrbr-a18',
  // MX, CO, CL, PE
  'rtc-aa': 'hydra-rating-rtc-aa',
  'rtc-a': 'hydra-rating-rtc-a',
  'rtc-b': 'hydra-rating-rtc-b',
  'rtc-b15': 'hydra-rating-rtc-b15',
  'rtc-c': 'hydra-rating-rtc-c',
  'rtc-d': 'hydra-rating-rtc-d',
  'bbfc-12': 'hydra-rating-bbfc-12',
  'bbfc-12a': 'hydra-rating-bbfc-12a',
  'bbfc-15': 'hydra-rating-bbfc-15',
  'bbfc-18': 'hydra-rating-bbfc-18',
  'bbfc-u': 'hydra-rating-bbfc-u',
  'bbfc-pg': 'hydra-rating-bbfc-pg',
  // IE
  'rte-12a': 'hydra-rating-rte-12a',
  'rte-15a': 'hydra-rating-rte-15a',
  'rte-16': 'hydra-rating-rte-16',
  'rte-18': 'hydra-rating-rte-18',
  'rte-g': 'hydra-rating-rte-g',
  'rte-pg': 'hydra-rating-rte-pg',
  'rte-ga': 'hydra-rating-rte-ga',
  'rte-ch': 'hydra-rating-rte-ch',
  'rte-ma': 'hydra-rating-rte-ma',
  'rte-ps': 'hydra-rating-rte-ps',
  // CA
  'cbsc-18a': 'hydra-rating-cbsc-18a',
  'cbsc-14a': 'hydra-rating-cbsc-14a',
  'cbsc-g': 'hydra-rating-cbsc-g',
  'cbsc-pg': 'hydra-rating-cbsc-pg',
  'cbsc-r': 'hydra-rating-cbsc-r',
  'cbsc-a': 'hydra-rating-cbsc-a',
  'cbsc-14': 'hydra-rating-cbsc-14',
  'cbsc-18': 'hydra-rating-cbsc-18',
  // NZ
  'fvlb-g': 'hydra-rating-fvlb-g',
  'fvlb-pg': 'hydra-rating-fvlb-pg',
  'fvlb-m': 'hydra-rating-fvlb-m',
  'fvlb-r13': 'hydra-rating-fvlb-r13',
  'fvlb-rp13': 'hydra-rating-fvlb-rp13',
  'fvlb-r15': 'hydra-rating-fvlb-r15',
  'fvlb-r16': 'hydra-rating-fvlb-r16',
  'fvlb-rp16': 'hydra-rating-fvlb-rp16',
  'fvlb-r18': 'hydra-rating-fvlb-r18',
  'fvlb-r': 'hydra-rating-fvlb-r',
} as Record<string, string>;
