









































































































































































































































































































































































































// TODO: Move to Catalog
const ASIAN_LANGUAGES = [
  'ja',
  'ko',
  'zh',
  'zh_MN',
  'zh_CT',
  'zh_TW',
  'zh_CN',
  'cn'
];
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
export default Vue.extend({
  name: 'video-player-version-language-panel',
  props: {
    panelOpen: Boolean,
  },
  data() {
    return {
      showAudioLanguageSelection: false,
      showVersionSelection: false,
      showSubtitlesSelection: false,
    };
  },
  computed: {
    ...mapGetters({
      episodeSpokenLanguages: 'videoPlayerCatalog/episodeSpokenLanguages',
      experienceSubtitleLanguages: 'videoPlayerCatalog/experienceSubtitleLanguages',
      episodeVersions: 'videoPlayerCatalog/episodeVersions',
      selectedSubtitleLanguage: 'selectedSubtitleLanguage',
      selectedSpokenLanguage: 'selectedSpokenLanguage',
      selectedVersion: 'selectedVersion',
      isMatureAllowed: 'isMatureAllowed',
      currentEpisode: 'videoPlayerCatalog/currentEpisode',
    }),
    // TODO: Move to Catalog
    isSubtitleOffDisabled(): boolean {
      return ASIAN_LANGUAGES.indexOf(this.spokenLanguageModel) !== -1;
    },
    subtitleLanguageModel: {
      get() {
        return this.selectedSubtitleLanguage;
      },
      set(val) {
        this.setSelectedSubtitleLanguage(val);
        this.setSubtitleTrack(val);
      },
    },
    spokenLanguageModel: {
      get() {
        return this.selectedSpokenLanguage;
      },
      set(val) {
        const timeCurrent = this.$store.state.videoPlayerCore.timeCurrent;
        this.$store.commit('SET_LANGUAGE_CHANGE_PLAY_POSITION', timeCurrent);
        this.setSelectedSpokenLanguage(val);
      },
    },
    version: {
      get() {
        return this.selectedVersion;
      },
      set(val) {
        this.setSelectedVersion(val);
      },
    },
    hasOneLockedSVODSpokenLanguage(): boolean {
      if (!Array.isArray(this.episodeSpokenLanguages)) {
        return false;
      }

      return this.episodeSpokenLanguages.some( (lang: any) => lang.isLocked && lang.lockedReason === 'SVOD' );
    },
    hasMultipleLockedSVODSpokenLanguages() {
      if (!Array.isArray(this.episodeSpokenLanguages)) {
        return false;
      }

      const svodLockedLanguages = this.episodeSpokenLanguages.filter( (lang: any) => lang.isLocked && lang.lockedReason === 'SVOD' );
      const estLockedLanguages = this.episodeSpokenLanguages.filter( (lang: any) => lang.isLocked && lang.lockedReason === 'EST' );

      return (svodLockedLanguages.length + estLockedLanguages.length) > 1;
    },
    hasOneLockedESTSpokenLanguage(): boolean {
      if (!Array.isArray(this.episodeSpokenLanguages)) {
        return false;
      }

      return this.episodeSpokenLanguages?.some( (lang: any) => lang.isLocked && lang.lockedReason === 'EST' );
    },
    hasOneLockedSVODSubtitleLanguage(): boolean {
      if (!Array.isArray(this.experienceSubtitleLanguages)) {
        return false;
      }

      return this.experienceSubtitleLanguages.some( (lang: any) => lang.isLocked && lang.lockedReason === 'SVOD' );
    },
    hasMultipleLockedSVODSubtitleLanguages() {
      if (!Array.isArray(this.experienceSubtitleLanguages)) {
        return false;
      }

      const svodLockedLanguages = this.experienceSubtitleLanguages.filter( (lang: any) => lang.isLocked && lang.lockedReason === 'SVOD' );
      const estLockedLanguages = this.experienceSubtitleLanguages.filter( (lang: any) => lang.isLocked && lang.lockedReason === 'EST' );

      return (svodLockedLanguages.length + estLockedLanguages.length) > 1;
    },
    hasOneLockedESTSubtitleLanguage(): boolean {
      if (!Array.isArray(this.experienceSubtitleLanguages)) {
        return false;
      }

      return this.experienceSubtitleLanguages?.some( (lang: any) => lang.isLocked && lang.lockedReason === 'EST' );
    },
    hasOneLockedSVODVersion(): boolean {
      if (!Array.isArray(this.episodeVersions)) {
        return false;
      }

      return this.episodeVersions.some( (version: any) => version.isLocked && version.lockedReason === 'SVOD' );
    },
    hasMultipleLockedSVODVersions() {
      if (!Array.isArray(this.episodeVersions)) {
        return false;
      }

      const svodLockedVersions = this.episodeVersions.filter( (version: any) => version.isLocked && version.lockedReason === 'SVOD' );
      const estLockedVersions = this.episodeVersions.filter( (version: any) => version.isLocked && version.lockedReason === 'EST' );

      return (svodLockedVersions.length + estLockedVersions.length) > 1;
    },
    hasOneLockedESTVersion(): boolean {
      if (!Array.isArray(this.episodeVersions)) {
        return false;
      }

      return this.episodeVersions?.some( (version: any) => version.isLocked && version.lockedReason === 'EST' );
    },
  },
  methods: {
    ...mapActions({
      setSelectedSpokenLanguage: 'setSelectedSpokenLanguage',
      setSelectedSubtitleLanguage: 'setSelectedSubtitleLanguage',
      setSubtitleTrack: 'videoPlayerCore/setSubtitleTrack',
      setSelectedVersion: 'setSelectedVersion',
      setLanguageChangePlayPosition: 'setLanguageChangePlayPosition',
      setUpsellRedirect: 'setUpsellRedirect',
      setDigitalCopyUpsellRedirect: 'setDigitalCopyUpsellRedirect',
    }),
  },
  watch: {
    selectedSpokenLanguage() {
      if (
        this.isSubtitleOffDisabled
        && (this.subtitleLanguageModel === 'off' || this.isSubtitleOffDisabled)
        && this.experienceSubtitleLanguages?.length > 0
      ) {
        this.subtitleLanguageModel = this.experienceSubtitleLanguages[0].languageCode;
      }
    },
    panelOpen(newValue: boolean) {
      if (!newValue) {
        this.showAudioLanguageSelection = false;
        this.showVersionSelection = false;
        this.showSubtitlesSelection = false;
      }
    },
  },
});
