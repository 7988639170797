import Vue from 'vue';
import axios from 'axios';
import './types/uuid';
import { v4 as uuidv4 } from 'uuid';
import { compareStrings } from '@funimation/comp-utils';
import { API_URLS } from './config/apiUrls';
import { ProfileData, UserData } from './UserInfoData';
import { ASIAN_LANGUAGES, PREMIUM_SUBSCRIPTION_TIER_CUTOFF } from './config';
import { getCookie, UserInfo } from './utils';
import {
  getInitialVideoPlayerSettings,
  saveVideoPlayerSettingsToLocalStorage,
  SESSION_ID_LOCAL_STORAGE_KEY
} from './config/localStorageConfig';


declare global {
  interface Window {
    isSupportedRegion: boolean;
  }
}

const setupStore = (context: any, config?: Record<string, any>) => {
  if (!config || !config.venueService || !config.geoService) {
    throw new TypeError('config must contain venueService and geoService');
  }

  const userInfo = new UserInfo({ service: config.geoService });

  const store = {
    state: {
      token: '',
      deviceid: '',
      userData: null,
      region: '',
      isSupportedRegion: false,
      userLanguage: '',
      selectedSpokenLanguage: '',
      selectedSubtitleLanguage: 'off',
      selectedQuality: 'auto',
      selectedVersion: 'uncut',
      selectedVolume: undefined,
      languageChangePlayPosition: undefined,
      userDataReady: false,
      pin: '',
      pinActivated: false,
      userInfo,
    },
    getters: {
      regionLocale(state: Record<string, any>): string {
        let regionLocale = 'en';
        const enLocales = [ 'CA', 'GB', 'IE', 'AU', 'NZ', 'US' ];
        const region = window.region;
        if ( region && !enLocales.includes(region)) {
          regionLocale = `${state.userLanguage}-${region.toLowerCase()}`;
        }
        return regionLocale;
      },
      userLanguage(state: Record<string, any>): boolean {
        return state.userLanguage;
      },
      isLoggedIn(state: Record<string, any>): boolean {
        return state.userData && !state.userData.noUserData;
      },
      isSubscriber(state: Record<string, any>, getters: Record<string, any>): boolean {
        return getters.isLoggedIn
          && getters.userSubscription
          && getters.userSubscription.subscriptionTier !== 10;
      },
      autoMarathon(state: Record<string, any>): boolean {
        const autoPlay = state.userData?.user?.profileData?.autoPlay;
        return autoPlay === 'true' || autoPlay === true;
      },
      selectedSpokenLanguage(state: Record<string, any>, getters: Record<string, any>): string {
        if (state.selectedSpokenLanguage) {
          return state.selectedSpokenLanguage;
        }
        if (getters.userLanguage) {
          return getters.userLanguage;
        }

        return 'ja';
      },
      selectedSubtitleLanguage(
        state: Record<string, any>,
        getters: Record<string, any>
      ): string {
        // Force Subtitles on Asian audio languages
        if (getters.forcedSubtitleLanguage) {
          return getters.forcedSubtitleLanguage;
        }

        // Use state value
        if (state.selectedSubtitleLanguage) {
          return state.selectedSubtitleLanguage;
        }

        // Fallback
        return 'off';
      },
      /**
       * Force Subtitles on Asian audio languages.
       * Keep this logic at getter level, rather than localStorage or mutation,
       * to avoid overriding user preference.
       */
      forcedSubtitleLanguage(
        state: Record<string, any>,
        _getters: Record<string, any>,
        _rootState: Record<string, any>,
        rootGetters: Record<string, any>
      ): string {
        const experienceSubtitleLanguages: Record<string, any>[] = rootGetters['videoPlayerCatalog/experienceSubtitleLanguages'];
        if (!experienceSubtitleLanguages?.length) {
          // Nothing to select from
          return '';
        }

        const isAsianAudioLanguage = ASIAN_LANGUAGES.includes(state.selectedSpokenLanguage);
        if (!isAsianAudioLanguage) {
          // Not an Asian Audio Language
          return '';
        }

        const selectedSubtitleLanguage: string = state.selectedSubtitleLanguage;
        if (selectedSubtitleLanguage) {
          const foundPreferredSubtitleLanguage = experienceSubtitleLanguages.some((lang) => {
            return lang?.languageCode === selectedSubtitleLanguage;
          });
          if (foundPreferredSubtitleLanguage) {
            // Use preferred Subtitle Language
            return selectedSubtitleLanguage;
          }
        }

        // Fallback for no preference or 'off'
        return experienceSubtitleLanguages[0]?.languageCode || '';
      },
      selectedQuality(state: Record<string, any>): string {
        if (state.selectedQuality) {
          return state.selectedQuality;
        }

        return 'auto';
      },
      selectedVersion(state: Record<string, any>): string {
        if (state.selectedVersion) {
          return state.selectedVersion;
        }

        return 'uncut';
      },
      isMatureAllowed(state: Record<string, any>): boolean {
        if (
          state.userData?.user?.profileData?.restrictMatureContent !== undefined &&
          state.userData?.user?.profileData?.restrictMatureContent !== null
        ) {
          return !compareStrings(state.userData.user.profileData.restrictMatureContent, 'true');
        }

        return false;
      },
      userEntitlements(state: Record<string, any>): Array<number> {
        return (Array.isArray(state.userData?.user?.entitlements)) ? state.userData?.user?.entitlements : [];
      },
      userSubscription(state: Record<string, any>): Array<number> {
        return state.userData?.subscription;
      },
      isUserPremium(state: Record<string, any>): boolean {
        return state.userData?.subscription && state.userData.subscription.subscriptionTier > PREMIUM_SUBSCRIPTION_TIER_CUTOFF;
      },
      subtitleStyles(state: Record<string, any>): Record<string, any> {
        const profileData = state.userData?.user?.profileData;
        if (!profileData) {
          return {};
        }

        return {
          backgroundPreference: profileData.backgroundPreference,
          fontColorPreference: profileData.fontColorPreference,
          fontOpacityPreference: profileData.fontOpacityPreference,
          textSizePreference: profileData.textSizePreference,
        };
      },
      sessionId(): string | null {
        return window.localStorage.getItem(SESSION_ID_LOCAL_STORAGE_KEY);
      }
    },
    actions: {
      async fetchUserRegion(context: any) {
        const region = context.rootState.userInfo.region;
        if (region) {
          return;
        }
        let result;
        try {
          result = await userInfo.fetchUserRegion();
          context.commit('SET_USER_REGION', result);
        } catch (err) {
          const er = new Error(`Error fetching user region: ${err}`);
          context.dispatch('error', er, { root: true });
        }
      },
      setUserLanguage(context: any) {
        const language = context.rootState.userInfo.userLanguage;
        if (language) {
          return;
        }
        const result = userInfo.fetchUserLanguage();
        context.commit('SET_USER_LANGUAGE', result);
        userInfo.saveUserData(context.state.userData);
      },
      setUserAudioLanguage(context: any) {
        const language = context.rootState.userInfo.userAudioLanguage;
        if (language) {
          return;
        }
        const result = userInfo.fetchUserAudioLanguage();
        context.commit('SET_USER_AUDIO_LANGUAGE', result);
        userInfo.saveUserData(context.state.userData);
      },
      logOutUser(context: any) {
        const result = userInfo.logOutUser();
        context.commit('SET_USER_TOKEN', result.token);
        context.commit('SET_USER_DEVICE_ID', result.deviceid);
        context.commit('SET_USER_DATA', result.userData);
      },
      setSelectedSpokenLanguage(context: Record<string, any>, newValue: string): void {
        context.commit('SET_SELECTED_SPOKEN_LANGUAGE', newValue);
        saveVideoPlayerSettingsToLocalStorage(context);
      },
      setSelectedSubtitleLanguage(context: Record<string, any>, newValue: string): void {
        context.commit('SET_SELECTED_SUBTITLE_LANGUAGE', newValue);
        saveVideoPlayerSettingsToLocalStorage(context);
      },
      setSelectedQuality(context: Record<string, any>, newValue: string): void {
        context.commit('SET_SELECTED_QUALITY', newValue);
        saveVideoPlayerSettingsToLocalStorage(context);
      },
      setSelectedVersion(context: Record<string, any>, newValue: string): void {
        context.commit('SET_SELECTED_VERSION', newValue);
        saveVideoPlayerSettingsToLocalStorage(context);
      },
      setSelectedVolume(context: Record<string, any>, newValue: string): void {
        context.commit('SET_SELECTED_VOLUME', newValue);
        saveVideoPlayerSettingsToLocalStorage(context);
      },
      toggleAutoMarathon(context: any) {
        context.commit('SET_AUTO_MARATHON', !context.getters.autoMarathon);
        userInfo.saveUserData(context.state.userData);
        context.dispatch('setAutoMarathon');
      },

      async setAutoMarathon(context: any) {
        const token = context.state.token;
        const autoPlayState = context.state.userData.user.profileData.autoPlay.toString();
        if (!token) { return; }
        try {
          await axios.post(config?.venueService + API_URLS.userProfileService, {
            options: [
              {
                key: 'autoPlay',
                value: autoPlayState,
              } ]
          }, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
        } catch (err) {
          const er = new Error('Error updating autoPlay: ' + err);
          console.warn(er);
          context.dispatch('error', er, { root: true });
        }
      },

      fetchTokenAndDeviceIdFromCookies(context: any) {
        const token = getCookie('src_token');
        if (token) {
          context.commit('SET_USER_TOKEN', token);
        }

        const deviceid = getCookie('deviceid');
        if (deviceid !== null || deviceid !== undefined) {
          context.commit('SET_USER_DEVICE_ID', deviceid);
        }
      },
      async activatePin(context: any, pin: string) {
        const token = context.state.token;
        if (!token) {
          context.commit('SET_USER_DATA_READY', true);
          return;
        }
        const venueUserId = getCookie('src_user_id');
        try {
          await axios.post(config?.pinService + API_URLS.pinService, { pin: pin, venueUserId: venueUserId}, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
          context.commit('SET_USER_PIN_STATUS', true);
        } catch (err) {
          const er = new Error('Error activating PIN: ' + err);
          console.warn(er);
          context.dispatch('error', er, { root: true });
        }
      },
      async fetchUserInfoFromVenue(context: any) {
        const token = context.state.token;

        if (!token) {
          context.commit('SET_USER_DATA_READY', true);
          return;
        }

        let result;
        try {
          result = await axios.get(config?.venueService + API_URLS.userInfoService, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          const er = new Error(`Error getting user info from venue: ${error}`);
          console.warn(er);
        }

        if (!result || !Array.isArray(result?.data?.items) || result?.data?.items.length < 1) {
          console.warn('no user profile data in the response');
          context.commit('SET_USER_DATA_READY', true);
          return;
        }

        const userDataResponse = result.data.items[0];
        const userData = {
          addons: userDataResponse.addons,
          displayname: userDataResponse.displayname,
          subscription: userDataResponse.subscription,
          user: userDataResponse,
        };
        context.commit('SET_USER_DATA', userData);
        userInfo.saveUserData(context.state.userData);
      },
      fetchInitialVideoPlayerSettings(context: any) {
        getInitialVideoPlayerSettings(context);
      },
      setUpsellRedirect(context: Record<string, any>, payload: Record<string, any>) {
        const url = `/v/${payload.showSlug}/${payload.episodeSlug}`;
        const cookiesPayload = {
          url: url,
          show: payload.showName,
        };

        userInfo.setCookie('upsellRedirect', JSON.stringify(cookiesPayload), 1);
        window.location.assign('/subscribe');
      },
      setDigitalCopyUpsellRedirect(context: Record<string, any>, payload: Record<string, any>) {
        const url = `/v/${payload.showSlug}/${payload.episodeSlug}`;
        const cookiesPayload = {
          url: url,
          show: payload.showName,
        };

        userInfo.setCookie('upsellRedirect', JSON.stringify(cookiesPayload), 1);
        window.location.assign('/digitalcopy');
      },
      generateSessionId() {
        const sessionId = window.localStorage.getItem(SESSION_ID_LOCAL_STORAGE_KEY);
        if (sessionId) {
          return;
        }

        const newSessionId = uuidv4();
        window.localStorage.setItem(SESSION_ID_LOCAL_STORAGE_KEY, newSessionId);
      },
    },
    mutations: {
      SET_USER_DATA_READY(state: Record<string, any>, payload: boolean) {
        state.userDataReady = payload;
      },
      SET_USER_DATA(state: Record<string, any>, payload: Record<string, any>) {
        Vue.set(state, 'userData', payload);
        state.userDataReady = true;
      },
      SET_USER_TOKEN(state: Record<string, any>, payload: string) {
        state.token = payload;
      },
      SET_USER_DEVICE_ID(state: Record<string, any>, payload: string) {
        state.deviceid = payload;
      },
      SET_USER_REGION(state: Record<string, any>, payload: ProfileData) {
        state.region = payload.region;
        state.isSupportedRegion = payload.isSupportedRegion;
      },
      SET_USER_LANGUAGE(state: Record<string, any>, payload: UserData) {
        state.userLanguage = payload.defaultLanguage;
      },
      SET_USER_AUDIO_LANGUAGE(state: Record<string, any>, payload: UserData) {
        state.userAudioLanguage = payload.defaultLanguage;
      },
      SET_AUTO_MARATHON(state: Record<string, any>, payload: boolean) {
        state.userData.user.profileData.autoPlay = payload;
      },
      SET_SELECTED_SPOKEN_LANGUAGE(state: Record<string, any>, payload: string) {
        state.selectedSpokenLanguage = payload;
      },
      SET_SELECTED_SUBTITLE_LANGUAGE(state: Record<string, any>, payload: string) {
        state.selectedSubtitleLanguage = payload;
      },
      SET_SELECTED_QUALITY(state: Record<string, any>, payload: string) {
        state.selectedQuality = payload;
      },
      SET_SELECTED_VERSION(state: Record<string, any>, payload: string) {
        state.selectedVersion = payload;
      },
      SET_SELECTED_VOLUME(state: Record<string, any>, payload: string | number) {
        // Normalize to number
        payload = Number(payload);
        if (Number.isNaN(payload)) {
          payload = 1;
        }

        // Normalize decimal for backwards compatibility.
        if (payload > 1) {
          payload = payload / 100;
        }

        state.selectedVolume = payload;
      },
      SET_LANGUAGE_CHANGE_PLAY_POSITION(state: Record<string, any>, payload: string | number) {
        state.languageChangePlayPosition = payload;
      },
      SET_USER_PIN_STATUS(state: Record<string, any>, payload: boolean) {
        state.pinActivated =  payload;
      }, 
    },
  };
  context.registerModule('userInfo', store);
  context.dispatch('generateSessionId');
  context.dispatch('fetchTokenAndDeviceIdFromCookies');
  context.dispatch('fetchUserInfoFromVenue');
  context.dispatch('fetchUserRegion');
  context.dispatch('setUserLanguage');
  context.dispatch('setUserAudioLanguage');
};

export default (context: any, config?: Record<string, any>) => {
  setupStore(context, config);
  return {
    install() { }, // eslint-disable-line
  };
};
