










































































import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BottomSheet',
  data() {
    return {
      sheet: true,
      bottomSheetContent: this.$t('bottomSheetContent'),
    };
  },
  beforeMount() {
    const translations = this.bottomSheetContent as Record<string, any>;
    const esCountries = [ 'CO', 'CL', 'PE', 'MX' ];
    const region = window.region as string;
    if (esCountries.includes(window.region)) {
      translations.subscribeLink = `/es-${region.toLowerCase()}/subscribe`;
    }
  },
  async mounted() {
    const slug = this.route.params.slug || window.defaultShow;
    await this.fetchShowDetailsBySlug({
      slug: slug,
      region: this.region,
      deviceType: 'web',
      locale: this.userLanguage,
    });
  },
  computed: {
    ...mapState({
      route: (state: any) => state.route,
      show: (state: any) => state.showDetails.show,
      userLanguage: (state: any) => state.userInfo.userLanguage,
    }),
    ...mapGetters([ 'isLoggedIn', 'isSubscriber' ]),
    region(): string {
      return window.region;
    },
    desktopMessage(): string  {
      return `${this.$t('bottomSheetContent.desktopMsgPart1')}
        ${this.show.episodeCount ? this.show.episodeCount : ''}
        ${this.$t('bottomSheetContent.desktopMsgPart2')}`;
    },
    isDismissed(): boolean {
      return !!sessionStorage.getItem('Premium Dismissed');
    },
  },
  methods: {
    ...mapActions({
      fetchShowDetailsBySlug: 'fetchShowDetailsBySlug',
    }),
    dismissBottomSheet() {
      this.sheet = !this.sheet;
      sessionStorage.setItem('Premium Dismissed', 'true');
    },
  },
});
