var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h6 font-weight-bold text-center text-line-clamp-1",domProps:{"textContent":_vm._s(_vm.$t('issueMsg'))}}),_c('p',{staticClass:"text-center text-body-2 font-weight-normal my-6"},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.$t('endcard-x', {
          curEpisode: _vm.currentShowName,
          curEpisodeType: _vm.$t(_vm.currentEpisodeType),
          nextEpisodeNumber: _vm.$t(_vm.nextEpisodeNumber),
          lang: _vm.$t(_vm.selectedSpokenLanguage),
          altLang: _vm.$t(_vm.altNextEpisodeExperienceSpokenLanguage),
          version: _vm.$t(_vm.selectedVersion),
          altVersion: _vm.$t(_vm.altNextEpisodeExperienceType),
        })
      )}})]),_c('div',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"text-uppercase primary--text",attrs:{"color":"white","href":_vm.nextEpisodeHref},domProps:{"textContent":_vm._s(_vm.$t('yes'))}}),(_vm.currentShowSlug)?_c('v-btn',{staticClass:"ml-4 text-uppercase",attrs:{"data-test":"button-current","depressed":"","color":"white","outlined":"","href":("/shows/" + _vm.currentShowSlug)},domProps:{"textContent":_vm._s(_vm.$t('back'))}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }