import { PluginFunction } from 'vue';
import { PluginOptions, RootStoreInstance } from './types';
import createVuexModule from './store/';

/** Factory to create a VideoPlayerModule */
export const playerModuleFactory = (store: RootStoreInstance): any => {
  let getVideoCuesWatchRef: () => void;

  return {
    name: 'video-player-skip-cues',
    install() {
      getVideoCuesWatchRef = store.watch(
        function getVideoId(_state: any, getters: any) {
          return getters['videoPlayerCatalog/selectedVideoId'];
        },
        function getVideoCues(videoId) {
          if (!videoId) {
            return;
          }

          store.dispatch('videoPlayerSkipCues/getVideoCues', videoId);
        }
      );
    },
    destroy() {
      if (getVideoCuesWatchRef) {
        getVideoCuesWatchRef();
      }
    },
  };
};

/**
 * Vue Plugin, which is called on Vue.use().
 *
 * @example
 * import VideoPlayerSkipCues from 'funimation/comp-video-player-skip-cues';
 * Vue.use(VideoPlayerSkipCues, {
 *  store,
 *  videoCuesService
 * });
 */
const vuePlugin: PluginFunction<PluginOptions> = function(Vue, pluginOptions): void {
  // Validate PluginOptions
  if (!pluginOptions?.store) {
    throw new Error('VideoPlayerSkipCues requires "pluginOptions.store".');
  }
  if (!pluginOptions?.videoCuesService) {
    throw new Error('VideoPlayerSkipCues requires "pluginOptions.videoCuesService".');
  }

  pluginOptions.store.registerModule('videoPlayerSkipCues', createVuexModule(pluginOptions));

  const playerModule = playerModuleFactory(pluginOptions.store);
  pluginOptions.store.dispatch('videoPlayer/modulesRegister', playerModule);
};

export default vuePlugin;
