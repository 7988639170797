import { PluginObject } from 'vue';
import { Store } from 'vuex';

import VideoPlayerEndScreen from './index.vue';

export const store = {
  state: {
    showEndScreen: false,
  },
  mutations: {
    SET_SHOW_END_SCREEN(state: Record<string, any>, payload: any) {
      state.showEndScreen = payload;
    },
  },
};

type StoreInstance = Store<undefined>;

/** Sets-up context and returns a Vue Plugin. */
const pluginFactory = /* istanbul ignore next */(storeInstance: StoreInstance): PluginObject<typeof storeInstance> => {
  if (!storeInstance) {
    throw new Error('VideoPlayerEndScreen requires "storeInstance" argument.');
  }

  return {
    install(Vue) {
      storeInstance.registerModule('videoPlayerEndScreen', store);

      Vue.component('video-player-end-screen', VideoPlayerEndScreen);

      storeInstance.dispatch('videoPlayer/modulesRegister', {
        name: 'video-player-end-screen',
        install(installContext: any) {
          installContext.eventBus.on('playback-ended', () => {
            storeInstance.commit('SET_SHOW_END_SCREEN', true);
          });
        }
      });
    },
  };
};

export default pluginFactory;
