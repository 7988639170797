import { v4 as uuidv4 } from 'uuid';

// @ts-expect-error
import { TruexAdRenderer } from '@truex/ctv-ad-renderer';

// Use a random UUID for the "opt out of tracking" advertising id that is stable for all ads in an app session.
const optOutAdvertisingId = uuidv4();

// Exercises the True[X] Ad Renderer for interactive ads.
export default class InteractiveAd {
  start: any;
  tar: any;
  videoController: any;
  adFreePod: any;
  adOverlay: any;
  adsManager: any;
  playerControlBar: any;
  ad: any;
  optOut: any;

  constructor(vastConfigUrl: any, videoController: any) {
    this.adFreePod = false;
    this.videoController = videoController;
    this.adsManager = videoController.adsManager;
    this.ad = videoController.ad;

    this.start = async () => {
      try {
        this.videoController.player.pause();
        this.adsManager.pause();

        const options = {
          fallbackAdvertisingId: optOutAdvertisingId, // random fallback to use if no user ad id is available
          supportsUserCancelStream: true // i.e. user backing out of an ad will cancel the entire video
        };

        this.tar = new TruexAdRenderer(vastConfigUrl, options);
        this.tar.subscribe((event: any) => this.handleAdEvent(event));

        return this.tar.init()
          .then((vastConfig: any): any => {
            return this.tar.start(vastConfig);
          })
          .then((newAdOverlay: any): any => {
            this.adOverlay = newAdOverlay;
          })
          .catch((err: any) => {
            this.handleAdError(err);
          }
          );
      } catch (err) {
        this.handleAdError(err);
      }
    };
  }

  handleAdEvent(event: any) {
    const adEvents = this.tar.adEvents;
    const showAndEpisodeName = this.videoController.store.getters['videoPlayerAnalytics/showAndEpisodeName'];
    switch (event.type) {
    case adEvents.adError:
      this.handleAdError(event.errorMessage);
      break;

    case adEvents.adStarted:
      // Choice card loaded and displayed.
      this.adsManager.pause();
      this.videoController.store.dispatch('videoPlayerAnalytics/trackEvent', {
        eventAction: 'TrueX Card Shown',
        eventLabel: showAndEpisodeName,
      });
      break;

      // For future use:
    case adEvents.optIn:
      // User started the engagement experience
      this.videoController.store.dispatch('videoPlayerAnalytics/trackEvent', {
        eventAction: 'TrueX Engagement Selected',
        eventLabel: 'Yes',
      });
      break;

      // For future use:
    case adEvents.optOut:
      // User cancelled out of the choice card, either explicitly, or implicitly via a timeout.
      this.videoController.store.dispatch('videoPlayerAnalytics/trackEvent', {
        eventAction: 'TrueX Engagement Selected',
        eventLabel: 'No',
      });
      break;

    case adEvents.adFreePod:
      this.adFreePod = true; // the user did sufficient interaction for an ad credit
      break;

      // For future use:
      /*case adEvents.userCancel:
      // User backed out of the ad, now showing the choice card again.
      break;*/

      // For future use:
      /*case adEvents.userCancelStream:
      // User backed out of the choice card, which means backing out of the entire video.
      //closeAdOverlay();
      //videoController.closeVideoAction();
      break;*/

    case adEvents.noAdsAvailable:
    case adEvents.adCompleted:
      // Ad is not available, or has completed. Depending on the adFreePod flag, either the main
      // video or the ad fallback videos are resumed.
      this.videoController.store.dispatch('videoPlayerAnalytics/trackEvent', {
        eventAction: 'TrueX Ad Completed',
        eventLabel: showAndEpisodeName,
      });
      this.tar.stop();
      this.closeAdOverlay();
      this.resumePlayback();
      break;
    }
  }

  handleAdError(errOrMsg: any) {
    console.error('ad error: ' + errOrMsg); // eslint-disable-line
    if (this.tar) {
      // Ensure the ad is no longer blocking back or key events, etc.
      this.tar.stop();
    }
    this.closeAdOverlay();
    this.resumePlayback();
  }

  closeAdOverlay() {
    if (this.adOverlay) {
      if (this.adOverlay.parentNode) {
        this.adOverlay.parentNode.removeChild(this.adOverlay);
      }
      this.adOverlay = null;
    }

    if (this.tar) {
      this.tar.stop();
      this.tar = null;
    }
  }

  resumePlayback() {
    if (this.adFreePod) {
      // The user has the ad credit, skip over the ad video.
      this.adsManager.stop();
    } else {
      this.adsManager.resume();
    }
  }
}
