export const API_URLS = {
  catalogProjection: {
    show: (slug: string) => {
      return `/shows/${slug}.json`;
    },
    showById: (id: number) => {
      return `/shows/${id}.json`;
    },
    episode: (episodeId: number) => {
      return `/episodes/${episodeId}.json`;
    },
    episodesBySeason: (seasonId: string) => {
      return `/seasons/${seasonId}.json`;
    },
  },
  titleService: {
    episodesViewed: (region: string, deviceType: string,
      limit: number, offset: number, locale: string) => {
      return `/episodes/viewed?offset=${offset}&limit=${limit}&deviceType=${deviceType}&region=${region}&locale=${locale}`;
    },
    showsViewed: (region: string, deviceType: string,
      limit: number, offset: number, locale: string) => {
      return `/shows/viewed?offset=${offset}&limit=${limit}&deviceType=${deviceType}&region=${region}&locale=${locale}`;
    },
    showMeta: (showId: number, region: string, deviceType: string, locale: string) => {
      return `/shows/${showId}/meta?region=${region}&deviceType=${deviceType}&locale=${locale}`;
    },
    unSignedVideoBySlug: (showSlug: string, episodeSlug: string, region: string, deviceType: string, locale: string) => {
      return `/shows/${showSlug}/episodes/${episodeSlug}/?region=${region}&deviceType=${deviceType}&locale=${locale}`;
    },
    episodesMeta: (seasonId: number) => {
      return `/seasons/${seasonId}/meta`;
    },
    addShowToQueue: () => {
      return '/usershows';
    },
    deleteShowFromQueue: (showId: number) => {
      return `/usershows/${showId}`;
    },
  },
};
