import { compareStrings } from '@funimation/comp-utils';
import {
  Image,
  RootState,
  TitleEpisode,
  VideoPlayerCatalogState
} from '../types';


export function createEpisodeGetters(episodeToGet = 'currentEpisode'): Record<string, any> {
  return {
    // currentEpisode
    // nextEpisode
    [`${episodeToGet}`]: function(state: VideoPlayerCatalogState): TitleEpisode {
      return state[episodeToGet];
    },
    // currentEpisodeId
    // nextEpisodeId
    [`${episodeToGet}Id`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): string | null {
      if (!getters[episodeToGet]?.id) {
        return null;
      }

      return getters[episodeToGet].id;
    },
    // currentEpisodeVenueId
    // nextEpisodeVenueId
    [`${episodeToGet}VenueId`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): string | null {
      if (!getters[episodeToGet]?.venueId) {
        return null;
      }

      return getters[episodeToGet].venueId;
    },
    // currentEpisodeType
    // nextEpisodeType
    [`${episodeToGet}Type`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
      if (!getters[episodeToGet]?.type) {
        return '';
      }
  
      return getters[episodeToGet].type;
    },
    // currentEpisodeRating
    // nextEpisodeRating
    [`${episodeToGet}Rating`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState): Record<string, any> | null {
      if (!Array.isArray(getters[episodeToGet]?.ratings) || getters[episodeToGet]?.ratings.length < 1) {
        return null;
      }

      let matchingRating = getters[episodeToGet].ratings.find((rating: Record<string, any>) => {
        return compareStrings(rating.region, rootState.userInfo.region);
      });
      if (!matchingRating) {
        matchingRating = getters[episodeToGet].ratings[0];
      }
        
      return matchingRating;
    },
    // currentEpisodeRatingCode
    // nextEpisodeRatingCode
    [`${episodeToGet}RatingCode`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
      if (!getters[`${episodeToGet}Rating`]?.ratingCode) {
        return '';
      }
        
      return getters[`${episodeToGet}Rating`].ratingCode;
    },
    // currentEpisodeRatingSystem
    // nextEpisodeRatingSystem
    [`${episodeToGet}RatingSystem`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
      if (!getters[`${episodeToGet}Rating`]?.ratingSystem) {
        return '';
      }
        
      return getters[`${episodeToGet}Rating`].ratingSystem;
    },
    // currentEpisodeName
    // nextEpisodeName
    [`${episodeToGet}Name`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): string {
      if (!getters[episodeToGet]?.name) {
        return '';
      }

      const userLanguage = (rootGetters.userLanguage || '').toLowerCase();
      let matchingName = getters[episodeToGet].name[userLanguage];
      if (!matchingName) {
        matchingName = Object.values(getters[episodeToGet].name)[0];
      }

      return matchingName;
    },
    // currentEpisodeSlug
    // nextEpisodeSlug
    [`${episodeToGet}Slug`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
      if (!getters[episodeToGet]?.slug) {
        return '';
      }

      return getters[episodeToGet].slug;
    },
    // currentEpisodeNumber
    // nextEpisodeNumber
    [`${episodeToGet}Number`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
      if (!getters[episodeToGet]?.episodeNumber) {
        return '';
      }

      return getters[episodeToGet].episodeNumber;
    },
    // currentEpisodeDurationInMinutes
    // nextEpisodeDurationInMinutes
    [`${episodeToGet}DurationInMinutes`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): number | null {
      if (!getters[episodeToGet]?.durationInMinutes) {
        return null;
      }
  
      return getters[episodeToGet].durationInMinutes;
    },
    // currentEpisodeDuration
    // nextEpisodeDuration
    [`${episodeToGet}Duration`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): number | null {
      if (!getters[episodeToGet]?.duration) {
        return null;
      }
  
      return getters[episodeToGet].duration;
    },
    // currentEpisodeReleaseDate
    // nextEpisodeReleaseDate
    [`${episodeToGet}ReleaseDate`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): number | null {
      if (!getters[episodeToGet]?.releaseDate) {
        return null;
      }
  
      return getters[episodeToGet].releaseDate;
    },
    // currentEpisodeSynopsis
    // nextEpisodeSynopsis
    [`${episodeToGet}Synopsis`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>, rootState: RootState, rootGetters: Record<string, any>): string {
      if (!getters[episodeToGet]?.synopsis) {
        return '';
      }

      const userLanguage = (rootGetters.userLanguage || '').toLowerCase();
      let matchingName = getters[episodeToGet].synopsis[userLanguage];
      if (!matchingName) {
        matchingName = Object.values(getters[episodeToGet].synopsis)[0];
      }

      return matchingName;
    },
    // currentEpisodeShowThumbnail
    // nextEpisodeShowThumbnail
    [`${episodeToGet}ShowThumbnail`]: function(state: VideoPlayerCatalogState, getters: Record<string, any>): string {
      const images = getters[episodeToGet]?.images ?? [];
      if (images.length < 1) {
        return '';
      }
  
      let matchingImage = images.find((image: Image) => compareStrings(image.key, 'show thumbnail'));
      if (!matchingImage) {
        matchingImage = images[0];
      }
  
      return matchingImage?.path;
    },
  };
}