<template>
  <v-row>
    <v-col>
      <h1 class="text-h1 text-uppercase font-weight-bold">Watch History</h1>
      <v-divider class="my-9" />
      <v-row>
        <v-col cols="12">
          <v-expansion-panels class="mb-4" light flat>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="headline primary--text text-uppercase font-weight-bold"
                disable-icon-rotate
              >
                Watch History
                <template v-slot:actions>
                  <v-icon class="primary--text" x-large>mdi-code-tags</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card
            class="mb-9"
            flat
            light
          >
            <v-card-actions class="flex-column flex-md-row align-start">
              <v-btn
                class="px-5 ml-0 ml-md-2 mb-2 mb-md-0"
                color="purple"
                depressed
                :large="true"
                @click="setPlaybackProgressWithParams"
              >
                Set Playback Progress
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Vue from 'vue';
import 'vue-code-highlight/themes/duotone-sea.css';
import 'vue-code-highlight/themes/window.css';
import VueCodeHighlight from 'vue-code-highlight';
import {mapActions} from 'vuex';
Vue.use(VueCodeHighlight);

export default {
  name: 'Watch History',
  methods: {
    ...mapActions([
      'setPlaybackProgress',
    ]),
    async setPlaybackProgressWithParams() {
      await this.setPlaybackProgress({
        deviceType: 'web',
        checkpoint: 666,
        lang: 'ja',
        videoId: '831457',
        authToken: '4625b096534b7fc78ed5c9ef275dedb36b712077',
      });
    },
  },
};
</script>
<style lang="scss">
pre.example-code-block {
  width: 100%;
}
</style>
