




















































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
export default Vue.extend({
  name: 'content-details-tabs',
  data(): Record<string, any> {
    return {
      initialTabSelected: false,
      tab: null as number | null,
      contentDetailsTabs: this.$t('contentDetailsTabs'),
    };
  },
  watch: {
    show(): any {
      if (this.initialTabSelected) {
        return;
      }
      this.initialTabSelected = true;
      if (this.hasSeasons) {
        return this.tab = 0;
      }
      if (this.hasExtras) {
        return this.tab = 1;
      }
      return this.tab = 2;
    },
    tab(): void {
      this.setFilterSortPanelState(false);
    },
  },
  computed: {
    ...mapState({
      panel: (state: any) => state.filterSort.panel,
      show: (state: any) => state.showDetails.show,
    }),
    hasSeasons(): boolean {
      if (!this.show) {
        return true;
      }
      return this.show.seasons.some((season: any) => {
        return /season/i.test(season.type);
      });
    },
    hasExtras(): boolean {
      // Default for extras tab
      if (!this.show) {
        return false;
      }
      return this.show.seasons.some((season: any) => {
        return /extras/i.test(season.type);
      });
    },
  },
  methods: {
    ...mapActions([
      'setFilterSortPanelState',
    ]),
  },
});
