

































































import { mapActions, mapState } from 'vuex';
import Vue from 'vue';
export default Vue.extend({
  name: 'video-player-main-controls',
  data() {
    return {};
  },
  props: {
    isMobile: Boolean,
  },
  components: {},
  computed: {
    ...mapState({
      isPaused: (state: any) => state.videoPlayerCore.isPaused,
    }),
  },
  methods: {
    ...mapActions('videoPlayerCore', [
      'playToggle',
      'seek',
    ]),
  },
});
