/* istanbul ignore file */

import ShowsPosterGrid from './ShowsPosterGrid.vue';

export const setupStore = (context: any) => {
  const showsPosterGridStore = {
    state: {},
    actions: {},
    mutations: {},
  };
  context.registerModule('showsPosterGrid', showsPosterGridStore);
};
export default (context: any) => {
  setupStore(context);
  return {
    install(Vue: any): void {
      Vue.component('shows-poster-grid', ShowsPosterGrid);
    },
  };
};
