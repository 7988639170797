































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import {iconsMap} from './ratingsIcons';
export default Vue.extend({
  name: 'video-player-meta-overlay',
  data() {
    return {
      timer: 0 as any,
      timerFinished: false as boolean,
    };
  },
  computed: {
    ...mapGetters({
      currentEpisode: 'videoPlayerCatalog/currentEpisode',
      episodeRatingSystem: 'videoPlayerCatalog/currentEpisodeRatingSystem',
      episodeRatingCode: 'videoPlayerCatalog/currentEpisodeRatingCode',
      episodeName: 'videoPlayerCatalog/currentEpisodeName',
      episodeSynopsis: 'videoPlayerCatalog/currentEpisodeSynopsis',
      episodeNumber: 'videoPlayerCatalog/currentEpisodeNumber',
      episodeType: 'videoPlayerCatalog/currentEpisodeType',
      episodeVersion: 'selectedVersion',
      showName: 'videoPlayerCatalog/currentShowName',
      showSlug: 'videoPlayerCatalog/currentShowSlug',
      seasonName: 'videoPlayerCatalog/currentSeasonName',
      selectedSpokenLanguage: 'selectedSpokenLanguage',
      selectedSpokenLanguageTranslation: 'videoPlayerCatalog/selectedSpokenLanguageTranslation',
      selectedVersion: 'selectedVersion',
    }),
    ... mapState({
      isPaused: (state: any) => state.videoPlayerCore.isPaused,
      player: (state: any) => state.videoPlayerCore.player,
      eventBus: (state: any) => state.videoPlayer.eventBus,
    }),
    ratingIcon(): any {
      return () => {
        if (!this.episodeRatingCode || !this.episodeRatingSystem) {
          return '';
        }

        // example key from iconsMap = 'tv-us-pg': 'hydra-rating-tv-us-pg',
        const system = this.episodeRatingSystem.toLowerCase();
        const code = this.episodeRatingCode.toLowerCase();
        return iconsMap[`${system}-${code}`];
      };
    },
  },
  methods: {
    ... mapActions('videoPlayerCore', [ 'inAdBreak' ])
  },
  watch: {
    player() {
      this.player?.on('play', async () => {
        if (this.timerFinished) {
          return;
        }
        if (await this.inAdBreak()) {
          return; // exit
        }
        if (this.timer) {
          return; // Only allow 1 timer
        }
        this.timer = setTimeout(async () => {
          this.timer = undefined; // clean-up timer reference
          if (await this.inAdBreak()) {
            return; // exit
          }
          this.timerFinished = true;
        }, 5000);
      });
    }
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },



});
