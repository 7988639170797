























import Vue from 'vue';
import {mapGetters, mapState} from 'vuex';

export default Vue.extend({
  data() {
    return {
    };
  },

  computed: {
    ...mapState({
      showEndScreen: (state: any) => state.videoPlayerEndScreen.showEndScreen,
    }),
    ...mapGetters({
      nextEpisode: 'videoPlayerCatalog/nextEpisode',
      currentEpisode: 'videoPlayerCatalog/currentEpisode',
      autoMarathon: 'autoMarathon',
    }),
  },
});
