

































































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import NextEpisodeCountdown from './NextEpisodeCountdown.vue';
import NextEpisodeMismatch from './NextEpisodeMismatch.vue';
import NextEpisodePaywallError from './NextEpisodePaywallError.vue';
import NextEpisodeMatchedLocked from './NextEpisodeMatchedLocked.vue';
import RewatchEpisode from './RewatchEpisode.vue';
import NextEpisode from './NextEpisode.vue';
import EpisodeCard from './EpisodeCard.vue';


export default Vue.extend({
  name: 'video-player-end-screen',

  components: {
    NextEpisodeCountdown,
    NextEpisodeMismatch,
    NextEpisodePaywallError,
    NextEpisodeMatchedLocked,
    RewatchEpisode,
    NextEpisode,
    EpisodeCard,
  },

  data() {
    return {
      showNextEpisodeCountdown: false,
    };
  },

  computed: {
    ...mapState({
      showEndScreen: (state: any) => state.videoPlayerEndScreen.showEndScreen,
    }),

    ...mapGetters({
      autoMarathon: 'autoMarathon',
      currentEpisode: 'videoPlayerCatalog/currentEpisode',
      nextEpisode: 'videoPlayerCatalog/nextEpisode',
      nextEpisodeExperienceMatches: 'videoPlayerCatalog/nextEpisodeExperienceMatches',
      altNextEpisodeExperience: 'videoPlayerCatalog/altNextEpisodeExperience',
      nextEpisodeSVODPaywallError: 'videoPlayerCatalog/nextEpisodeSVODPaywallError',
      nextEpisodeESTOnlyPaywallError: 'videoPlayerCatalog/nextEpisodeESTOnlyPaywallError',
      matchingNextEpisodeExperienceIsSVODLocked: 'videoPlayerCatalog/matchingNextEpisodeExperienceIsSVODLocked',
      matchingNextEpisodeExperienceIsESTOnlyLocked: 'videoPlayerCatalog/matchingNextEpisodeExperienceIsESTOnlyLocked'
    }),

    orientationPortrait(): boolean {
      return this.$vuetify.breakpoint.height > this.$vuetify.breakpoint.width;
    },

    showNextEpisode(): boolean {
      return Boolean(
        this.nextEpisode &&
        this.nextEpisodeExperienceMatches &&
        !this.showNextEpisodeCountdown
      );
    },

    showNextEpisodeMismatch(): boolean {
      return Boolean(
        this.nextEpisode &&
        this.altNextEpisodeExperience &&
        !this.nextEpisodeSVODPaywallError &&
        !this.nextEpisodeESTOnlyPaywallError &&
        !this.nextEpisodeExperienceMatches &&
        !this.matchingNextEpisodeExperienceIsSVODLocked &&
        !this.matchingNextEpisodeExperienceIsESTOnlyLocked
      );
    },

    showNextEpisodePaywallError(): boolean {
      return Boolean(
        this.nextEpisode &&
        (this.nextEpisodeSVODPaywallError || this.nextEpisodeESTOnlyPaywallError)
      );
    },

    showNextEpisodeMatchedLocked(): boolean {
      return Boolean(
        this.nextEpisode &&
        (this.matchingNextEpisodeExperienceIsSVODLocked || this.matchingNextEpisodeExperienceIsESTOnlyLocked)
      );
    },

    showRewatchEpisode(): boolean {
      return Boolean(
        !this.nextEpisode &&
        this.currentEpisode
      );
    },
  },

  watch: {
    showEndScreen() {
      // Show Countdown?
      if (
        this.showEndScreen &&
        this.autoMarathon &&
        this.nextEpisode &&
        this.nextEpisodeExperienceMatches
      ) {
        this.showNextEpisodeCountdown = true;
      }
    },
  },
});
