














































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

const COUNTDOWN_SECONDS = 5;

export default Vue.extend({
  name: 'NextEpisodeCountdown',

  data() {
    return {
      countdownSeconds: COUNTDOWN_SECONDS,
      timer: null as any,
    };
  },

  computed: {
    ...mapState({
      showEndScreen: (state: any) => state.videoPlayerEndScreen.showEndScreen,
    }),

    ...mapGetters({
      currentShowSlug: 'videoPlayerCatalog/currentShowSlug',
      nextEpisodeSlug: 'videoPlayerCatalog/nextEpisodeSlug',
    }),

    circleValue(): number {
      return this.countdownSeconds / COUNTDOWN_SECONDS * 100;
    },

    nextEpisodeHref(): string {
      const origin = window.location.origin; // "https://www.funimation.com"
      const routerBase = this.$router.options.base; // "/v/"" or "/"
      const showSlug = this.currentShowSlug;
      const episodeSlug = this.nextEpisodeSlug;

      return origin + routerBase + `${showSlug}/${episodeSlug}`;
    },

    orientationPortrait(): boolean {
      return this.$vuetify.breakpoint.height > this.$vuetify.breakpoint.width;
    },
  },

  mounted() {
    this.countdownEpisode();
  },

  methods: {
    startNextEpisode() {
      // @ts-expect-error using legacy API
      window.location = this.nextEpisodeHref;
    },

    cancelCountdown() {
      clearTimeout(this.timer);
      this.countdownSeconds = COUNTDOWN_SECONDS;
      this.$emit('close');
    },

    countdownEpisode() {
      if (this.countdownSeconds > 0) {
        this.timer = setTimeout(() => {
          this.countdownSeconds -= 1;
          this.countdownEpisode();
        }, 1000);
      } else {
        // TODO: Is this timeout needed?
        setTimeout(() => {
          this.startNextEpisode();
        }, 600);
      }
    },
  },
});
