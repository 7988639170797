/* eslint-disable @typescript-eslint/no-use-before-define */
import { setNextEpisodeWatchers } from './nextEpisode';


export function setCatalogWatchers(context: any) {
  let currentEpisodeSlugWatchRef: () => void;
  let currentShowIdWatchRef: () => void;
  let currentEpisodeIdWatchRef: () => void;
  let continueWatchingRequiredStateWatchRef: () => void;
  let showPlaylistWatchRef: () => void;
  let nextEpisodeIdWatchRef: () => void;
  let nextEpisodeVideosWatchRef: () => void;
  let currentEpisodeVideosWatchRef: () => void;
  let selectedSpokenLanguageWatchRef: () => void;
  let selectedVersionWatchRef: () => void;

  context.dispatch('videoPlayer/modulesRegister', {
    name: 'video-player-catalog',

    install() {
      // First, get episode metadata and episode content id when the current episode slug is available.
      // Content id is needed to get current episode videos.
      /* getCurrentEpisode */
      currentEpisodeSlugWatchRef = watchThenGetCurrentEpisodeMetadata(context);

      // use content id to get videos for current episode.
      /* getCurrentEpisodeVideos */
      currentEpisodeIdWatchRef = watchThenGetCurrentEpisodeVideos(context);

      // use current episode videos to get selected video.
      /* getSelectedVideo */
      currentEpisodeVideosWatchRef = context.watch(
        function validateGetSelectedVideoRequiredState() {
          return getSelectedVideoRequiredStateValidate(context);
        },
        function getSelectedVideo() {
          if (!getSelectedVideoRequiredStateValidate(context)) {
            return;
          }

          context.dispatch('videoPlayerCatalog/getSelectedVideo');

          ({
            selectedSpokenLanguageWatchRef,
            selectedVersionWatchRef
          } = setUpMatrixWatchers(context));
        }
      );

      // use venue id to get continue watching data if user is logged in.
      /* getContinueWatchingData */
      continueWatchingRequiredStateWatchRef = watchThenGetContinueWatching(context);

      ({
        currentShowIdWatchRef,
        showPlaylistWatchRef,
        nextEpisodeIdWatchRef,
        nextEpisodeVideosWatchRef
      } = setNextEpisodeWatchers(context));
    },

    destroy() {
      if (currentEpisodeSlugWatchRef) {
        currentEpisodeSlugWatchRef();
      }
      if (currentShowIdWatchRef) {
        currentShowIdWatchRef();
      }
      if (currentEpisodeIdWatchRef) {
        currentEpisodeIdWatchRef();
      }
      if (continueWatchingRequiredStateWatchRef) {
        continueWatchingRequiredStateWatchRef();
      }
      if (currentEpisodeVideosWatchRef) {
        currentEpisodeVideosWatchRef();
      }
      if (showPlaylistWatchRef) {
        showPlaylistWatchRef();
      }
      if (nextEpisodeIdWatchRef) {
        nextEpisodeIdWatchRef();
      }
      if (nextEpisodeVideosWatchRef) {
        nextEpisodeVideosWatchRef();
      }
      if (selectedSpokenLanguageWatchRef) {
        selectedSpokenLanguageWatchRef();
      }
      if (selectedVersionWatchRef) {
        selectedVersionWatchRef();
      }
    },
  });
}

function watchThenGetCurrentEpisodeMetadata(context: Record<string, any>) {
  return context.watch(
    function validateEpisodeSlug() {
      return episodeSlugValidate(context);
    },
    function getCurrentEpisodeMetadata() {
      if (!episodeSlugValidate(context)) {
        return;
      }

      context.dispatch(
        'videoPlayerCatalog/getEpisodeData',
        {
          contentId: context.state.route.params.episodeSlug,
          episodeToGet: 'currentEpisode'
        }
      );
    },
    { immediate: true }
  );
}

function episodeSlugValidate(context: Record<string, any>) {
  return context.state.route?.params?.episodeSlug;
}

function watchThenGetCurrentEpisodeVideos(context: Record<string, any>) {
  return context.watch(
    function validateGetCurrentEpisodeVideosRequiredState() {
      return getCurrentEpisodeVideosRequiredStateValidate(context);
    },
    async function getCurrentEpisodeVideos() {
      if (!getCurrentEpisodeVideosRequiredStateValidate(context)) {
        return;
      }

      await context.dispatch('fetchInitialVideoPlayerSettings');

      context.dispatch(
        'videoPlayerCatalog/getEpisodeVideos',
        {
          episodeToGet: 'currentEpisode'
        }
      );
    },
    { immediate: true }
  );
}

function getCurrentEpisodeVideosRequiredStateValidate(context: Record<string, any>) {
  return context.getters['videoPlayerCatalog/currentEpisodeId'] &&
    context.state.userInfo?.region &&
    context.state.userInfo?.userDataReady;
}

function watchThenGetContinueWatching(context: Record<string, any>) {
  return context.watch(
    function validateContinueWatchingRequiredState() {
      return continueWatchingRequiredStateValidate(context);
    },
    function getContinueWatchingData() {
      if (!continueWatchingRequiredStateValidate(context)) {
        return;
      }

      context.dispatch('videoPlayerCatalog/getContinueWatchingData');
    },
    { immediate: true }
  );
}

function continueWatchingRequiredStateValidate(context: Record<string, any>) {
  return context.getters['videoPlayerCatalog/currentEpisodeVenueId'] &&
    context.state.userInfo?.token &&
    context.state.userInfo?.region;
}

function getSelectedVideoRequiredStateValidate(context: Record<string, any>) {
  return Array.isArray(context.getters['videoPlayerCatalog/currentEpisodeVideos'])
    && context.getters['videoPlayerCatalog/currentShowSlug'];
}

function setUpMatrixWatchers(context: Record<string, any>) {
  const selectedSpokenLanguageWatchRef = context.watch(
    function validateSelectedSpokenLanguage() {
      return context.getters.selectedSpokenLanguage;
    },
    function getSelectedVideoOnSpokenLanguageChange() {
      if (!context.getters.selectedSpokenLanguage) {
        return;
      }

      context.dispatch('videoPlayerCatalog/getSelectedVideo');
      context.dispatch('videoPlayerCatalog/getNextEpisodeExperienceMatches');
      if (context.getters['videoPlayerCatalog/selectedVideoId']) {
        context.dispatch(
          'setPlaybackProgress',
          {
            deviceType: 'web',
            checkpoint: Math.floor(context.state.videoPlayerCore.timeCurrent),
            lang: context.getters.selectedSpokenLanguage,
            videoId: context.getters['videoPlayerCatalog/selectedVideoId'],
          }
        );
      }
    }
  );

  const selectedVersionWatchRef = context.watch(
    function validateSelectedVersion() {
      return context.getters.selectedVersion;
    },
    function getSelectedVideoOnVersionChange() {
      if (!context.getters.selectedVersion) {
        return;
      }

      context.dispatch('videoPlayerCatalog/getSelectedVideo');
      context.dispatch('videoPlayerCatalog/getNextEpisodeExperienceMatches');
      if (context.getters['videoPlayerCatalog/selectedVideoId']) {
        context.dispatch(
          'setPlaybackProgress',
          {
            deviceType: 'web',
            checkpoint: Math.floor(context.state.videoPlayerCore.timeCurrent),
            lang: context.getters.selectedSpokenLanguage,
            videoId: context.getters['videoPlayerCatalog/selectedVideoId'],
          }
        );
      }
    }
  );

  return {
    selectedSpokenLanguageWatchRef,
    selectedVersionWatchRef
  };
}
