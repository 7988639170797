import { render, staticRenderFns } from "./FilterSortPanel.vue?vue&type=template&id=598cb8d8&scoped=true&"
import script from "./FilterSortPanel.vue?vue&type=script&lang=ts&"
export * from "./FilterSortPanel.vue?vue&type=script&lang=ts&"
import style0 from "./FilterSortPanel.vue?vue&type=style&index=0&id=598cb8d8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "598cb8d8",
  null
  
)

/* custom blocks */
import block0 from "./FilterSortPanel.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardActions,VCardText,VChip,VChipGroup,VDivider,VIcon,VNavigationDrawer,VSpacer})
