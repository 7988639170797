import { Module } from 'vuex';
import {
  RootState,
  StoreConfig,
  VideoPlayerCatalogState
} from './types';
import {
  setCatalogWatchers
} from './watchers';
import getters from './getters';
import mutations from './mutations';
import createActions from './actions';


const setupStore = (context: Record<string, any>, config: StoreConfig) => {
  if (!config || !config.titleServiceBase || !config.catalogProjectorServiceBase || !config.playbackServiceBase || !config.playlistServiceBase) {
    throw new TypeError('config must contain titleServiceBase, catalogProjectorServiceBase, playbackServiceBase, and playlistServiceBase');
  }

  const builtConfig = {
    titleServiceBase: `${config.titleServiceBase}/v1`,
    catalogProjectorServiceBase: config.catalogProjectorServiceBase,
    playbackServiceBase: `${config.playbackServiceBase}/v1`,
    playlistServiceBase: `${config.playlistServiceBase}/v1`
  };

  const actions = createActions(builtConfig);

  const store: Module<VideoPlayerCatalogState, RootState> = {
    namespaced: true,
    state: {
      currentEpisode: null,
      showPlaylist: null,
      currentEpisodeVideos: null,
      selectedVideo: null,
      nextEpisode: null,
      nextEpisodeVideos: null,
      continueWatching: null,
      nextEpisodeSVODPaywallError: null,
      nextEpisodeESTOnlyPaywallError: null,
      nextEpisodeExperienceMatches: null,
      matchingNextEpisodeExperienceIsSVODLocked: null,
      matchingNextEpisodeExperienceIsESTOnlyLocked: null,
      altNextEpisodeExperience: null,
      aips: [],
    },
    getters: {
      ...getters,
      catalogProjectorBase(): string {
        return config.catalogProjectorServiceBase;
      }
    },
    actions: {
      ...actions
    },
    mutations: {
      ...mutations
    },
  };

  context.registerModule('videoPlayerCatalog', store);
  setCatalogWatchers(context);
};

export default (context: Record<string, any>, config: StoreConfig) => {
  setupStore(context, config);
  return {
    install() {
      //
    },
  };
};
