import setupFirebase from './firebase';
import RemoteConfigViewer from './RemoteConfigViewer.vue';
export default async (context: any, config: Record<string, any> = {}) => {
  let firebase;
  try {
    firebase = await setupFirebase(config);
  } catch (err) {
    const er = new Error('Error in firebase wrapper: ' + err);
    context.dispatch('error', er, {root: true});
    return console.error(er);
  }
  const analytics = firebase.analytics();
  const remoteConfig = firebase.remoteConfig();
  // for debugging only, enable this line to cause configs to refresh quickly
  // remoteConfig.settings.minimumFetchIntervalMillis = 10000;
  const store = {
    state: {
      ready: false,
      remoteConfig,
      firebase,
      analytics,
    },
    mutations: {
      SET_FIREBASE_KEY(state: Record<string, any>, payload: Record<string, any>) {
        state[payload.key] = payload.value;
      },
      SET_FIREBASE_READY(state: Record<string, any>) {
        state.ready = true;
      },
    },
    getters: {
      getValue() {
        return (key: string) => {
          let value;
          try {
            value = remoteConfig.getValue(key);
          } catch (err) {
            const er = new Error('Error in getValue firebase lookup: ' + err);
            console.error(er);
          }
          context.commit('SET_FIREBASE_KEY', {
            key,
            value: value._value,
          });
          return value._value;
        };
      },
    },
  };
  // add default keys to state to promote responsiveness
  const defaultRemoteConfig = config.defaultRemoteConfg || {};
  remoteConfig.defaultConfig = defaultRemoteConfig;
  Object.keys(defaultRemoteConfig).forEach((key: string) => {
    (store.state as any)[key] = defaultRemoteConfig[key];
  });
  context.registerModule('config', store);
  try {
    await remoteConfig.fetchAndActivate();
  } catch (err) {
    const er = new Error('Error in firebase fetchAndActivate: ' + err);
    context.dispatch('error', er, {root: true});
    return {};
  }
  context.commit('SET_FIREBASE_READY');
  return {
    install(Vue: any) {
      Vue.component('remote-config-viewer', RemoteConfigViewer);
    },
  };
};
