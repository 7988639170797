

























import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapState({
      isMuted: (state: any) => state.videoPlayerCore.isMuted,
      isPaused: (state: any) => state.videoPlayerCore.isPaused,
      isFullScreen: (state: any) => state.videoPlayerCore.isFullScreen,
      timeCurrent: (state: any) => state.videoPlayerCore.timeCurrent,
      timeTotal: (state: any) => state.videoPlayerCore.timeTotal,
      volumeLevelDecimal: (state: any) => state.videoPlayerCore.volumeLevel,
    }),
  },
});
