import {
  GetEpisodeVideosPayload,
  GetEpisodeDataPayload,
  PlayResponse,
  StoreConfig,
  TitleEpisode,
  VideoSource
} from '../types';
import {
  calculateWhetherEpisodeExperienceMatches,
  markCorruptVideos,
  getNextEpisodeId,
  getSelectedVideoForUser
} from './helpers';
import {
  getContinueWatchingData,
  getEpisodeData,
  getEpisodeVideos,
  getShowPlaylist
} from '../api';


export default function createActions(config: StoreConfig) {
  return {
    setAips(context: Record<string, any>, payload: Record<string, any>[]): void {
      context.commit('SET_AIPS', payload);
    },
    async getEpisodeData(context: Record<string, any>, payload: GetEpisodeDataPayload): Promise<void> {
      let contentId;
      if (payload.episodeToGet === 'currentEpisode') {
        contentId = payload.contentId;
      } else if (payload.episodeToGet === 'nextEpisode') {
        contentId = getNextEpisodeId(context);
      }

      if (!contentId) {
        if (payload.episodeToGet === 'currentEpisode') {
          console.error('getCurrentEpisode: episode slug is missing');
          context.dispatch('videoPlayerModal/dispatchContentNotFoundError', undefined, { root: true });
        }

        return;
      }

      const episodeData: TitleEpisode | undefined = await getEpisodeData(
        config.catalogProjectorServiceBase,
        context,
        contentId,
        payload.episodeToGet === 'currentEpisode'
      );
      if (!episodeData) {
        if (payload.episodeToGet === 'currentEpisode') {
          console.error('getCurrentEpisode: episode data is missing');
          context.dispatch('videoPlayerModal/dispatchContentNotFoundError', undefined, { root: true });
        } else if (payload.episodeToGet === 'nextEpisode') {
          console.error('getNextEpisode: episode data is missing');
        }
        return;
      }

      if (payload.episodeToGet === 'currentEpisode') {
        if (!episodeData.id) {
          console.error(`CATALOG PROJECTION ERROR: episode with slug: ${payload.contentId} has no content id`);
        }
        context.commit('SET_CURRENT_EPISODE', episodeData);
      } else if (payload.episodeToGet === 'nextEpisode') {
        context.commit('SET_NEXT_EPISODE', episodeData);
      }
    },
    async getContinueWatchingData(context: Record<string, any>): Promise<void> {
      const episodeVenueId = context.getters.currentEpisodeVenueId;
      if (!episodeVenueId) {
        console.warn('getContinueWatchingData: no episode venue id found');
        return;
      }

      const continueWatchingData = await getContinueWatchingData(
        config.titleServiceBase,
        episodeVenueId,
        context
      );
      if (!continueWatchingData) {
        console.warn('getContinueWatchingData: no continue watching data');
        return;
      }

      context.commit('SET_CONTINUE_WATCHING', continueWatchingData);
    },
    async getEpisodeVideos(context: Record<string, any>, payload: GetEpisodeVideosPayload): Promise<void> {
      let episodeId;
      if (payload.episodeToGet === 'currentEpisode') {
        episodeId = context.getters.currentEpisodeId;
      } else if (payload.episodeToGet === 'nextEpisode') {
        episodeId = context.getters.nextEpisodeId;
      }

      if (!episodeId) {
        if (payload.episodeToGet === 'currentEpisode') {
          context.dispatch('videoPlayerModal/dispatchTechnicalError', undefined, { root: true });
          console.warn('getCurrentEpisodeVideos: no episode id found');
        } else if (payload.episodeToGet === 'nextEpisode') {
          console.warn('getNextEpisodeVideos: no episode id found');
        }
        return;
      }

      const videos: PlayResponse | undefined = await getEpisodeVideos(
        payload.episodeToGet,
        episodeId,
        context.rootState.userInfo?.token,
        config.playbackServiceBase,
        context,
        payload.episodeToGet === 'currentEpisode',
        context.rootGetters.isUserPremium
      );
      if (!videos) {
        return;
      }

      let primaryVideos: VideoSource[] = [];
      if (
        typeof videos?.primary === 'object' &&
        videos?.primary?.errorName === undefined
      ) {
        primaryVideos = [ videos.primary ];
      }
      const fallbackVideos: VideoSource[] = Array.isArray(videos.fallback) ? videos.fallback : [];
      const videoSources: VideoSource[] = primaryVideos.concat(fallbackVideos);

      if (
        videoSources.length < 1
      ) {
        if (payload.episodeToGet === 'currentEpisode') {
          context.dispatch('videoPlayerModal/dispatchTechnicalError', undefined, { root: true });
          console.error(`getCurrentEpisodeVideos: No videoSources found for ${episodeId}`);
        } else if (payload.episodeToGet === 'nextEpisode') {
          console.error(`getNextEpisodeVideos: No videoSources found for ${episodeId}`);
        }

        return;
      }

      if (payload.episodeToGet === 'currentEpisode') {
        const markedVieos = markCorruptVideos(videoSources, episodeId);
        const unCorruptedVideos = markedVieos.filter(video => !video.corruptVideo);
        if (unCorruptedVideos.length < 1) {
          console.error(`ALL VIDEOS CORRUPTED ERROR: All videos are corrupted for episode with id: ${episodeId}`);
        }

        context.commit('SET_CURRENT_EPISODE_VIDEOS', markedVieos);

        if (typeof videos.primary === 'object') {
          context.commit('SET_SELECTED_SPOKEN_LANGUAGE', videos.primary.audioLanguage, { root: true });
        }
      } else if (payload.episodeToGet === 'nextEpisode') {
        context.commit('SET_NEXT_EPISODE_VIDEOS', videoSources);
      }
    },
    getSelectedVideo(context: Record<string, any>): void {
      const currentEpisodeVideos: VideoSource[] = context.getters.currentEpisodeVideos;

      if (!Array.isArray(currentEpisodeVideos)) {
        context.dispatch('videoPlayerModal/dispatchTechnicalError', undefined, { root: true });
        console.warn('Current Episode Videos not found while selecting video');
        return;
      }
      if (currentEpisodeVideos.length < 1) {
        context.dispatch('videoPlayerModal/dispatchTechnicalError', undefined, { root: true });
        console.warn('current episode videos is empty');
        return;
      }

      const selectedSpokenLanguage = context.rootGetters.selectedSpokenLanguage;
      const selectedVersion = context.rootGetters.selectedVersion;
      const setSelectedSpokenLanguage = (newSpokenLanguage: string) => context.dispatch('setSelectedSpokenLanguage', newSpokenLanguage, { root: true });
      const setSelectedVersion = (newVersion: string) => context.dispatch('setSelectedVersion', newVersion, { root: true });
      const dispatchCorruptVideoError = () => context.dispatch('videoPlayerModal/dispatchCorruptVideoError', undefined, { root: true });
      const result: VideoSource | undefined = getSelectedVideoForUser(
        currentEpisodeVideos,
        selectedSpokenLanguage,
        setSelectedSpokenLanguage,
        selectedVersion,
        setSelectedVersion,
        dispatchCorruptVideoError
      );

      if (!result) {
        console.warn('getSelectedVideo: No selected video found');
        return;
      }

      context.commit('SET_SELECTED_VIDEO', result);
    },
    async getShowPlaylist(context: Record<string, any>, showId: string): Promise<void> {
      const playlist = await getShowPlaylist(config.playlistServiceBase, showId);
      if (!playlist) {
        return;
      }

      context.commit('SET_SHOW_PLAYLIST', playlist);
    },
    getNextEpisodeExperienceMatches(context: Record<string, any>): void {
      const nextEpisodeVideos = context.state.nextEpisodeVideos;
      if (!Array.isArray(nextEpisodeVideos)) {
        return;
      }

      const nextEpisodeData = calculateWhetherEpisodeExperienceMatches(
        nextEpisodeVideos,
        context.getters.nextEpisodeSpokenLanguages,
        context.getters.nextEpisodeVersions,
        context.rootGetters.selectedSpokenLanguage,
        context.rootGetters.selectedVersion
      );

      context.commit('SET_NEXT_EPISODE_EXPERIENCE_MATCHES', nextEpisodeData.hasMatchingExperience);
      context.commit('SET_MATCHING_NEXT_EPISODE_EXPERIENCE_IS_SVOD_LOCKED', nextEpisodeData.matchingNextEpisodeExperienceIsSVODLocked);
      context.commit('SET_MATCHING_NEXT_EPISODE_EXPERIENCE_IS_EST_ONLY_LOCKED', nextEpisodeData.matchingNextEpisodeExperienceIsESTOnlyLocked);
      if (nextEpisodeData.alternateExperience) {
        context.commit('SET_ALT_NEXT_EPISODE_EXPERIENCE', nextEpisodeData.alternateExperience);
      }
    },
  };
}
