import {
  BackendLanguage,
  PlayerSpokenLanguage,
  PlayerSubtitleLanguage,
  PlayerVersion,
  SubtitleSource,
  VideoSource
} from '../types';
import { compareStrings } from '@funimation/comp-utils';


export function getLanguageTranslation(
  language: string,
  languageTranslations: BackendLanguage[],
  userLanguage: string
): string | undefined {
  const languageTranslationData = languageTranslations.find(
    (lang: BackendLanguage) => compareStrings(lang.languageCode, language)
  )?.name ?? {};
  const userLanguageKey = (userLanguage || '').toLowerCase();
  const languageName = languageTranslationData[userLanguageKey];

  return languageName;
}

export function getUnlockedSpokenLanguages(
  videos: VideoSource[],
  audioLanguageTranslations: BackendLanguage[],
  userLanguage: string
) {
  const unlockedSpokenLanguages: PlayerSpokenLanguage[] = [];

  videos.forEach(video => {
    const spokenLanguageAlreadyPresent = unlockedSpokenLanguages.some(unlockedLanguage => compareStrings(unlockedLanguage.languageCode, video.audioLanguage));

    if (!spokenLanguageAlreadyPresent) {
      const languageName = getLanguageTranslation(
        video.audioLanguage,
        audioLanguageTranslations,
        userLanguage
      );

      unlockedSpokenLanguages.push({
        id: undefined,
        languageCode: video.audioLanguage,
        name: languageName ?? '',
        isLocked: false
      });
    }
  });

  return unlockedSpokenLanguages;
}

export function addLockedSpokenLanguages(
  unlockedSpokenLanguages: PlayerSpokenLanguage[],
  videoAvailability: Record<string, any>,
  region: string,
  selectedVersion: string,
  audioLanguageTranslations: BackendLanguage[],
  userLanguage: string,
  accessType = 'SVOD'
) {
  // create array of available spoken languages based off videoAvailablity
  const availableSpokenLanguagesDict = videoAvailability?.all?.[region]?.[accessType]?.[selectedVersion] ?? {};

  const availableSpokenLanguages: string[] = [];
  for (const [ possibleSpokenLanguageCode ] of Object.entries(availableSpokenLanguagesDict)) {
    if (
      possibleSpokenLanguageCode === 'start' ||
      possibleSpokenLanguageCode === 'end' ||
      possibleSpokenLanguageCode === 'alphaPackageIds' ||
      possibleSpokenLanguageCode === 'downloadableAlphaPackageIds'
    ) {
      continue;
    }

    availableSpokenLanguages.push(possibleSpokenLanguageCode);
  }

  // assume that if an available spoken language is not already present in the unlocked array, then it is locked
  // add the spoken language to the array as locked.
  availableSpokenLanguages.forEach((language) => {
    const spokenLanguageAlreadyPresent = unlockedSpokenLanguages.some(unlockedLanguage => compareStrings(unlockedLanguage.languageCode, language));

    if (!spokenLanguageAlreadyPresent) {
      const languageName = getLanguageTranslation(
        language,
        audioLanguageTranslations,
        userLanguage
      );

      unlockedSpokenLanguages.push({
        id: undefined,
        languageCode: language,
        name: languageName ?? '',
        isLocked: true,
        lockedReason: accessType
      });
    }
  });
}

export function getUnlockedSubtitleLanguages(
  subtitleSources: SubtitleSource[],
  subtitleLanguageTranslations: BackendLanguage[],
  userLanguage: string
) {
  const unlockedSubtitleLanguages: PlayerSubtitleLanguage[] = [];

  subtitleSources.forEach((subtitle: SubtitleSource) => {
    const subtitleLanguageAlreadyPresent = unlockedSubtitleLanguages.some(unlockedLanguage => compareStrings(unlockedLanguage.languageCode, subtitle.languageCode));

    if (!subtitleLanguageAlreadyPresent) {
      const languageName = getLanguageTranslation(
        subtitle.languageCode,
        subtitleLanguageTranslations,
        userLanguage
      );

      unlockedSubtitleLanguages.push({
        id: undefined,
        languageCode: subtitle.languageCode,
        name: languageName ?? '',
        isLocked: false
      });
    }
  });

  return unlockedSubtitleLanguages;
}
  
export function addLockedSubtitleLanguages(
  unlockedSubtitleLanguages: PlayerSubtitleLanguage[],
  videoAvailability: Record<string, any>,
  region: string,
  selectedVersion: string,
  selectedSpokenLanguage: string,
  subtitleLanguageTranslations: BackendLanguage[],
  userLanguage: string,
  accessType = 'SVOD'
) {
  // create array of available subtitle languages based off videoAvailablity
  const availableSubtitleLanguagesDict = videoAvailability?.all?.[region]?.[accessType]?.[selectedVersion]?.[selectedSpokenLanguage] ?? {};

  const availableSubtitleLanguages: string[] = [];
  for (const [ possibleSubtitleLanguageCode ] of Object.entries(availableSubtitleLanguagesDict)) {
    if (
      possibleSubtitleLanguageCode === 'start' ||
      possibleSubtitleLanguageCode === 'end' ||
      possibleSubtitleLanguageCode === 'alphaPackageIds' ||
      possibleSubtitleLanguageCode === 'downloadableAlphaPackageIds'
    ) {
      continue;
    }

    availableSubtitleLanguages.push(possibleSubtitleLanguageCode);
  }

  // assume that if an available subtitle language is not already present in the unlocked array, then it is locked
  // add the subtitle language to the array as locked.
  availableSubtitleLanguages.forEach((language) => {
    const subtitleLanguageAlreadyPresent = unlockedSubtitleLanguages.some(unlockedLanguage => compareStrings(unlockedLanguage.languageCode, language));

    if (!subtitleLanguageAlreadyPresent) {
      const languageName = getLanguageTranslation(
        language,
        subtitleLanguageTranslations,
        userLanguage
      );

      unlockedSubtitleLanguages.push({
        id: undefined,
        languageCode: language,
        name: languageName ?? '',
        isLocked: true,
        lockedReason: accessType
      });
    }
  });
}

export function getUnlockedVersions(videos: VideoSource[], selectedSpokenLanguage: string) {
  const returnedVersions: PlayerVersion[] = [];
  
  videos = videos.filter(video => compareStrings(video.audioLanguage, selectedSpokenLanguage));
  videos.forEach((video: VideoSource) => {
    const versionAlreadyPresent = returnedVersions.some(unlockedVersion => compareStrings(unlockedVersion.version, video.version));

    if (!versionAlreadyPresent) {
      returnedVersions.push({
        version: video.version,
        isLocked: false
      });
    }
  });
  
  return returnedVersions;
}

export function addLockedVersions(
  unlockedVersions: PlayerVersion[],
  videoAvailability: Record<string, any>,
  region: string,
  selectedSpokenLanguage: string,
  accessType = 'SVOD'
) {
  // create array of available subtitle languages based off videoAvailablity
  const availableVersionsDict = videoAvailability?.all?.[region]?.[accessType] ?? {};

  const availableVersions: string[] = [];
  for (const [ possibleVersion, languagesForVersion ] of Object.entries(availableVersionsDict)) {
    if (
      possibleVersion === 'start' ||
      possibleVersion === 'end' ||
      possibleVersion === 'alphaPackageIds' ||
      possibleVersion === 'downloadableAlphaPackageIds'
    ) {
      continue;
    }

    const languageKeysForVerson = Object.keys(languagesForVersion as object);
    const languageMatchForVersion = languageKeysForVerson.some(languageKey => compareStrings(languageKey, selectedSpokenLanguage));

    if (languageMatchForVersion) {
      availableVersions.push(possibleVersion);
    }
  }

  // assume that if an available version is not already present in the unlocked array, then it is locked
  // add the version to the array as locked.
  availableVersions.forEach((version) => {
    const versionAlreadyPresent = unlockedVersions.some(unlockedVersion => compareStrings(unlockedVersion.version, version));

    if (!versionAlreadyPresent) {
      unlockedVersions.push({
        version,
        isLocked: true,
        lockedReason: accessType
      });
    }
  });
}

export function convertAccessType(rawAccessType: string): string {
  if (compareStrings(rawAccessType, 'ad-based')) {
    return 'AVOD';
  }
  if (compareStrings(rawAccessType, 'digital copy') || compareStrings(rawAccessType, 'digital-copy')) {
    return 'EST';
  }
  if (compareStrings(rawAccessType, 'subscription')) {
    return 'SVOD';
  }

  return rawAccessType;
}
  