import { render, staticRenderFns } from "./VideoPlayerControls.vue?vue&type=template&id=3519bc20&scoped=true&"
import script from "./VideoPlayerControls.vue?vue&type=script&lang=ts&"
export * from "./VideoPlayerControls.vue?vue&type=script&lang=ts&"
import style0 from "./VideoPlayerControls.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./VideoPlayerControls.vue?vue&type=style&index=1&id=3519bc20&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3519bc20",
  null
  
)

/* custom blocks */
import block0 from "./VideoPlayerControls.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VFadeTransition,VIcon,VSlider,VTooltip})
