import canAutoPlay from 'can-autoplay';
import videojs from 'video.js';

type SourceObject = videojs.Tech.SourceObject;
type SourceObjects = Array<videojs.Tech.SourceObject>;

type VideoInstance = {
    filePath: string;
    ext?: string;
};
type VideoInstances = Array<VideoInstance>;

const MIMETYPE_M3U8 = 'application/x-mpegURL';
const EXT_M3U8 = 'm3u8';


/** Normalize video sources format for Video.js */
export function normalizeToPlayerSources(selectedVideo: Record<string, any>): SourceObjects {
  if (!selectedVideo) {
    console.error(`selected video must be present: ${JSON.stringify(selectedVideo)}`);
    return [];
  }

  const normalizedSources = [];
  const src = selectedVideo.manifestPath;
  if (!src) {
    console.error(`selected video does not have a valid manifestPath: ${JSON.stringify(selectedVideo)}`);
  }

  // type is optional but recommended
  let type;
  const ext = selectedVideo.fileExt?.toLowerCase();
  if (ext) {
    type = ext === EXT_M3U8 ? MIMETYPE_M3U8 : `video/${ext}`;
  }

  normalizedSources.push({
    src,
    type,
  });

  return normalizedSources;
}

/**
 * Sort sources to ensure defined mimetypes are tried/played first before
 * missing mimetypes.
 */
export function sortSourcesTypesFirst(sources: SourceObjects): SourceObjects {
  // Copy to avoid side-effect mutations
  sources = JSON.parse(JSON.stringify(sources));

  sources.sort((a, b) => {
    // Prioritize defined mimetypes over missing mimetypes
    if (a.type && !b.type) {
      return -1; // leave a and b in the same order
    }

    if (!a.type && b.type) {
      return 1; // sort b before a
    }

    return 0; // leave a and b in the same order
  });

  return sources;
}

/**
 * Sort sources to ensure M3U8s (application/x-mpegURL) are tried/played first,
 * before other formats.
 */
export function sortSourcesM3U8sFirst(sources: SourceObjects): SourceObjects {
  // Copy to avoid side-effect mutations
  sources = JSON.parse(JSON.stringify(sources));

  sources.sort((a, b) => {
    const aIsM3U8 = a.type === MIMETYPE_M3U8 || a.src.includes(`.${EXT_M3U8}`);
    const bIsM3U8 = b.type === MIMETYPE_M3U8 || b.src.includes(`.${EXT_M3U8}`);

    // Prioritize M3U8s over other formats
    if (aIsM3U8 && !bIsM3U8) {
      return -1; // leave a and b in the same order
    }

    if (!aIsM3U8 && bIsM3U8) {
      return 1; // sort b before a
    }

    return 0; // leave a and b in the same order
  });

  return sources;
}

type AutoplayFeatures = {
  muted?: boolean;
};
/** Autoplay features test. */
export async function autoplayFeaturesTest(features: AutoplayFeatures): Promise<boolean> {
  try {
    const { result } = await canAutoPlay.video({
      inline: true,
      muted: Boolean(features.muted),
      timeout: 250, // Timeout for autoplay check. Default 250 ms.
    });
    return result;
  } catch (error) {
    console.warn('can-autoplay timed-out or erred.', error);
    return false;
  }
}

type AutoplaySupportResponse = {
  audioAllowed: boolean;
  autoplayAllowed: boolean;
};
/** Autoplay feature detection. */
export async function autoplayFeatureDetection(): Promise<AutoplaySupportResponse> {
  // Test unmuted autoplay
  const unmutedAutoplayAllowed = await autoplayFeaturesTest({
    muted: false,
  });
  if (unmutedAutoplayAllowed) {
    return {
      audioAllowed: true,
      autoplayAllowed: true,
    };
  }

  // Test muted autoplay
  const mutedAutoplayAllowed = await autoplayFeaturesTest({
    muted: true,
  });
  if (mutedAutoplayAllowed) {
    return {
      audioAllowed: false,
      autoplayAllowed: true,
    };
  }

  // Autoplay not allowed
  return {
    audioAllowed: true, // No reason to mute audio if autoplay is not allowed
    autoplayAllowed: false,
  };
}
