














































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'NextEpisodeMatchedLocked',

  computed: {
    ...mapGetters({
      altNextEpisodeExperienceSpokenLanguage: 'videoPlayerCatalog/altNextEpisodeExperienceSpokenLanguage',
      altNextEpisodeExperienceType: 'videoPlayerCatalog/altNextEpisodeExperienceType',
      currentShowSlug: 'videoPlayerCatalog/currentShowSlug',
      currentShowName: 'videoPlayerCatalog/currentShowName',
      nextEpisodeSlug: 'videoPlayerCatalog/nextEpisodeSlug',
      nextEpisodeType: 'videoPlayerCatalog/nextEpisodeType',
      selectedSpokenLanguage: 'selectedSpokenLanguage',
      selectedVersion: 'selectedVersion',
      matchingNextEpisodeExperienceIsSVODLocked: 'videoPlayerCatalog/matchingNextEpisodeExperienceIsSVODLocked',
      matchingNextEpisodeExperienceIsESTOnlyLocked: 'videoPlayerCatalog/matchingNextEpisodeExperienceIsESTOnlyLocked'
    }),
    nextEpisodeHref(): string {
      const origin = window.location.origin; // "https://www.funimation.com"
      const routerBase = this.$router.options.base; // "/v/"" or "/"
      const showSlug = this.currentShowSlug;
      const episodeSlug = this.nextEpisodeSlug;

      if (!origin || !routerBase || !showSlug || !episodeSlug) {
        return '';
      }

      return `${origin}${routerBase}${showSlug}/${episodeSlug}`;
    },
    upsellRedirectPayload(): Record<string, any> {
      const showSlug = this.currentShowSlug;
      const episodeSlug = this.nextEpisodeSlug;
      const showName = this.currentShowName;
      
      return {
        showSlug,
        episodeSlug,
        showName
      };
    }
  },

  methods: {
    ...mapActions([
      'setSelectedSpokenLanguage',
      'setSelectedVersion',
      'setUpsellRedirect',
      'setDigitalCopyUpsellRedirect'
    ]),
    goToAlternateVideo() {
      this.setSelectedSpokenLanguage(this.altNextEpisodeExperienceSpokenLanguage);
      this.setSelectedVersion(this.altNextEpisodeExperienceType);
      window.location.assign(this.nextEpisodeHref);
    },
  },

});
