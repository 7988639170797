export function dispatchPlayBackErrors(
  episodeToGet: string,
  context: Record<string, any>,
  error: Record<string, any>,
  episodeId: string,
  dispatchErrors: boolean
): Record<string, any> {
  const errorDispatched: {
    SVODError: boolean | undefined;
    ESTOnlyError: boolean | undefined;
    knownError: boolean | undefined;
  } = {
    SVODError: false,
    ESTOnlyError: false,
    knownError: false
  };

  if (
    error.response?.data?.statusCode === 600 
  ) {
    if (dispatchErrors) {
      context.dispatch('videoPlayerModal/dispatchPaywallError', undefined, { root: true });
    }
    console.error(`Paywall Error: getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);

    errorDispatched.knownError = true;
    errorDispatched.SVODError = true;
    return errorDispatched;
  }
  if (
    error.response?.data?.statusCode === 601
  ) {
    if (dispatchErrors) {
      context.dispatch('videoPlayerModal/dispatchDigitalCopyError', undefined, { root: true });
    }
    console.error(`Digital Copy Error: getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);
    
    errorDispatched.knownError = true;
    errorDispatched.ESTOnlyError = true;
    return errorDispatched;
  }
  if (
    error.response?.data?.statusCode === 602
  ) {
    if (dispatchErrors) {
      context.dispatch('videoPlayerModal/dispatchOutOfTerritoryError', undefined, { root: true });
    }
    console.error(`Out Of Territory Error: getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);
    
    errorDispatched.knownError = true;
    return errorDispatched;
  }
  if (
    error.response?.data?.statusCode === 603
  ) {
    if (dispatchErrors) {
      context.dispatch('videoPlayerModal/dispatchNotAvailableOnDeviceError', undefined, { root: true });
    }
    console.error(`Not Available On Device Error: getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);
    
    errorDispatched.knownError = true;
    return errorDispatched;
  }
  if (
    error.response?.data?.statusCode === 604
  ) {
    if (dispatchErrors) {
      context.dispatch('videoPlayerModal/dispatchContentNotFoundError', undefined, { root: true });
    }
    console.error(`Content Not Found Error: getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);
    
    errorDispatched.knownError = true;
    return errorDispatched;
  }
  if (
    error.response?.data?.statusCode === 606
  ) {
    if (dispatchErrors) {
      context.dispatch('videoPlayerModal/dispatchNotAvailableYetError', undefined, { root: true });
    }
    console.error(`Not Available Yet Error: getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);
    
    errorDispatched.knownError = true;
    return errorDispatched;
  }
  if (
    error.response?.data?.statusCode === 607
  ) {
    if (dispatchErrors) {
      context.dispatch('videoPlayerModal/dispatchNoLongerAvailableError', undefined, { root: true });
    }
    console.error(`No Longer Available Error: getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);
    
    errorDispatched.knownError = true;
    return errorDispatched;
  }
  if (
    error.response?.data?.statusCode === 608
  ) {
    if (dispatchErrors) {
      context.dispatch('videoPlayerModal/dispatchMatureContentError', undefined, { root: true });
    }
    console.error(`Mature Content Error: getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);
    
    errorDispatched.knownError = true;
    return errorDispatched;
  }
  if (
    error.response?.data?.statusCode === 609
  ) {
    if (dispatchErrors) {
      context.dispatch('videoPlayerModal/dispatchStreamLimitError', undefined, { root: true });
    }
    console.error(`Stream Limit Error: getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);
    
    errorDispatched.knownError = true;
    return errorDispatched;
  }

  if (dispatchErrors) {
    context.dispatch('videoPlayerModal/dispatchTechnicalError', undefined, { root: true });
  }
  console.error(`Error getting ${episodeToGet} videos with content id ${episodeId}: ${error}`);

  return errorDispatched;
}