/**
 * @fileoverview
 *
 * Values below must return enumerated values from
 * https://docs.videojs.com/tracks_text-track-settings.js.html
 */

export const fontColorMap: Record<string, string> = {
  undefined: '#FFF', // Default
  'white': '#FFF', // Default
  'black': '#000',
  'red': '#F00',
  'green': '#0F0',
  'yellow': '#FF0',
  'pink': '#F0F',
  'lightBlue': '#0FF',
  'darkBlue': '#00F',
};

export const lightColors: Array<string | undefined> = [
  undefined,
  'white',
  'red',
  'green',
  'yellow',
  'pink',
  'lightBlue',
];

export const backgroundColorMap: Record<string, string | undefined> = {
  undefined: undefined, // Default
  'none': undefined, // Default
  'white': '#FFF',
  'black': '#000',
  'red': '#F00',
  'green': '#0F0',
  'yellow': '#FF0',
  'pink': '#F0F',
  'lightBlue': '#0FF',
  'darkBlue': '#00F',
};

export const fontSizeMap: Record<string, number> = {
  undefined: 1.25, // Default
  'small': 1.00,
  'medium': 1.25, // Default
  'large': 1.5,
};
