
















































































































import Vue from 'vue';
import { mapActions } from 'vuex';
import { compareStrings } from '@funimation/comp-utils';


export default Vue.extend({
  name: 'auxiliary-panel',
  data() {
    return {
      hover: false,
    };
  },
  props: {
    toggleSearch: {
      type: Function,
    },
    isLoggedIn: {
      type: Boolean,
    },
    auxiliaryPanel: {
      type: Object,
      default: null,
    },
    userData: {
      type: Object,
    },
  },
  computed: {
    isCanada(): boolean {
      return compareStrings(window.region, 'ca');
    },
    isUs(): boolean {
      return compareStrings(window.region, 'us');
    },
    isNotUSCA(): boolean {
      return window.region.toLowerCase() != 'ca' && window.region.toLowerCase() != 'us';
    },
    avatar(): string {
      if (!this.userData || !this.userData.user || !this.userData.user.avatar) {
        return 'https://www.funimation.com/static/img/default-avatar.jpg';
      }
      return this.userData.user.avatar;
    },
  },
  methods: {
    ...mapActions([ 'logOutUser' ]),
  },
});
