/* eslint-disable @typescript-eslint/no-use-before-define */
import { BackendVideoCue, VideoCue } from '../types';


export function buildCues(
  cueType: string,
  videoCues: BackendVideoCue[],
  episodeDurationInSeconds: number | undefined
): VideoCue[] | null {
  const backendCues = filterCues(videoCues, cueType);
  if (backendCues.length < 1) {
    return null;
  }

  return backendCues.map((backendCue) => {
    const timeCodeInSeconds = timeCodeToSeconds(backendCue.timecode);
    const timecodePercentage = episodeDurationInSeconds ?
      (timeCodeInSeconds/episodeDurationInSeconds)*100 :
      null;

    return {
      _id: backendCue._id,
      timecode: backendCue.timecode,
      timecodeSeconds: timeCodeInSeconds,
      timecodePercentage: timecodePercentage,
      markerTypeId: backendCue.markerTypeId,
      markerTypeName: backendCue.markerTypeName,
      markerType: cueType,
    } as VideoCue;
  });
}

function filterCues(videoCues: BackendVideoCue[], type: string): BackendVideoCue[] {
  const expressionToMatch = new RegExp(type, 'i');
  
  return videoCues.filter((cue) => expressionToMatch.test(cue.markerTypeName));
}
  
function timeCodeToSeconds(timeCode: string): number {
  const values = timeCode.split(':');
  const hours = +values[0];
  const minutes = +values[1];
  const seconds = +values[2];
  
  const totalSeconds = (hours * 60 * 60)+ (minutes * 60) + seconds;
  
  return totalSeconds;
}

export function playerSeek(context: Record<string, any>, timeDelta: number) {
  if (!timeDelta) {
    return;
  }

  context.dispatch('videoPlayerCore/seek', timeDelta, {root: true});
}
