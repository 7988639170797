<template>
  <v-sheet
    color="#ffffff"
  >
    <v-container>
      <v-row>
        <v-col>
          <h1 class="text-h1 text-uppercase font-weight-bold text-center mt-8 black--text">FAQ</h1>
          <v-divider class="my-9" />
          <content-faq :data="faqs" :isLightTheme="isLightTheme" />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  data() {
    return {
      isLightTheme: true,
      faqs: [
        {
          question: 'Question 1',
          answer: 'Answer 1',
        },
        {
          question: 'Question 2',
          answer: 'Answer 2',
        },
        {
          question: 'Question 3',
          answer: 'Answer 3',
        },
        {
          question: 'Question 4',
          answer: 'Answer 4',
        },
        {
          question: 'Question 5',
          answer: 'Answer 5',
        },
      ],
    };
  },
};
</script>
